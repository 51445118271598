import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import useFormHandler from 'hooks/useFormHandler';
import {
  useEstate,
  useEstateStakeholder,
  useEstateStakeholders,
  useUpdateStakeholder,
} from 'query/estates';
import { useCreateStakeholder } from 'query/stakeholder';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useStore from 'store';
import { gridSpacing } from 'store/constant';
import mapHeirRelationToType from 'utils/mapHeirRelationToType';
import messages from './messages';

export default function StakeholderForm({
  notHeir,
  mode,
  stakeholderId,
  open,
  onClose,
  parentData,
  notHeirData,
  handleReturnedData,
  refetch,
}) {
  const activeEstate = useStore((state) => state.activeEstate);
  const estate = useEstate({ uuid: activeEstate });
  const stakeholders = useEstateStakeholders({ uuid: activeEstate });
  const createStakeholder = useCreateStakeholder();

  const { data: stakeholderData, isLoading } = useEstateStakeholder({
    id: stakeholderId,
    enabled: mode === 'update',
  });
  const updateStakeholder = useUpdateStakeholder();

  const { formData, setFormData, handleChange, errorMessage, setErrorMessage } =
    useFormHandler({});

  const [singleEstateManager, setSingleEstateManager] = useState(false);
  const [allHeirsMinimum, setAllHeirsMinimum] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [customFraction, setCustomFraction] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (mode === 'update' && !isLoading) {
      setFormData({
        ...formData,
        type: stakeholderData.type,
        name: stakeholderData.name,
        ssn: stakeholderData.ssn,
        email: stakeholderData.email,
        heir_relation: stakeholderData.heir_relation,
        heir_parent: stakeholderData.heir_parent,
        heir_guardian: stakeholderData.heir_guardian,
        heir_minimum_is_maximum: stakeholderData.heir_minimum_is_maximum,
        heir_status: stakeholderData.heir_status,
        entity_type: stakeholderData.entity_type,
        estate_manager_poa: stakeholderData.estate_manager_poa,
        orgnr: stakeholderData.orgnr,
        heir_fraction_denominator: stakeholderData.heir_fraction_denominator,
        heir_fraction_numerator: stakeholderData.heir_fraction_numerator,
      });
    } else if (mode === 'create') {
      setFormData({});
    }
    if (parentData?.heir_parent) {
      setFormData({
        ...formData,
        ...parentData,
      });
      // console.log('ParentData: ', parentData);
    }
    if (notHeir && notHeirData) {
      setFormData({ ...notHeirData });
      // console.log(notHeirData);
    }

    setSingleEstateManager(
      estate.data?.b2c_estate_questions.single_estate_manager,
    );
    setAllHeirsMinimum(estate.data?.heirs_inherit_minimum);

    setCustomFraction(
      stakeholderData?.heir_fraction_denominator > 0 ||
        stakeholderData?.heir_fraction_numerator > 0,
    );
    // console.log(singleEstateManager);
  }, [stakeholderData, parentData, notHeirData]);

  const handleSubmit = async () => {
    setErrorMessage('');

    if (mode === 'create') {
      let entType;
      const personalTypes = [
        'spouse',
        'predeceased_spouse',
        'heir',
        'life_heirs',
      ];
      const governmentTypes = ['government', 'tax_authority'];

      if (personalTypes.includes(formData.type)) {
        entType = 'personal';
      } else if (governmentTypes.includes(formData.type)) {
        entType = 'government';
      } else if (formData.type === 'debt_collector') {
        entType = 'company';
      } else if (formData.type === 'other') {
        entType = 'other';
      }
      createStakeholder.mutate(
        {
          ...formData,
          entity_type: entType,
          estate: estate.data.id,
          heir_parent: formData?.heir_parent,
          type: formData?.type,
          heir_fraction_denominator: customFraction
            ? formData.heir_fraction_denominator
            : 0,
          heir_fraction_numerator: customFraction
            ? formData.heir_fraction_numerator
            : 0,
        },
        {
          onSuccess: (data) => {
            console.log('Mutation succeeded. Data:', data);
            setFormData({
              ...data,
            });
            if (handleReturnedData !== undefined) handleReturnedData(data.id);
            stakeholders.refetch();
            onClose();
          },
          onError: (error) => {
            console.log('Error:', error);
            setErrorMessage(error);
          },
        },
      );
    } else if (mode === 'update') {
      updateStakeholder.mutate(
        {
          ...formData,
          id: stakeholderId,
          create_common_stakeholder: false,
          heir_fraction_denominator: customFraction
            ? formData.heir_fraction_denominator
            : 0,
          heir_fraction_numerator: customFraction
            ? formData.heir_fraction_numerator
            : 0,
        },
        {
          onSuccess: (data) => {
            console.log('Mutation succeeded. Data:', data);
            if (handleReturnedData !== undefined) handleReturnedData(data.id);
            stakeholders.refetch();
            onClose();
          },
          onError: (error) => {
            console.log('Error:', error);
            setErrorMessage(error);
          },
        },
      );
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle variant="h2">
        {mode === 'create' && !notHeir
          ? intl.formatMessage(messages.dialogTitleCreateStakeholder)
          : mode === 'update' && !notHeir
            ? intl.formatMessage(messages.dialogTitleUpdateStakeholder)
            : mode === 'create' && notHeir
              ? intl.formatMessage(
                  messages.dialogTitleCreateExternalStakeholder,
                )
              : mode === 'update' && notHeir
                ? intl.formatMessage(
                    messages.dialogTitleUpdateExternalStakeholder,
                  )
                : ''}
      </DialogTitle>

      {notHeir && notHeirData ? (
        <DialogContent>
          <div className="flex h-full items-center justify-center">
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    type="text"
                    id="name"
                    label="Name"
                    onChange={handleChange('name')}
                    value={formData.name || ''}
                    autoCapitalize="on"
                    helperText={errorMessage.name && errorMessage.name[0]}
                    error={!!errorMessage.name}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Divider>
                  <Button onClick={handleExpandClick}>
                    {expanded
                      ? intl.formatMessage(messages.showLess)
                      : intl.formatMessage(messages.showMore)}
                  </Button>
                </Divider>

                <Collapse in={expanded} timeout="auto">
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          id="orgnr"
                          type="text"
                          label={intl.formatMessage(messages.companyNumner)}
                          onChange={handleChange('orgnr')}
                          value={formData.orgnr || ''}
                          helperText={
                            errorMessage.orgnr && errorMessage.orgnr[0]
                          }
                          error={!!errorMessage.orgnr}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>
                          {intl.formatMessage(messages.type)}
                        </InputLabel>
                        <Select
                          id="type"
                          label={intl.formatMessage(messages.type)}
                          onChange={handleChange('type')}
                          value={parentData?.type || formData.type || ''}
                          error={!!errorMessage.type}
                        >
                          <MenuItem value={'debt_collector'}>
                            {intl.formatMessage(messages.debtCollector)}
                          </MenuItem>
                          ,
                          <MenuItem value={'tax_authority'}>
                            {intl.formatMessage(messages.taxAuthority)}
                          </MenuItem>
                          ,
                          <MenuItem value={'government'}>
                            {intl.formatMessage(messages.government)}
                          </MenuItem>
                          ,
                          <MenuItem value={'other'}>
                            {intl.formatMessage(messages.other)}
                          </MenuItem>
                          ,{/* ]} */}
                        </Select>
                        <FormHelperText>
                          {errorMessage.type && errorMessage.type[0]}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth required>
                        <TextField
                          variant="outlined"
                          type="text"
                          id="email"
                          label={intl.formatMessage(messages.email)}
                          onChange={handleChange('email')}
                          value={formData.email || ''}
                          helperText={
                            errorMessage.email && errorMessage.email[0]
                          }
                          error={!!errorMessage.email}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      ) : (
        <DialogContent>
          <div className="flex h-full items-center justify-center">
            {/* <MainCard sx={{ width: '60%' }}> */}
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    type="text"
                    id="name"
                    label={intl.formatMessage(messages.name)}
                    onChange={handleChange('name')}
                    value={formData.name || ''}
                    autoCapitalize="on"
                    helperText={errorMessage.name && errorMessage.name[0]}
                    error={!!errorMessage.name}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    variant="outlined"
                    type="text"
                    id="email"
                    label={intl.formatMessage(messages.email)}
                    onChange={handleChange('email')}
                    value={formData.email || ''}
                    helperText={errorMessage.email && errorMessage.email[0]}
                    error={!!errorMessage.email}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    id="fødselsnummer"
                    type="text"
                    label={intl.formatMessage(messages.ssn)}
                    onChange={handleChange('ssn')}
                    value={formData.ssn || ''}
                    helperText={errorMessage.ssn && errorMessage.ssn[0]}
                    error={!!errorMessage.ssn}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>
                    {intl.formatMessage(messages.relationshipToDeceased)}
                  </InputLabel>
                  <Select
                    id="heir_relation"
                    label={intl.formatMessage(messages.relationshipToDeceased)}
                    onChange={(e) => {
                      handleChange('heir_relation')(e);
                      const newTypeValue = mapHeirRelationToType(
                        e.target.value,
                      );
                      handleChange('type', newTypeValue)();
                    }}
                    value={formData.heir_relation || ''}
                    error={!!errorMessage.heir_relation}
                  >
                    <MenuItem value={'parent'}>
                      {intl.formatMessage(messages.parent)}
                    </MenuItem>
                    <MenuItem value={'grandparent'}>
                      {intl.formatMessage(messages.grandparent)}
                    </MenuItem>
                    <MenuItem value={'child'}>
                      {intl.formatMessage(messages.child)}
                    </MenuItem>
                    <MenuItem value={'grandchild'}>
                      {intl.formatMessage(messages.grandchild)}
                    </MenuItem>
                    <MenuItem value={'uncle_aunt'}>
                      {intl.formatMessage(messages.uncleAunt)}
                    </MenuItem>
                    <MenuItem value={'nephew_niece'}>
                      {intl.formatMessage(messages.nephewNiece)}
                    </MenuItem>
                    <MenuItem value={'spouse'}>
                      {intl.formatMessage(messages.spouse)}
                    </MenuItem>
                    <MenuItem value={'other'}>
                      {intl.formatMessage(messages.other)}
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {errorMessage.heir_relation &&
                      errorMessage.heir_relation[0]}
                  </FormHelperText>
                </FormControl>
              </Grid>

              {formData.heir_relation === 'grandchild' && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>
                      {intl.formatMessage(messages.parent)}
                    </InputLabel>
                    <Select
                      id="parent"
                      label="Parent"
                      onChange={handleChange('heir_parent')}
                      value={formData?.heir_parent || ''}
                      error={!!errorMessage.heir_parent}
                    >
                      {stakeholders.data
                        ?.filter((s) => s.type !== 'other')
                        .map((s) => (
                          <MenuItem key={s.id} value={s.id}>
                            {s.name}
                          </MenuItem>
                        ))}
                      <MenuItem value={null}>
                        {intl.formatMessage(messages.none)}
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {errorMessage.heir_parent && errorMessage.heir_parent[0]}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData?.estate_manager_poa || false}
                      onChange={handleChange('estate_manager_poa')}
                      name="estate_manager_poa"
                    />
                  }
                  label={intl.formatMessage(
                    messages.estateManagerAuthorization,
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={customFraction}
                        onChange={() => setCustomFraction((prev) => !prev)}
                        name="customFraction"
                      />
                    }
                    label={'Tilpasset arvebrøk'}
                    // label={intl.formatMessage(
                    //   messages.estateManagerAuthorization,
                    // )}
                  />
                  {customFraction && (
                    <div>
                      <FormControl>
                        <TextField
                          variant="outlined"
                          id="numerator "
                          type="number"
                          label={'Teller'}
                          size="small"
                          onChange={handleChange('heir_fraction_numerator')}
                          value={formData.heir_fraction_numerator || ''}
                          helperText={
                            errorMessage.heir_fraction_numerator &&
                            errorMessage.heir_fraction_numerator[0]
                          }
                          error={!!errorMessage.heir_fraction_numerator}
                        />
                      </FormControl>
                      <Divider sx={{ marginY: 1 }} />
                      <FormControl>
                        <TextField
                          variant="outlined"
                          id="denominator "
                          type="number"
                          label={'Nevner'}
                          size="small"
                          onChange={handleChange('heir_fraction_denominator')}
                          value={formData.heir_fraction_denominator || ''}
                          helperText={
                            errorMessage.heir_fraction_denominator &&
                            errorMessage.heir_fraction_denominator[0]
                          }
                          error={!!errorMessage.heir_fraction_denominator}
                        />
                      </FormControl>
                    </div>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  // onChange={handleChange('heir_minimum_is_maximum')}
                  control={
                    <Switch
                      // checked={
                      //   formData.heir_minimum_is_maximum || allHeirsMinimum
                      // }
                      checked={formData?.heir_minimum_is_maximum || false}
                      onChange={handleChange('heir_minimum_is_maximum')}
                      name="heir_minimum_is_maximum"
                    />
                  }
                  label={intl.formatMessage(messages.minimumInheritance)}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Status</FormLabel>
                  <RadioGroup
                    id="status"
                    name="heir_status"
                    value={formData.heir_status || ''}
                    onChange={handleChange('heir_status')}
                    row
                  >
                    <FormControlLabel
                      value=""
                      control={<Radio />}
                      label={intl.formatMessage(messages.standard)}
                    />
                    <FormControlLabel
                      value="deceased"
                      control={<Radio />}
                      label={intl.formatMessage(messages.deceasedLabel)}
                    />
                    <FormControlLabel
                      value="renunciation_of_inheritance"
                      control={<Radio />}
                      label={intl.formatMessage(
                        messages.renunciationOfInheritance,
                      )}
                    />
                    <FormControlLabel
                      value="minor"
                      control={<Radio />}
                      label={intl.formatMessage(messages.minor)}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {formData?.heir_status === 'minor' && (
                  <Grid container spacing={gridSpacing}>
                    {/* <Typography variant="overline">Verge</Typography> */}
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          id="guardian_name"
                          type="text"
                          label={intl.formatMessage(messages.guardianName)}
                          onChange={handleChange('heir_guardian.name')}
                          value={formData?.heir_guardian?.name || ''}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          id="guardian_email"
                          type="text"
                          label={intl.formatMessage(messages.guardianEmail)}
                          onChange={handleChange('heir_guardian.email')}
                          value={formData?.heir_guardian?.email || ''}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          id="guardian_ssn"
                          type="text"
                          label={intl.formatMessage(messages.guardianSsn)}
                          onChange={handleChange('heir_guardian.ssn')}
                          value={formData?.heir_guardian?.ssn || ''}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={onClose}>{intl.formatMessage(messages.cancel)}</Button>
        <Button variant="contained" onClick={handleSubmit}>
          {intl.formatMessage(messages.save)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
