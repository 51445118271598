import { Box, Container } from '@mui/material';
import StakeholderCard from './StakeholderCard';

export default function FamilyNode({
  node,
  stakeholders_complete,
  handleEditStakeholder,
  handleCreateOpenForm,
  setParentData,
  stakeholder,
  handleDelete,
}) {
  return (
    <Container className="child">
      <StakeholderCard
        stakeholder={node}
        stakeholders_complete={stakeholders_complete}
        onEdit={() => handleEditStakeholder(node.id)}
        setParentData={setParentData}
        newChildForm={() => handleCreateOpenForm()}
        handleDelete={handleDelete}
      />
      {node.heir_children?.length >= 1 && (
        <Box className="parent">
          {node.heir_children?.map((child) => {
            const newNode = stakeholder.data?.find((c) => child.id === c.id);
            return (
              <Box key={child.id} className="child">
                <FamilyNode
                  node={newNode}
                  stakeholders_complete={stakeholders_complete}
                  handleEditStakeholder={handleEditStakeholder}
                  handleCreateOpenForm={handleCreateOpenForm}
                  setParentData={setParentData}
                  stakeholder={stakeholder}
                  handleDelete={handleDelete}
                />
              </Box>
            );
          })}
        </Box>
      )}
    </Container>
  );
}
