import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import FamilyNode from './FamilyNode';
import StakeholderCard from './StakeholderCard';

export default function StakeholderTreeView({
  estate,
  stakeholder,
  stakeholdersComplete,
  handleEditStakeholder,
  handleCreateOpenForm,
  setParentData,
  handleDelete,
}) {
  const [hasSpouse, setHasSpouse] = useState(false);

  useEffect(() => {
    const isSpouse = stakeholder.data?.some(
      (s) => s.type === 'spouse' || s.type === 'predeceased_spouse',
    );
    setHasSpouse(isSpouse);
  }, [stakeholder.data]);

  return (
    <div className="container mx-auto h-[93%] overflow-visible">
      <TransformWrapper minScale={0.2}>
        <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }}>
          <div className="h-full w-full">
            <Grid container className="grid grid-cols-3 justify-center gap-14">
              {/* <div></div> */}
              <Grid item></Grid>
              <Grid item className="flex items-center justify-center">
                {/* <MainCard className="m-auto w-52 pb-3 shadow-md">
                  <CardContent className="p-5 text-center">
                    <div className="flex items-center justify-center space-x-2">
                      <div className="space-y-1">
                        <Typography
                          align="center"
                          className="text-lg font-semibold"
                        >
                          {estate.data?.name}
                        </Typography>
                        <Badge variant="outlined">Avdøde</Badge>
                      </div>
                    </div>
                  </CardContent>
                </MainCard> */}
                <StakeholderCard deceased name={estate.data?.name} />
              </Grid>
              {hasSpouse ? (
                <Grid item className="spouse justify-self-start">
                  {stakeholder.data
                    ?.filter(
                      (s) =>
                        s.type === 'spouse' || s.type === 'predeceased_spouse',
                    )
                    .map((s) => (
                      // <Container key={s.id}>
                      <StakeholderCard
                        key={s.id}
                        stakeholder={s}
                        stakeholdersComplete={stakeholdersComplete}
                        onEdit={() => handleEditStakeholder(s.id)}
                        setParentData={setParentData}
                        handleDelete={handleDelete}
                      />
                      // </Container>
                    ))}
                </Grid>
              ) : (
                <Grid item></Grid>
              )}
            </Grid>

            {stakeholder.data?.some((s) => s.type === 'life_heirs') && (
              <Box className="parent">
                {stakeholder.data
                  ?.filter((s) => s.type === 'life_heirs')
                  .map((s) => (
                    <FamilyNode
                      key={s.id}
                      node={s}
                      stakeholdersComplete={stakeholdersComplete}
                      handleEditStakeholder={handleEditStakeholder}
                      handleCreateOpenForm={handleCreateOpenForm}
                      setParentData={setParentData}
                      stakeholder={stakeholder}
                      handleDelete={handleDelete}
                    />
                  ))}
              </Box>
            )}
            {/* <Box className="p-14">
              {stakeholder.data
                ?.filter((s) => s.type === 'other')
                .map((s) => (
                  <FamilyNode
                    key={s.id}
                    node={s}
                    stakeholders_complete={stakeholders_complete}
                    handleEditStakeholder={handleEditStakeholder}
                    handleCreateOpenForm={handleCreateOpenForm}
                    setParentData={setParentData}
                    stakeholder={stakeholder}
                    handleDelete={handleDelete}
                  />
                ))}
            </Box> */}
          </div>
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
}
