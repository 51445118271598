/*
 * LoginForm Messages
 *
 * This contains all the text for the LoginForm component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  signinButton: {
    id: 'app.components.LoginForm.signinButton',
    defaultMessage: 'Sign in with email/password',
  },
  formPlaceholder: {
    id: 'app.components.LoginForm.formPlaceholder',
    defaultMessage: 'This is the placeholder',
  },
  usernameLabel: {
    id: 'app.components.LoginForm.usernameLabel',
    defaultMessage: 'Username (your email)',
  },
  passwordLabel: {
    id: 'app.components.LoginForm.passwordLabel',
    defaultMessage: 'Password',
  },
  signinAsSomeoneElse: {
    id: 'app.components.LoginForm.signinAsSomeoneElse',
    defaultMessage: 'Sign in as someone else',
  },
  cancel: {
    id: 'app.components.LoginForm.cancel',
    defaultMessage: 'Cancel',
  },
});
