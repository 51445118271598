import { CardContent, Divider, Grid, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { useGetPrivateDivisionSigningStatus } from 'query/estates';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import useStore from 'store';
import File from './File';
import messages from './messages';

export default function FilesCard({ isLoading }) {
  const activeEstate = useStore((state) => state.activeEstate);
  // const stakeholders = useEstateStakeholders({ uuid: activeEstate });
  const getSigningStatus = useGetPrivateDivisionSigningStatus({
    uuid: activeEstate,
  });

  useEffect(() => {
    getSigningStatus.refetch();
  }, [activeEstate]);

  return (
    <MainCard content={false} id="filesCard">
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Files</Typography>
          </Grid>
          <Grid item xs={12}>
            {getSigningStatus.data?.length > 0 ? (
              getSigningStatus.data?.map((s, index) => (
                <div key={index} className="cursor-pointer">
                  <File s={s} />

                  {index !== getSigningStatus.data?.length - 1 && (
                    <Divider sx={{ my: 1.5 }} variant="middle" />
                  )}
                </div>
              ))
            ) : (
              <Typography variant="caption">
                <FormattedMessage {...messages.noFiles} />
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </MainCard>
  );
}
