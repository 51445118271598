import { Download } from '@mui/icons-material';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import DataroomFileButton from './DataroomFileButton';

export default function FileListDownload({ files }) {
  function downloadBase64(base64String, filename) {
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = filename;
    downloadLink.click();
  }

  return (
    <Box width={300}>
      <List>
        {files?.map((f, index) => (
          <ListItem key={index}>
            <ListItemText>{f.filename}</ListItemText>
            {f.encoding === 'base64' && (
              <ListItemButton
                dense
                onClick={() => downloadBase64(f.content, f.filename)}
              >
                <Download />
              </ListItemButton>
            )}
            {f.type === 'dataroomFile' && (
              <DataroomFileButton id={f.content} iconButton />
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
