//import logo from 'logo.svg';
import { Suspense, useEffect, useMemo } from 'react';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { IntlProvider } from 'react-intl';

import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import enLocale from 'date-fns/locale/en-US';
import nbLocale from 'date-fns/locale/nb';

import En from 'intl/compiled-locales/en.json';
import No from 'intl/compiled-locales/no.json';

import type { AppState } from 'store';
import useStore, { useStoreIsHydrated } from 'store';

import Asset from 'containers/Asset';
import Claims from 'containers/Claims';
import CreateEstate from 'containers/CreateEstate';
import Dashboard from 'containers/Dashboard';
import EstateSummary from 'containers/EstateSummary';
import Home from 'containers/Home/Loadable';
import AltLayout from 'containers/Layout/AltLayout';
import Layout from 'containers/Layout/Loadable';
import LetterTemplates from 'containers/LetterTemplates';
import Stakeholders from 'containers/Stakeholders';
import Subscriptions from 'containers/Subscriptions';
import TestPage from 'containers/TestPage';
import UpdateEstate from 'containers/UpdateEstate';
import UserLogin from 'containers/UserLogin';
import CreateProfile from 'containers/UserLogin/CreateProfile';
import EmailLogin from 'containers/UserLogin/EmailLogin';
import SignicatLogin from 'containers/UserLogin/SignicatLogin';
import Success from 'containers/UserLogin/Success';
import ViewEstate from 'containers/ViewEstate';
import { useUser } from 'query/user';

const localeMap: { en: any; no: any } = {
  en: enLocale,
  no: nbLocale,
};

const ErrorFallback = ({ error }: FallbackProps) => (
  <div>
    <p>
      <strong>Error occured</strong>
    </p>
    <p>
      <strong>Error:</strong> {error.toString()}
    </p>
  </div>
);

function App() {
  const loggedIn = useStore((state: AppState) => state.loggedIn);
  const locale: 'no' | 'en' = useStore((state: AppState) => state.locale);
  const tutorialDone = useStore((state: AppState) => state.tutorialDone);

  const isHydrated = useStoreIsHydrated();
  const user = useUser({ options: {} });

  const messages = useMemo(() => {
    switch (locale) {
      case 'no':
        return No;
      case 'en':
        return En;
      default:
        return No;
    }
  }, [locale]);

  useEffect(() => {
    const heartbeat = setInterval(async () => {
      user.remove();
      await user.refetch();
    }, 30000);

    return () => clearInterval(heartbeat);
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <IntlProvider locale={locale} messages={messages} onError={() => null}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={localeMap[locale]}
        >
          {isHydrated && (
            <Suspense fallback={'test'}>
              <Router>
                {loggedIn ? (
                  <>
                    {/* {!tutorialDone && <Joyride steps={steps}  />} */}
                    <Routes>
                      <Route element={<Layout />}>
                        <Route
                          path="/stakeholders"
                          element={<Stakeholders />}
                        />
                        <Route path="/assets" element={<Asset />} />
                        <Route path="/claims" element={<Claims />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/summary" element={<EstateSummary />} />
                        <Route
                          path="/templates"
                          element={<LetterTemplates />}
                        />
                        <Route path="/testpage" element={<TestPage />} />
                        <Route path="/settings" element={<UpdateEstate />} />
                        <Route
                          path="/subscriptions"
                          element={<Subscriptions />}
                        />
                      </Route>
                      <Route element={<AltLayout />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/estate/:uuid" element={<ViewEstate />} />
                        <Route
                          path="/createprofile"
                          element={<CreateProfile />}
                        />
                        <Route path="/estate/new" element={<CreateEstate />} />
                      </Route>
                    </Routes>
                  </>
                ) : (
                  <Routes>
                    <Route element={<AltLayout />}>
                      <Route path="/" element={<UserLogin />} />
                      <Route path="/email" element={<EmailLogin />} />
                      <Route path="/bankid" element={<SignicatLogin />} />
                      <Route path="/login/success" element={<Success />} />
                    </Route>
                  </Routes>
                )}
              </Router>
            </Suspense>
          )}
        </LocalizationProvider>
      </IntlProvider>
    </ErrorBoundary>
  );
}

export default App;
