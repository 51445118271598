import { Download, ExpandLess, ExpandMore, History } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useDataroomFile } from 'query/estates';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import FileSigner from './FileSigner';
import messages from './messages';

const File = ({ s }) => {
  const fileDownload = useDataroomFile({ id: s.signed_file });
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getName = () => {
    if (s.name.includes('fullmakt'))
      return (
        <FormattedMessage
          {...messages.fullmaktLabel}
          values={{ name: s.signatures[0].name }}
        />
      );
    else if (s.name.includes('Private-Division'))
      return <FormattedMessage {...messages.privatSkifteLabel} />;
  };

  const handleFileDownload = async () => {
    try {
      const res = await fileDownload.refetch();
      console.log(res);
      const blob = await res.data.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = s.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  console.log(s);
  return (
    <div onClick={handleExpandClick}>
      <div className="flex items-center justify-between">
        <Typography variant="subtitle1">
          <IconButton>{expanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
          {getName()}
        </Typography>
        <div className="flex items-center justify-between">
          <Typography variant="subtitle1">
            {s.count_signed} / {s.count_total}
          </Typography>
          {s.count_signed === s.count_total ? (
            <Tooltip
              title={<FormattedMessage {...messages.downloadTooltip} />}
              placement="left"
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleFileDownload();
                }}
              >
                <Download />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title={<FormattedMessage {...messages.pendingTooltip} />}
              placement="left"
            >
              <IconButton>
                <History />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <Collapse in={expanded} timeout="auto">
        <Box mx={1} borderLeft={2} borderColor={'GrayText'}>
          <Divider orientation="vertical">
            {s.signatures.map((sign, index) => (
              <FileSigner
                sign={sign}
                divider={index !== s.signatures.length - 1}
                key={index}
              />
            ))}
          </Divider>
        </Box>
      </Collapse>
    </div>
  );
};

export default File;
