import { defineMessages } from 'react-intl';

const messages = defineMessages({
  claimsTitle: {
    id: 'Claims.title',
    defaultMessage: 'Debt & Expenses',
  },
  addNewClaim: {
    id: 'Claims.addNewClaim',
    defaultMessage: 'Add new claim',
  },
  doneAddingClaims: {
    id: 'Claims.doneAddingClaims',
    defaultMessage: 'Done adding claims',
  },
  claimsCompleted: {
    id: 'Claims.claimsCompleted',
    defaultMessage: 'Claims completed',
  },
  claimDeleted: {
    id: 'Claims.claimDeleted',
    defaultMessage: 'Claim deleted',
  },
  couldNotDeleteClaim: {
    id: 'Claims.couldNotDeleteClaim',
    defaultMessage: 'Could not delete claim!',
  },
  dialogTitleCreateClaim: {
    id: 'ClaimForm.dialogTitleCreateClaim',
    defaultMessage: 'Create Claim',
  },
  dialogTitleUpdateClaim: {
    id: 'ClaimForm.dialogTitleUpdateClaim',
    defaultMessage: 'Update Claim',
  },
  title: {
    id: 'ClaimForm.title',
    defaultMessage: 'Title',
  },
  value: {
    id: 'ClaimForm.value',
    defaultMessage: 'Value',
  },
  stakeholder: {
    id: 'ClaimForm.stakeholder',
    defaultMessage: 'Stakeholder',
  },
  cancel: {
    id: 'ClaimForm.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'ClaimForm.save',
    defaultMessage: 'Save',
  },
  addNew: {
    id: 'ClaimForm.addNew',
    defaultMessage: 'Add "{name}"',
  },
  outlay: {
    id: 'ClaimForm.outlay',
    defaultMessage: 'Outlay',
  },
  preferential: {
    id: 'ClaimForm.preferential',
    defaultMessage: 'Other',
  },
  claimCreated: {
    id: 'ClaimForm.claimCreated',
    defaultMessage: 'Claim created',
  },
  claimUpdated: {
    id: 'ClaimForm.claimUpdated',
    defaultMessage: 'Claim updated',
  },
  errorCreatingClaim: {
    id: 'ClaimForm.errorCreatingClaim',
    defaultMessage: 'Error: {error}',
  },
  emptyMessage: {
    id: 'ClaimsGridView.emptyMessage',
    defaultMessage: 'It is empty here, add a new claim',
  },
});

export default messages;
