import { useAuthMutation } from 'query';

export function useCreateRealAsset(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation('real_asset', mergedOptions);
}

export function useUpdateRealAsset(options = {}) {
  const mergedOptions = {
    ...options,
    idField: 'id',
    method: 'put',
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation('real_asset', mergedOptions);
}

export function usePartialUpdateRealAsset(options = {}) {
  const mergedOptions = {
    ...options,
    idField: 'id',
    method: 'patch',
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation('real_asset', mergedOptions);
}
