import { CreditCard, Money } from '@mui/icons-material';
import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import EditDeletePopup from 'components/EditDeletePopup';
import { FormattedNumber, useIntl } from 'react-intl';
import { gridSpacing } from 'store/constant';
import messages from './messages';

export default function ClaimsTableView({
  claimsData,
  handleEditClaim,
  handleDelete,
}) {
  const intl = useIntl();
  return (
    <Grid container spacing={gridSpacing} justifyContent={'center'}>
      {claimsData.length > 0 ? (
        <>
          <Grid item xs={12} md={5} justifyContent={'center'}>
            <Typography variant="h3" className="pb-3">
              {intl.formatMessage(messages.preferential)}
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {claimsData &&
                    claimsData
                      .filter((e) => e.type_category === 'preferential')
                      .map((e) => (
                        <TableRow key={e.id}>
                          <TableCell component="th" scope="row">
                            <Tooltip
                              title={e.get_type_category_display}
                              placement="left"
                            >
                              {e.type_category === 'outlay' ? (
                                <Money />
                              ) : (
                                <CreditCard />
                              )}
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <b>{e.title}</b>
                          </TableCell>
                          <TableCell>{e.stakeholder.name}</TableCell>
                          <TableCell>
                            <FormattedNumber
                              value={e.value}
                              // eslint-disable-next-line react/style-prop-object
                              style="currency"
                              currency="nok"
                            />
                          </TableCell>
                          <TableCell>
                            <EditDeletePopup
                              handleEdit={() => handleEditClaim(e.id)}
                              handleDelete={() => handleDelete(e.id)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Divider orientation={{ md: 'vertical' }} variant="middle" />
          </Grid>
          <Grid item xs={12} md={5} justifyContent={'center'}>
            <Typography variant="h3" className="pb-3">
              {intl.formatMessage(messages.outlay)}
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {claimsData &&
                    claimsData
                      .filter((e) => e.type_category === 'outlay')
                      .map((e) => (
                        <TableRow key={e.id}>
                          <TableCell component="th" scope="row">
                            <Tooltip
                              title={e.get_type_category_display}
                              placement="left"
                            >
                              {e.type_category === 'outlay' ? (
                                <Money />
                              ) : (
                                <CreditCard />
                              )}
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <b>{e.title}</b>
                          </TableCell>
                          <TableCell>{e.stakeholder.name}</TableCell>
                          <TableCell>
                            <FormattedNumber
                              value={e.value}
                              // eslint-disable-next-line react/style-prop-object
                              style="currency"
                              currency="nok"
                            />
                          </TableCell>
                          <TableCell>
                            <EditDeletePopup
                              handleEdit={() => handleEditClaim(e.id)}
                              handleDelete={() => handleDelete(e.id)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>{' '}
        </>
      ) : (
        <Grid item xs={12} className="text-center">
          <Typography variant="caption">
            {intl.formatMessage(messages.emptyMessage)}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
