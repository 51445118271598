import { Box, CssBaseline } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'infoOpen',
})(({ theme, open, infoOpen }) => ({
  ...theme.typography.mainContent,
  // borderBottomLeftRadius: 0,
  // borderBottomRightRadius: 0,
  top: '10px',
  minHeight: '95vh',
  overflow: 'auto',
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        },
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: 17,
    marginRight: 17,
    width: `calc(100% - 17px)`,
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    width: `calc(100% - 17px)`,
    padding: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    width: `calc(100% - 17px)`,
    padding: '16px',
    marginRight: '10px',
  },
}));

export default function AltLayout() {
  const theme = useTheme();

  // const NavbarMemoized = memo(Navbar);
  // const InfoDrawerMemoized = memo(InfoDrawer);
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* <AppBar /> */}
      {/* <Navbar /> */}
      {/* <InfoDrawer infoOpen={infoOpen} handleInfoOpen={handleInfoOpen} /> */}
      <Main theme={theme}>
        {/* {children} */}
        <Outlet />
      </Main>
    </Box>
  );
}
