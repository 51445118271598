import {
  useQuery,
  useMutation,
  useInfiniteQuery,
  UseQueryOptions,
} from '@tanstack/react-query';

import { GraphQLClient } from 'graphql-request';

import useStore from '../store';

interface AuthUseQueryOptions extends UseQueryOptions {
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
  [key: string]: object | string | boolean | number | unknown | undefined;
}

interface AuthUseQueryVariables {
  [key: string]: object | string | boolean | number | unknown | undefined;
}

interface AuthMutationOptions {
  idField?: string;
  method?: string;
  endpointSuffix?: string;
  [key: string]: object | string | boolean | number | unknown | undefined;
}

const graphQLClient = new GraphQLClient(
  `${process.env.REACT_APP_GRAPHQL_API_URL}`,
);

const getHeaders = () => {
  const { token } = useStore.getState();

  const headers: {
    Accept: string;
    'Content-Type'?: string;
    Authorization?: string;
    'Accept-Language': string;
  } = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': 'nb',
  };

  if (token) {
    headers.Authorization = `Token ${token}`;
  }
  return headers;
};

function useAuthQuery<TData, Error>(
  key: Array<string>,
  endpoint: string,
  options: AuthUseQueryOptions = {},
) {
  const loggedIn = useStore((state) => state.loggedIn);

  const mergedQueryOptions: object = {
    ...options,
    enabled: (options?.allowAnonymous || loggedIn) && options?.enabled,
    refetchOnWindowFocus: false,
  };

  return useQuery<TData, Error>({
    queryKey: key,
    queryFn: async () => {
      const headers = getHeaders();
      const res = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
        method: 'GET',
        headers,
      });

      if (!res.ok) {
        const json = await res.json();
        throw { ...json, http_status: res.status }; // eslint-disable-line no-throw-literal
      }
      if (options.fileDl) return res;
      else return res.json();
    },
    ...mergedQueryOptions,
  });
}

function useAuthMutation<TData, TError, TVariables>(
  endpoint: string,
  options?: AuthMutationOptions,
) {
  const mergedOptions: object = { ...options };
  return useMutation<TData, TError, TVariables>({
    mutationFn: async (o) => {
      const headers = getHeaders();

      const obj: any = o;

      let url = `${process.env.REACT_APP_API_URL}${endpoint}/`;

      if (
        options &&
        options.idField &&
        obj[options.idField as keyof typeof obj]
      ) {
        url = `${url}${obj[options.idField as keyof typeof obj]}/`;
      }

      if (options && options.endpointSuffix) {
        url = `${url}${options.endpointSuffix}/`;
      }

      let formDataBody: FormData | null = null;
      let jsonBody: string | null = null;
      let hasFile = false;
      let largeFile: File | null;

      if (
        options &&
        options.largeFileFieldName &&
        obj[options.largeFileFieldName as keyof typeof obj]
      ) {
        largeFile = obj[options.largeFileFieldName as keyof typeof obj];
      } else {
        largeFile = null;
      }

      if (
        largeFile &&
        process.env.REACT_APP_API_URL !== 'http://localhost:8000/v1/' &&
        largeFile.size > 1024 * 1024 * 10
      ) {
        const linkURL = `${process.env.REACT_APP_API_URL}large_file_upload/get_upload_link/`;

        const filenameExt = largeFile.name.split('.').pop();

        const linkResponse = await fetch(linkURL, {
          headers,
          method: 'post',
          body: JSON.stringify({
            filename_ext: filenameExt,
          }),
        });
        const linkJson = await linkResponse.json();

        const uploadURL = linkJson.url;

        const uploadPayload = {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          method: 'put',
          body: largeFile,
        };

        const uploadResponse = await fetch(uploadURL, uploadPayload);
        if (options && uploadResponse.ok) {
          obj.large_file_name = linkJson.filename;
          delete obj[options.largeFileFieldName as keyof typeof obj];
        }
      }

      if (obj) {
        Object.entries(obj).forEach(([, v]: [any, any]) => {
          if (v instanceof Blob || v instanceof File) {
            hasFile = true;
          }
        });

        if (hasFile) {
          formDataBody = new FormData();
          Object.entries(obj).forEach(([key, value]: [any, any]) => {
            if (
              value !== '' &&
              value !== null &&
              (typeof value === 'string' ||
                value instanceof Blob ||
                value instanceof File) &&
              !(Array.isArray(value) && value.length === 0)
            ) {
              formDataBody?.append(key, value);
            }
          });
          delete headers['Content-Type'];
        } else {
          jsonBody = JSON.stringify(obj);
        }
      }

      const res = await fetch(url, {
        // It looks like all methods but patch are uppercased automatically by most browsers,
        // but in Firefox and safary a lowercase patch method was the cause of a difficult to track down bug
        method: (options?.method || 'post').toUpperCase(),
        headers,
        body: formDataBody || jsonBody,
      });
      if (!res.ok) {
        const json = await res.json();
        throw { ...json, http_status: res.status }; // eslint-disable-line no-throw-literal
      }

      if (options?.method?.toUpperCase() !== 'DELETE') {
        return res.json();
      }
      return null;
    },
    ...mergedOptions,
  });
}

function useAuthGraphQuery<TData, TError>(
  key: Array<string>,
  query: string,
  variables: AuthUseQueryVariables = {},
  options: AuthUseQueryOptions = {},
) {
  const loggedIn = useStore((state) => state.loggedIn);

  const mergedQueryOptions: object = {
    ...options,
    enabled: (options?.allowAnonymous || loggedIn) && options?.enabled,
    onError: (e: Error) => console.log(e),
  };

  const queryConfig = {
    queryKey: key,
    queryFn: async () => {
      const mergedVariables = { ...variables };

      const headers = {
        Authorization: `Token ${useStore.getState().token}`,
      };
      const data = await graphQLClient.request(query, mergedVariables, headers);
      if (data !== undefined) {
        return data as TData;
      }
      return {} as TData;
    },
    ...mergedQueryOptions,
  };

  return useQuery<TData, TError>(queryConfig);
}

function useAuthGraphInfiniteQuery<TData, TError>(
  key: Array<string>,
  query: string,
  variables: AuthUseQueryVariables = {},
  options: AuthUseQueryOptions = {},
) {
  const loggedIn = useStore((state) => state.loggedIn);

  const mergedQueryOptions: object = {
    ...options,
    enabled: (options?.allowAnonymous || loggedIn) && options?.enabled,
    onError: (e: Error) => console.log(e),
    getNextPageParam: (lastPage: object, allPages: object[]) => {
      return allPages.length + 1;
    },
  };

  const queryConfig = {
    queryKey: key,
    queryFn: async ({ pageParam = 0 }) => {
      const mergedVariables = { ...variables, page: Math.max(pageParam, 1) };

      const headers = {
        Authorization: `Token ${useStore.getState().token}`,
      };
      const data = await graphQLClient.request(query, mergedVariables, headers);
      if (data !== undefined) {
        return data as TData;
      }
      return {} as TData;
    },
    ...mergedQueryOptions,
  };

  return useInfiniteQuery<TData, TError>(queryConfig);
}

export type { AuthUseQueryOptions };

export {
  useAuthQuery,
  useAuthMutation,
  useAuthGraphQuery,
  useAuthGraphInfiniteQuery,
  getHeaders,
};
