import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import useFormHandler from 'hooks/useFormHandler';
import { useCreateRealAsset } from 'query/asset';
import {
  useEstate,
  useEstateAssets,
  useEstateStakeholders,
  useUpdateAsset,
} from 'query/estates';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';
import useStore from 'store';
import assetTypeIcon from 'utils/assetTypeIcon';
import messages from './messages';

export default function AssetForm({ mode, assetId, open, onClose }) {
  const intl = useIntl();
  const store = useStore();
  const activeEstate = store.activeEstate;
  const estate = useEstate({ uuid: activeEstate });
  const stakeholders = useEstateStakeholders({ uuid: activeEstate });
  const assets = useEstateAssets({ uuid: activeEstate });

  const createAsset = useCreateRealAsset();
  const updateAsset = useUpdateAsset({ id: assetId });

  const { formData, setFormData, handleChange, errorMessage, setErrorMessage } =
    useFormHandler({});

  const fetchData = () => {
    try {
      updateAsset.mutate(
        {
          ...formData,
          id: assetId,
        },
        {
          onSuccess: (data) => {
            console.log('Mutation succeeded. Data:', data);
            setFormData({
              ...data,
            });
          },
          onError: (error) => {
            console.log('Error:', error);
            setErrorMessage(error);
          },
        },
      );
    } catch (error) {
      console.error('Error fetching estate data:', error);
    }
  };

  useEffect(() => {
    if (mode === 'update') {
      fetchData();
    }
  }, [assetId]);

  const handleSubmit = async () => {
    setErrorMessage('');

    if (mode === 'create') {
      createAsset.mutate(
        {
          ...formData,
          attachments: [],
          estate: estate.data.id,
        },
        {
          onSuccess: (data) => {
            console.log('Mutation succeeded. Data:', data);
            assets.refetch();
            toast.success(intl.formatMessage(messages.assetAdded));
            // setFormData({
            //   ...data,
            // });
            handleClose();
          },
          onError: (error) => {
            console.log('Error:', error);
            toast.error('Error: ' + error);
            setErrorMessage(error);
          },
        },
      );
    } else if (mode === 'update') {
      updateAsset.mutate(
        { ...formData, id: assetId },
        {
          onSuccess: (data) => {
            assets.refetch();
            toast.success(intl.formatMessage(messages.assetUpdated));
            handleClose();
          },
          onError: (error) => {
            toast.error('Error: ' + error);
            setErrorMessage(error);
          },
        },
      );
    }
  };

  const handleClose = () => {
    setFormData({
      ...formData,
      type: undefined,
    });
    console.log(formData.type);
    onClose();
  };

  const types = [
    'real_estate',
    'vehicle',
    'bank_account',
    'cash',
    'vps_account',
    'other_current_asset',
    'agricultural_property',
  ];
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle variant="h2">
        {mode === 'create'
          ? intl.formatMessage(messages.dialogTitleCreateAsset)
          : intl.formatMessage(messages.dialogTitleUpdateAsset)}
      </DialogTitle>
      <DialogContent>
        <div className="flex h-full items-center justify-center">
          {/* <MainCard> */}
          <Grid container spacing={2}>
            {mode === 'create' && !formData?.type ? (
              <Grid container spacing={2} sx={{ marginTop: 3 }}>
                {types.map((t, index) => (
                  <Grid item xs={4} key={index}>
                    <Button
                      variant="contained"
                      value={t}
                      onClick={handleChange('type')}
                      fullWidth
                      startIcon={assetTypeIcon(t)}
                      sx={{ padding: 2 }}
                    >
                      {/* {console.log(t)} */}
                      {t === 'other_current_asset'
                        ? intl.formatMessage(messages.other)
                        : t.replaceAll('_', ' ')}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      type="text"
                      id="title"
                      label={intl.formatMessage(messages.title)}
                      onChange={handleChange('title')}
                      value={formData.title || ''}
                      helperText={errorMessage.title && errorMessage.title[0]}
                      error={!!errorMessage.title}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      variant="outlined"
                      type="text"
                      id="description"
                      label={intl.formatMessage(messages.description)}
                      onChange={handleChange('description')}
                      value={formData.description || ''}
                      helperText={
                        errorMessage.description && errorMessage.description[0]
                      }
                      error={!!errorMessage.description}
                    />
                  </FormControl>
                </Grid>
                {formData.type === 'agricultural_property' && (
                  <>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          type="text"
                          label={intl.formatMessage(messages.gnr)}
                          onChange={handleChange('property_gnr')}
                          value={formData.property_gnr || ''}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          type="text"
                          label={intl.formatMessage(messages.bnr)}
                          onChange={handleChange('property_bnr')}
                          value={formData.property_bnr || ''}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          type="text"
                          label={intl.formatMessage(messages.municipality)}
                          onChange={handleChange('property_municipality')}
                          value={formData.property_municipality || ''}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      variant="outlined"
                      type="text"
                      id="value"
                      label={intl.formatMessage(messages.value)}
                      onChange={handleChange('value')}
                      value={formData.value || ''}
                      helperText={errorMessage.value && errorMessage.value[0]}
                      error={!!errorMessage.value}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Inherrited by</InputLabel>
                    <Select
                      id="inherrit"
                      label={intl.formatMessage(messages.inheritedBy)}
                      onChange={handleChange('transferred_to_heir')}
                      value={formData.transferred_to_heir || null}
                    >
                      {stakeholders?.data &&
                        stakeholders.data.map((s) => (
                          <MenuItem value={s.id}>{s.name}</MenuItem>
                        ))}
                      <MenuItem value={null}>Ingen</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
          {/* <Box>
            <Button variant="contained" onClick={handleSubmit}>
              OK
            </Button>
          </Box> */}
          {/* </MainCard> */}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {intl.formatMessage(messages.cancel)}
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          {intl.formatMessage(messages.save)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
