import {
  AccountBalance,
  Agriculture,
  DirectionsCar,
  House,
  Inventory,
  Money,
  ShowChart,
} from '@mui/icons-material';

const assetTypeIcon = (type) => {
  return type === 'vehicle' ? (
    <DirectionsCar />
  ) : type === 'real_estate' ? (
    <House />
  ) : type === 'bank_account' ? (
    <AccountBalance />
  ) : type === 'vps_account' ? (
    <ShowChart />
  ) : type === 'cash' ? (
    <Money />
  ) : type === 'other_current_asset' ? (
    <Inventory />
  ) : type === 'agricultural_property' ? (
    <Agriculture />
  ) : (
    ''
  );
};

export default assetTypeIcon;
