import { Save } from '@mui/icons-material';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import MainCard from 'components/MainCard';
import { parseISO } from 'date-fns';
import useFormHandler from 'hooks/useFormHandler';
import { useEstate, useUpdateEstate } from 'query/estates';
import { useEffect } from 'react';
import useStore from 'store';
import { gridSpacing } from 'store/constant';

export default function UpdateEstate(params) {
  const store = useStore();
  const estate = useEstate({ uuid: store.activeEstate });
  const Update = useUpdateEstate();
  const locale = useStore((state) => state.locale);
  const setLocale = useStore((state) => state.setLocale);

  const {
    formData,
    handleChange,
    setFormData,
    errorMessage,
    setErrorMessage,
    // formDataChanged,
    // setFormDataChanged,
  } = useFormHandler();

  useEffect(() => {
    setFormData({
      ...formData,
      name: estate.data?.name,
      deceased_ssn: estate?.data?.deceased_ssn,
      deceased_date: estate?.data?.deceased_date,
      postal_address_city: estate?.data?.postal_address_city,
      postal_address_street: estate?.data?.postal_address_street,
      postal_address_zip: estate?.data?.postal_address_zip,
      deceased_has_will: estate?.data?.deceased_has_will,
      deceased_has_ektepakt: estate?.data?.deceased_has_ektepakt,
      stakeholders_complete: estate?.data?.stakeholders_complete,
      claims_complete: estate?.data?.claims_complete,
      real_assets_complete: estate?.data?.real_assets_complete,
      uuid: estate?.data?.uuid,
    });
  }, [estate.data]);

  const handleUpdate = async () => {
    console.log('update', formData);
    setErrorMessage('');
    Update.mutate(formData, {
      onSuccess: (data) => {
        console.log('Update mutation succeeded. Data:', data);
      },
      onError: (error) => {
        console.log('Error:', error);
        setErrorMessage(error);
      },
    });
  };

  const handleLocale = () => {
    setLocale(locale === 'en' ? 'no' : 'en');
  };
  //   console.log(estate.data);

  return (
    <div className="flex h-full w-full ">
      <MainCard>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Typography variant="h2">Edit Estate</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                type="text"
                id="name"
                label="Name"
                onChange={handleChange('name')}
                value={formData?.name || ''}
                autoCapitalize="on"
                helperText={errorMessage?.name && errorMessage.name[0]}
                error={!!errorMessage?.name}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                id="fødselsnummer"
                type="text"
                label="Fødselsnummer"
                onChange={handleChange('deceased_ssn')}
                value={formData?.deceased_ssn || ''}
                helperText={
                  errorMessage.deceased_ssn && errorMessage.deceased_ssn[0]
                }
                error={!!errorMessage.deceased_ssn}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <DatePicker
                label={'Dødsdato'}
                format="dd/MM/yyyy"
                onChange={(date) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    deceased_date: date.toISOString().split('T')[0],
                  }));
                }}
                value={
                  formData.deceased_date
                    ? parseISO(formData.deceased_date)
                    : null
                }
                slotProps={{
                  textField: {
                    helperText:
                      errorMessage.deceased_date &&
                      errorMessage.deceased_date[0],
                  },
                }}
                error={!!errorMessage.deceased_date}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                id="address"
                type="text"
                label="Boligadresse"
                onChange={handleChange('postal_address_street')}
                value={formData.postal_address_street || ''}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                id="postnr"
                type="text"
                label="Postnr"
                onChange={handleChange('postal_address_zip')}
                value={formData.postal_address_zip || ''}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                id="poststed"
                type="text"
                label="Poststed"
                onChange={handleChange('postal_address_city')}
                value={formData.postal_address_city || ''}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={4}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <FormLabel component="legend">Avdøde har testament</FormLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={formData?.deceased_has_will || false}
                  onChange={handleChange('deceased_has_will')}
                  name="deceased_has_will"
                />
              }
              label={formData?.deceased_has_will ? 'Ja' : 'Nei'}
            />
          </Grid>
          <Grid
            item
            xs={4}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <FormLabel component="legend">Avdøde har ektepakt</FormLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={formData?.deceased_has_ektepakt || false}
                  onChange={handleChange('deceased_has_ektepakt')}
                  name="deceased_has_ektepakt"
                />
              }
              label={formData?.deceased_has_ektepakt ? 'Ja' : 'Nei'}
            />
          </Grid>
          <Grid
            item
            xs={4}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <FormLabel component="legend">
              Alle arvinger får minstearv
            </FormLabel>

            <FormControlLabel
              control={
                <Switch
                  checked={formData?.heirs_inherit_minimum || false}
                  onChange={handleChange('heirs_inherit_minimum')}
                  name="heirs_inherit_minimum"
                />
              }
              label={formData?.heirs_inherit_minimum ? 'Ja' : 'Nei'}
            />
          </Grid>
          <Grid
            item
            xs={4}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <FormLabel component="legend">Done adding stakeholders</FormLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={formData?.stakeholders_complete || false}
                  onChange={handleChange('stakeholders_complete')}
                  name="stakeholders_complete"
                />
              }
              label={formData?.stakeholders_complete ? 'Ja' : 'Nei'}
            />
          </Grid>
          <Grid
            item
            xs={4}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <FormLabel component="legend">Done adding claims</FormLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={formData?.claims_complete || false}
                  onChange={handleChange('claims_complete')}
                  name="claims_complete"
                />
              }
              label={formData?.claims_complete ? 'Ja' : 'Nei'}
            />
          </Grid>
          <Grid
            item
            xs={4}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <FormLabel component="legend">Done adding assets</FormLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={formData?.real_assets_complete || false}
                  onChange={handleChange('real_assets_complete')}
                  name="real_assets_complete"
                />
              }
              label={formData?.real_assets_complete ? 'Ja' : 'Nei'}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              onClick={handleUpdate}
              startIcon={<Save />}
              sx={{ float: 'right' }}
            >
              Lagre
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleLocale}>
              Language: {locale}
            </Button>
          </Grid>
        </Grid>
      </MainCard>
    </div>
  );
}
