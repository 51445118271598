import { Chip, Tooltip, styled, tooltipClasses } from '@mui/material';
import { useState } from 'react';

const GreenTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default function ClipChip({ data }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(data);
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  };

  return (
    <GreenTooltip
      title="Copied to clipboard"
      placement="right"
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={tooltipOpen}
    >
      <Chip
        color="default"
        size="small"
        clickable
        onClick={handleCopy}
        label={data}
      />
    </GreenTooltip>
  );
}
