/* eslint-disable no-unused-vars */
import { Box, Grid, Typography } from '@mui/material';
import { useEstate } from 'query/estates';
import useStore from 'store';
import { gridSpacing } from 'store/constant';
import TextCard from './TextCard';

export default function LetterTemplates(params) {
  const store = useStore();
  const estate = useEstate({ uuid: store.activeEstate });

  console.log(estate.data);
  const deceased_name = estate.data?.name;
  const deceased_ssn = estate.data?.deceased_ssn;
  const deceased_deathDate = estate.data?.deceased_date;
  const owner_name = `${estate.data?.owner.profile.fname} ${estate.data?.owner.profile.lname}`;
  const owner_email = estate.data?.owner.email;

  return (
    <Box className="container mx-auto space-y-5">
      <Typography variant="h2">Templates</Typography>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={3}>
          <TextCard
            title={'Oppsigelse av Strømabonement'}
            text={`Hei,

Jeg, ${owner_name}, ønsker å informere om at ${deceased_name}, fødselsnummer ${deceased_ssn}, er dessverre gått bort.
                        
Vi ber om at strømabonnementet til ${deceased_name} avsluttes fra dags dato. Vennligst send utestående fakturaer til følgende e-postadresse: ${owner_email}.
                        
Vi takker for forståelsen og ber om at du bekrefter mottak av denne oppsigelsen så snart som mulig.
            
Vedlagt ligger kopi av skifteattest.
                        
Vennlig hilsen,
                        
${owner_name}`}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextCard
            title={'Oppsigelse av Bonuskort'}
            text={`Hei,

Jeg, ${owner_name}, ønsker å informere om at ${deceased_name}, fødselsnummer ${deceased_ssn}, er dessverre gått bort.

Vi ber om at bonuskortet til ${deceased_name} sies opp fra dags dato. Eventuelle gjenværende midler på kortet ønskes utbetalt.

Vennligst overfør resterende midler til følgende konto:
Bank: [Ditt banknavn]
Kontonummer: [Ditt kontonummer]
Navn på konto: [Ditt navn]

Vi takker for forståelsen og ber om at du bekrefter mottak av denne oppsigelsen samt informerer om prosedyren for utbetaling av gjenværende midler så snart som mulig.

Vedlagt ligger kopi av skifteattest.

Vennlig hilsen,

${owner_name}`}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
