import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom'; // Or your preferred routing library
import SendMailModal from './SendMailModal'; // Assuming this is your custom component

const CustomSubAccordion = ({
  taskManager,
  taskKey,
  title,
  detailText,
  actionComponent,
  actionLink = false,
  actionText,
  isMailModal = false,
  actionLinkInternal,
  mailTag,
  toggleable = true,
  customDetail = false,
}) => {
  const {
    expandedSubPanel,
    handleSubAccordionChange,
    getStatusToggleButton,
    tasks,
  } = taskManager;
  const theme = useTheme();
  const backgroundColor = tasks[taskKey]
    ? theme.palette.success.light
    : 'default';

  const StatusToggleButton = getStatusToggleButton(taskKey);

  return (
    <Accordion
      expanded={expandedSubPanel === taskKey}
      onChange={handleSubAccordionChange(taskKey)}
      sx={{ backgroundColor }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id={`${taskKey}-header`}
      >
        <div className="flex items-center gap-2">
          {toggleable && <StatusToggleButton task={taskKey} />}
          <Typography variant="caption">{title}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {customDetail ? (
          customDetail
        ) : (
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>{detailText}</Typography>
        )}
      </AccordionDetails>
      <AccordionActions>
        {isMailModal ? (
          <SendMailModal tag={mailTag} buttonText={actionText} />
        ) : actionLink ? (
          <Link target="_blank" to={actionLink}>
            <Button variant="contained">{actionText}</Button>
          </Link>
        ) : actionLinkInternal ? (
          <Link to={actionLinkInternal}>
            <Button variant="contained">{actionText}</Button>
          </Link>
        ) : (
          actionComponent || null
        )}
      </AccordionActions>
    </Accordion>
  );
};

export default CustomSubAccordion;
