import { CheckCircleOutline } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useCreateRealAsset } from 'query/asset';
import { useEstate, useUpdateEstateStatus } from 'query/estates';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from 'store';
import { gridSpacing } from 'store/constant';

export default function FinalStep({
  formData,
  handleChange,
  // handleNextButton,
  handleUpdate,
}) {
  const activeEstate = useStore((state) => state.activeEstate);
  const estate = useEstate({ uuid: activeEstate });
  const create = useCreateRealAsset();
  const navigate = useNavigate();

  const update = useUpdateEstateStatus({ uuid: activeEstate });

  // const [timerDone, setTimerDone] = useState(false);
  // const [caption, setCaption] = useState('');

  const handleCreateAsset = async (name, type) => {
    const assetData = {
      type: type,
      title: name,
    };

    create.mutate(
      {
        ...assetData,
        attachments: [],
        estate: estate.data.id,
      },
      {
        onSuccess: (data) => {
          console.log('Mutation succeeded. Data:', data);
        },
        onError: (error) => {
          console.log('Error:', error);
        },
      },
    );
    // console.log(assetData);
  };

  const handleEstateStatus = async () => {
    update.mutate(
      { uuid: activeEstate },
      {
        onSuccess: (data) => {
          console.log('Status updated');
        },
        onError: (error) => {
          console.log('could not update status', error);
        },
      },
    );
  };

  useEffect(() => {
    handleEstateStatus();
    if (formData?.b2c_estate_questions.has_realestate) {
      handleCreateAsset('Real Estate', 'real_estate');
    }
    if (formData?.b2c_estate_questions.has_vacationhome) {
      handleCreateAsset('Vacation Home', 'real_estate');
    }
    if (formData?.b2c_estate_questions.has_agricultural_property) {
      handleCreateAsset('Agricultural Property', 'agricultural_property');
    }

    console.log('STATUS: ', formData.status);

    // setTimeout(() => setTimerDone(true), 800);
    // fakeLoader();

    // return () => {
    //   // Clear all timeouts
    //   // eslint-disable-next-line no-implied-eval
    //   let highestTimeoutId = setTimeout(';');
    //   for (let i = 0; i < highestTimeoutId; i++) {
    //     clearTimeout(i);
    //   }
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const fakeLoader = () => {
  //   const captions = ['Adding stakeholders', 'Fetching Data', 'Adding assets'];
  //   let delay = 2000;

  //   captions.forEach((caption, index) => {
  //     setTimeout(() => {
  //       setCaption(caption);
  //       // Set timerDone to true on the last caption
  //       if (index === captions.length - 1) {
  //         setTimerDone(true);
  //       }
  //     }, index * delay);
  //   });
  // };

  return (
    <Grid
      container
      spacing={gridSpacing}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Typography variant="h3" align="center">
          Boet ble opprettet!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <CheckCircleOutline color="success" style={{ fontSize: 60 }} />
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate('/dashboard')}
            >
              Fullfør
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
