import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import MainCard from 'components/MainCard';
import useFormHandler from 'hooks/useFormHandler';
import { useProfile, useUpdateProfile } from 'query/profile';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { gridSpacing } from 'store/constant';

export default function CreateProfile(params) {
  const navigate = useNavigate();
  const user = useProfile();
  const { mutate } = useUpdateProfile();
  const { formData, setFormData, handleChange, errorMessage, setErrorMessage } =
    useFormHandler({});

  useEffect(() => {
    const userData = user.data;
    console.log('USERDATA', userData);
    // setId(userData.id);
    setFormData({ ...userData });
  }, [user.data]);

  const handleSubmit = async () => {
    setErrorMessage('');

    mutate(
      { ...formData },
      {
        onSuccess: (data) => {
          console.log('Mutatuin succeeded. Data: ', data);
          //   setFormData({
          //     ...data,
          //   });
          navigate('/'); //can handle newuser intro here
        },
        onError: (error) => {
          console.log('Error: ', error);
          setErrorMessage(error);
        },
      },
    );
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      <MainCard sx={{ maxWidth: '40%' }}>
        {user.isLoading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Typography variant="h2">Din info</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Fornavn"
                  onChange={handleChange('fname')}
                  value={formData.fname || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Etternavn"
                  onChange={handleChange('lname')}
                  value={formData.lname || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Gateadresse"
                  onChange={handleChange('address_street')}
                  value={formData.address_street || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Postnr"
                  onChange={handleChange('address_zip')}
                  value={formData.address_zip || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  label="By"
                  onChange={handleChange('address_city')}
                  value={formData.address_city || ''}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button onClick={handleSubmit} sx={{ float: 'right' }}>
                Save
              </Button>
            </Grid>
          </Grid>
        )}
      </MainCard>
    </div>
  );
}
