const mapHeirRelationToType = (heirRelation) => {
  switch (heirRelation) {
    case 'child':
      return 'life_heirs';
    case 'parent':
    case 'grandparent':
    case 'grandchild':
    case 'uncle_aunt':
    case 'nephew_niece':
      return 'heir';
    case 'spouse':
      return 'spouse';
    case 'other':
      return 'other';
    default:
      return '';
  }
};

export default mapHeirRelationToType;
