import { HighlightOff } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Typography,
} from '@mui/material';
import MainCard from 'components/MainCard';
import { useGetEmailTemplate } from 'query/estates';
import { useState } from 'react';
import { toast } from 'sonner';
import useStore from 'store';
import { gridSpacing } from 'store/constant';
import ClipChip from './ClipChip';
import FileListDownload from './FileListDownload';
import Mailto from './Mailto';

export default function SendMailModal({ tag, buttonText }) {
  const store = useStore();

  const [open, setOpen] = useState(false);
  const [emailTemplateData, setEmailTemplateData] = useState({});

  const { isLoading, mutate } = useGetEmailTemplate();
  const { body, sendTo, bcc, attachments, subject } = emailTemplateData || {};

  const handleGetMailTemplate = (tag) => {
    mutate(
      {
        uuid: store.activeEstate,
        template_name: tag,
      },
      {
        onSuccess: (data) => {
          setEmailTemplateData(data);
          handleOpen();
        },
        onError: (error) => {
          console.log(error);
        },
      },
    );
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const CopyToClipboardButton = ({ body }) => {
    const handleClick = () => {
      toast('Copied to clipboard');
      navigator.clipboard.writebody(body);
    };

    return (
      <>
        <Button onClick={handleClick} variant="outlined">
          Copy to clipboard
        </Button>
      </>
    );
  };

  return (
    <>
      <Button
        onClick={() => {
          handleOpen();
          handleGetMailTemplate(tag);
        }}
        variant="contained"
      >
        {buttonText}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-subject"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
          }}
        >
          <MainCard sx={{ height: '100%', position: 'relative' }}>
            <HighlightOff
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                cursor: 'pointer',
                color: 'inherit',
                '&:hover': {
                  color: 'red',
                },
                height: 30,
                width: 30,
              }}
            />
            {isLoading ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <Typography variant="h2">{subject}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid item xs={12}>
                    <Box
                      border={1}
                      sx={{
                        margin: 2,
                        height: 150,
                        // maxHeight: 100,
                        overflow: 'auto',
                        padding: 1,
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {/* <div dangerouslySetInnerHTML={{ __html: body }} /> */}
                      {body}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {/* <CopyToClipboardButton body={body} /> */}
                    <Mailto body={body} />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {sendTo && (
                    <Grid item xs={12}>
                      <Typography variant="overline">Send til:</Typography>
                      {/* <Chip
                      clickable
                      onClick={() => navigator.clipboard.writebody(sendTo)}
                      label={sendTo}
                    /> */}
                      <ClipChip data={sendTo} />
                    </Grid>
                  )}
                  {bcc && (
                    <Grid item xs={12}>
                      <Typography variant="overline">BCC til:</Typography>
                      <ClipChip data={bcc} />
                    </Grid>
                  )}
                  {attachments && (
                    <Grid item xs={12}>
                      <Typography variant="overline">
                        Filer som må legges ved:
                      </Typography>
                      <FileListDownload files={attachments} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </MainCard>
        </Box>
      </Modal>
    </>
  );
}
