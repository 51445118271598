import { Park, TableRows } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import 'assets/css/FamilyTree.css';
import TitleToggleMenu from 'components/TitleToggleMenu';
import {
  useCompleteStakeholders,
  useDeleteStakeholder,
  useEstate,
  useEstateStakeholders,
  useUpdateEstate,
} from 'query/estates';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';
import useStore from 'store';
import StakeholderForm from './StakeholderForm';
import StakeholderTableView from './StakeholderTableView';
import StakeholderTreeView from './StakeholderTreeView';
import messages from './messages';

export default function Stakeholders(params) {
  const activeEstate = useStore((state) => state.activeEstate);
  const stakeholderView = useStore((state) => state.stakeholderView);
  const toggleStakeholderView = useStore(
    (state) => state.toggleStakeholderView,
  );
  const estate = useEstate({ uuid: activeEstate });
  const update = useUpdateEstate();
  const deleteStakeholder = useDeleteStakeholder();
  const stakeholder = useEstateStakeholders({ uuid: activeEstate });
  const stakeholdersCompleteAction = useCompleteStakeholders({
    uuid: activeEstate,
  });

  const [createFormOpen, setCreateFormOpen] = useState(false);
  const [editingStakeholderId, setEditingStakeholderId] = useState(null);
  const [parentData, setParentData] = useState({});
  const [stakeholdersComplete, setStakeholdersComplete] = useState(false);
  const [missingStakeholderData, setMissingStakeholderData] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    if (estate.data && estate.data.stakeholders_complete !== undefined) {
      setStakeholdersComplete(estate.data.stakeholders_complete);
    }
  }, [estate.data]);

  const handleEditStakeholder = (stakeholderId) => {
    setEditingStakeholderId(stakeholderId);
  };

  const handleCreateOpenForm = () => setCreateFormOpen(true);
  const handleCreateCloseForm = () => {
    setCreateFormOpen(false);
    setParentData({});
  };

  const updateStakeholdersComplete = async () => {
    setMissingStakeholderData([]);
    stakeholdersCompleteAction.mutate(
      { uuid: activeEstate },
      {
        onSuccess: (data) => {
          if (data.status === 202) {
            setMissingStakeholderData(data.data);
            let delay = 0;
            const staggerInterval = 200;
            missingStakeholderData.forEach((item) => {
              Object.entries(item).forEach(([key, values]) => {
                setTimeout(() => {
                  const message = intl.formatMessage(
                    { ...messages.missingData },
                    { key: key, values: values },
                  );
                  toast.warning(message);
                }, delay);
                delay += staggerInterval;
              });
            });
          } else {
            console.log('Data:', data);
            toast.success(intl.formatMessage({ ...messages.heirsCompleted }));
            estate.refetch();
          }
        },
        onError: (error) => {
          console.log('Error:', error);
          toast.error(intl.formatMessage({ ...messages.errorOccurred }));
        },
      },
    );
  };

  const handleNewStakeholder = () => {
    setParentData({});
    handleCreateOpenForm();
  };

  const handleDelete = (id) => {
    deleteStakeholder.mutate(
      { id: id },
      {
        onSuccess: (data) => {
          stakeholder.refetch();
          console.log('Stakeholder ', id, ' deleted');
          toast.warning(
            intl.formatMessage({ ...messages.stakeholderDeleted }, { id: id }),
          );
        },
        onError: (error) => {
          console.log('Stakeholder ', id, ' could not be deleted ', error);
          intl.formatMessage(
            { ...messages.deleteStakeholderError },
            { id: id },
          );
        },
      },
    );
    console.log('item Deleted');
  };

  return (
    <Box className="container mx-auto h-full">
      <>
        <TitleToggleMenu
          title={intl.formatMessage({ ...messages.stakeholdersTitle })}
          iconLeft={<Park />}
          iconRight={<TableRows />}
          switchState={stakeholderView}
          toggleSwitch={toggleStakeholderView}
          id="stakeholdersTitleMenu"
          toggleId="stakeholdersToggleButton"
        >
          {!stakeholdersComplete && (
            <>
              <Button
                onClick={handleNewStakeholder}
                variant="contained"
                id="stakeholdersAddButton"
              >
                {/* Add new heir */}
                {intl.formatMessage({ ...messages.addNewHeir })}
              </Button>

              <Button
                onClick={updateStakeholdersComplete}
                id="stakeholdersCompleteButton"
              >
                {/* Done adding heirs */}
                {intl.formatMessage({ ...messages.doneAddingHeirs })}
              </Button>
            </>
          )}
        </TitleToggleMenu>
        {stakeholderView ? (
          <StakeholderTableView
            stakeholder={stakeholder}
            handleEditStakeholder={handleEditStakeholder}
            handleDelete={handleDelete}
          />
        ) : (
          <StakeholderTreeView
            estate={estate}
            stakeholder={stakeholder}
            stakeholdersComplete={stakeholdersComplete}
            handleEditStakeholder={handleEditStakeholder}
            handleCreateOpenForm={handleCreateOpenForm}
            setParentData={setParentData}
            handleDelete={handleDelete}
          />
        )}
      </>
      <StakeholderForm
        mode={'create'}
        open={createFormOpen}
        onClose={handleCreateCloseForm}
        parentData={parentData}
        refetch={stakeholder.refetch}
      />
      {editingStakeholderId && (
        <StakeholderForm
          mode={'update'}
          stakeholderId={editingStakeholderId}
          open={Boolean(editingStakeholderId)}
          onClose={() => setEditingStakeholderId(null)}
          refetch={stakeholder.refetch}
        />
      )}
      {/* <Snackbar
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setSnackOpen(false)}
        open={snackOpen}
      /> */}
    </Box>
  );
}
