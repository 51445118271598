// import { createTheme } from '@mui/material/styles';

// // assets
// import 'assets/css/_themes-vars.module.css';

// // project imports

// import useStore from 'store';
// import componentStyleOverrides from './compStyleOverride';
// import themePalette from './palette';
// import themeTypography from './typography';

// /**
//  * Represent theme style and structure as per Material-UI
//  * @param {JsonObject} customization customization parameter object
//  */

// export const theme = (customization) => {
//   const { darkMode } = useStore.getState();
//   // const themeOption = {
//   //   colors: {
//   //     paper: '#F5F5F5',

//   //     // primary
//   //     primaryLight: '#C3D7E9',
//   //     primaryMain: '#3F6B8E',
//   //     primaryDark: '#2E4C66',
//   //     primary200: '#A0C3E1',
//   //     primary800: '#1F425A',

//   //     // secondary
//   //     secondaryLight: '#D8D2E1',
//   //     secondaryMain: '#6B4782',
//   //     secondaryDark: '#543766',
//   //     secondary200: '#B29AC0',
//   //     secondary800: '#3C2653',

//   //     // success Colors
//   //     successLight: '#D1F0DC',
//   //     success200: '#8CE4B2',
//   //     successMain: '#4CAF50',
//   //     successDark: '#388E3C',

//   //     // error
//   //     errorLight: '#F4CCCC',
//   //     errorMain: '#E53935',
//   //     errorDark: '#B71C1C',

//   //     // orange
//   //     orangeLight: '#FCE8E4',
//   //     orangeMain: '#FF8A65',
//   //     orangeDark: '#D85A3D',

//   //     // warning
//   //     warningLight: '#FFF7E0',
//   //     warningMain: '#FFC107',
//   //     warningDark: '#FFA000',

//   //     // grey
//   //     grey50: '#F8F9FA',
//   //     grey100: '#F5F5F5',
//   //     grey200: '#E0E0E0',
//   //     grey300: '#BDBDBD',
//   //     grey500: '#757575',
//   //     grey600: '#424242',
//   //     grey700: '#2E2E2E',
//   //     grey900: '#121212',

//   //     // DARK THEME VARIANTS

//   //     // paper & background
//   //     darkBackground: '#1D1D1D',
//   //     darkPaper: '#222222',

//   //     // dark 800 & 900
//   //     darkLevel1: '#2E2E2E',
//   //     darkLevel2: '#272727',

//   //     // primary dark
//   //     darkPrimaryLight: '#C3D7E9',
//   //     darkPrimaryMain: '#3F6B8E',
//   //     darkPrimaryDark: '#2E4C66',
//   //     darkPrimary200: '#A0C3E1',
//   //     darkPrimary800: '#1F425A',

//   //     // secondary dark
//   //     darkSecondaryLight: '#B09AC1',
//   //     darkSecondaryMain: '#6B4782',
//   //     darkSecondaryDark: '#543766',
//   //     darkSecondary200: '#B29AC0',
//   //     darkSecondary800: '#3C2653',

//   //     // text variants
//   //     darkTextTitle: '#D7D7D7',
//   //     darkTextPrimary: '#B0B0B0',
//   //     darkTextSecondary: '#7D7D7D',
//   //   },
//   //   heading: '#121926',
//   //   paper: '#F5F5F5',
//   //   backgroundDefault: '#F5F5F5',
//   //   background: '#F1F1FF',
//   //   darkTextPrimary: '#424242',
//   //   darkTextSecondary: '#757575',
//   //   textDark: '#121926',
//   //   menuSelected: '#6B4782',
//   //   menuSelectedBack: '#D8D2E1',
//   //   divider: '#E0E0E0',
//   //   customization,
//   // };

//   // const themeOption = {
//   //   colors: {
//   //     paper: '#F5F5F5',

//   //     // primary
//   //     primaryLight: '#BDC8D1', // Soft light blue-grey, evoking calmness
//   //     primaryMain: '#5A6F7B', // Medium blue-grey, for main elements
//   //     primaryDark: '#3B4E57', // Darker blue-grey, for emphasis
//   //     primary200: '#A0B3BF', // Lighter variant for hover states or secondary actions
//   //     primary800: '#2E3F48', // Much darker blue-grey for depth or accent

//   //     // secondary
//   //     secondaryLight: '#C7CDD3', // A lighter shade of grey for secondary elements
//   //     secondaryMain: '#707C87', // A neutral, muted grey for secondary text
//   //     secondaryDark: '#4D5962', // Darker grey for secondary emphasis
//   //     secondary200: '#B0BEC5', // Very light grey for backgrounds or fields
//   //     secondary800: '#37474F', // Deep grey for strong secondary elements

//   //     // success Colors
//   //     successLight: '#E8F5E9', // A muted green for success highlights
//   //     success200: '#A5D6A7', // A lighter green for success states
//   //     successMain: '#4CAF50', // A calm, reassuring green for success actions
//   //     successDark: '#388E3C', // Dark green for success emphasis

//   //     // error
//   //     errorLight: '#FFEBEE', // Light pink for error highlights
//   //     errorMain: '#E53935', // Soft red for error messages
//   //     errorDark: '#B71C1C', // Dark red for critical errors

//   //     // orange (used sparingly for attention)
//   //     orangeLight: '#FBE9E7', // Very light orange for warnings or notices
//   //     orangeMain: '#FFAB91', // Soft orange for cautionary elements
//   //     orangeDark: '#D84315', // Darker orange for more urgent warnings

//   //     // warning
//   //     warningLight: '#FFF8E1', // Light yellow for mild warnings
//   //     warningMain: '#FFC107', // Bright yellow for warnings that need attention
//   //     warningDark: '#FFA000', // Amber for urgent warnings

//   //     // grey (neutral palette base)
//   //     grey50: '#FAFAFA',
//   //     grey100: '#F5F5F5',
//   //     grey200: '#EEEEEE',
//   //     grey300: '#E0E0E0',
//   //     grey500: '#9E9E9E',
//   //     grey600: '#757575',
//   //     grey700: '#616161',
//   //     grey900: '#212121',

//   //     // DARK THEME VARIANTS

//   //     // paper & background
//   //     darkBackground: '#1D1D1D',
//   //     darkPaper: '#222222',

//   //     // dark 800 & 900
//   //     darkLevel1: '#2E2E2E',
//   //     darkLevel2: '#272727',

//   //     // primary dark
//   //     darkPrimaryLight: '#BDC8D1',
//   //     darkPrimaryMain: '#5A6F7B',
//   //     darkPrimaryDark: '#3B4E57',
//   //     darkPrimary200: '#A0B3BF',
//   //     darkPrimary800: '#2E3F48',

//   //     // secondary dark
//   //     darkSecondaryLight: '#C7CDD3',
//   //     darkSecondaryMain: '#707C87',
//   //     darkSecondaryDark: '#4D5962',
//   //     darkSecondary200: '#B0BEC5',
//   //     darkSecondary800: '#37474F',

//   //     // text variants
//   //     darkTextTitle: '#ECEFF1',
//   //     darkTextPrimary: '#CFD8DC',
//   //     darkTextSecondary: '#B0BEC5',
//   //   },
//   //   heading: '#121926',
//   //   paper: '#F5F5F5',
//   //   backgroundDefault: '#F5F5F5',
//   //   background: '#ECEFF1',
//   //   darkTextPrimary: '#424242',
//   //   darkTextSecondary: '#757575',
//   //   textDark: '#121926',
//   //   menuSelected: '#5A6F7B',
//   //   menuSelectedBack: '#BDC8D1',
//   //   divider: '#E0E0E0',
//   //   customization,
//   // };

// const lightTheme = {
//   colors: {
//     paper: '#F5F5F5',

//     // primary
//     primaryLight: '#BDC8D1',
//     primaryMain: '#5A6F7B',
//     primaryDark: '#3B4E57',
//     primary200: '#A0B3BF',
//     primary800: '#2E3F48',

//     // secondary
//     secondaryLight: '#C7CDD3',
//     secondaryMain: '#707C87',
//     secondaryDark: '#4D5962',
//     secondary200: '#B0BEC5',
//     secondary800: '#37474F',

//     // success
//     successLight: '#E8F5E9',
//     success200: '#A5D6A7',
//     successMain: '#4CAF50',
//     successDark: '#388E3C',

//     // error
//     errorLight: '#FFEBEE',
//     errorMain: '#E53935',
//     errorDark: '#B71C1C',

//     // orange
//     orangeLight: '#FBE9E7',
//     orangeMain: '#FFAB91',
//     orangeDark: '#D84315',

//     // warning
//     warningLight: '#FFF8E1',
//     warningMain: '#FFC107',
//     warningDark: '#FFA000',

//     // grey
//     grey50: '#FAFAFA',
//     grey100: '#F5F5F5',
//     grey200: '#EEEEEE',
//     grey300: '#E0E0E0',
//     grey500: '#9E9E9E',
//     grey600: '#757575',
//     grey700: '#616161',
//     grey900: '#212121',
//   },
//   heading: '#121926',
//   paper: '#F5F5F5',
//   backgroundDefault: '#F5F5F5',
//   background: '#ECEFF1',
//   darkTextPrimary: '#424242',
//   darkTextSecondary: '#757575',
//   textDark: '#121926',
//   menuSelected: '#5A6F7B',
//   menuSelectedBack: '#BDC8D1',
//   divider: '#E0E0E0',
//   customization,
// };

// const darkTheme = {
//   colors: {
//     paper: '#263238', // Dark grey background

//     // primary
//     primaryLight: '#4F5A62',
//     primaryMain: '#263238', // Dark blue-grey
//     primaryDark: '#1C262B',
//     primary200: '#455A64',
//     primary800: '#102027',

//     // secondary
//     secondaryLight: '#546E7A',
//     secondaryMain: '#455A64', // Dark grey-blue
//     secondaryDark: '#37474F',
//     secondary200: '#607D8B',
//     secondary800: '#263238',

//     // success
//     successLight: '#2E7D32',
//     success200: '#388E3C',
//     successMain: '#1B5E20',
//     successDark: '#124116',

//     // error
//     errorLight: '#B71C1C',
//     errorMain: '#880E4F', // Dark red-purple
//     errorDark: '#4A148C',

//     // orange
//     orangeLight: '#FF7043',
//     orangeMain: '#EF6C00', // Dark orange
//     orangeDark: '#E65100',

//     // warning
//     warningLight: '#FFD54F',
//     warningMain: '#FFA000', // Dark yellow-orange
//     warningDark: '#FF6F00',

//     // grey
//     grey50: '#212121',
//     grey100: '#424242',
//     grey200: '#616161',
//     grey300: '#757575',
//     grey500: '#9E9E9E',
//     grey600: '#BDBDBD',
//     grey700: '#E0E0E0',
//     grey900: '#FFFFFF', // White text
//   },
//   heading: '#FFFFFF', // White heading text
//   paper: '#263238', // Dark grey paper background
//   backgroundDefault: '#263238', // Dark blue-grey background
//   background: '#1C262B', // Dark blue background
//   darkTextPrimary: '#FFFFFF', // White primary text
//   darkTextSecondary: '#BDBDBD', // Light grey secondary text
//   textDark: '#FFFFFF', // White text
//   menuSelected: '#263238', // Dark blue-grey selected menu
//   menuSelectedBack: '#455A64', // Dark grey-blue selected menu background
//   divider: '#37474F', // Dark secondary divider
//   customization,
// };

//   const themeOptions = {
//     direction: 'ltr',
//     palette: themePalette(darkMode ? darkTheme : lightTheme),
//     mixins: {
//       toolbar: {
//         minHeight: '48px',
//         padding: '16px',
//         '@media (min-width: 600px)': {
//           minHeight: '48px',
//         },
//       },
//     },
//     typography: themeTypography(darkMode ? darkTheme : lightTheme),
//     customization: {
//       fontFamily: `'Inter', sans-serif`,
//       borderRadius: 24,
//     },
//   };

//   const themes = createTheme(themeOptions);
//   themes.components = componentStyleOverrides(
//     darkMode ? darkTheme : lightTheme,
//   );

//   return themes;
// };

// export default theme;

import { createTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import useStore from 'store';
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

const generateTheme = (darkMode, customization) => {
  // const lightTheme = {
  //   colors: {
  //     paper: '#F5F5F5',

  //     // primary
  //     primaryLight: '#BDC8D1',
  //     primaryMain: '#5A6F7B',
  //     primaryDark: '#3B4E57',
  //     primary200: '#A0B3BF',
  //     primary800: '#2E3F48',

  //     // secondary
  //     secondaryLight: '#C7CDD3',
  //     secondaryMain: '#707C87',
  //     secondaryDark: '#4D5962',
  //     secondary200: '#B0BEC5',
  //     secondary800: '#37474F',

  //     // success
  //     successLight: '#E8F5E9',
  //     success200: '#A5D6A7',
  //     successMain: '#4CAF50',
  //     successDark: '#388E3C',

  //     // error
  //     errorLight: '#FFEBEE',
  //     errorMain: '#E53935',
  //     errorDark: '#B71C1C',

  //     // orange
  //     orangeLight: '#FBE9E7',
  //     orangeMain: '#FFAB91',
  //     orangeDark: '#D84315',

  //     // warning
  //     warningLight: '#FFF8E1',
  //     warningMain: '#FFC107',
  //     warningDark: '#FFA000',

  //     // grey
  //     grey50: '#FAFAFA',
  //     grey100: '#F5F5F5',
  //     grey200: '#EEEEEE',
  //     grey300: '#E0E0E0',
  //     grey500: '#9E9E9E',
  //     grey600: '#757575',
  //     grey700: '#616161',
  //     grey900: '#212121',
  //   },
  //   heading: '#121926',
  //   paper: '#F5F5F5',
  //   backgroundDefault: '#F5F5F5',
  //   background: '#ECEFF1',
  //   darkTextPrimary: '#424242',
  //   darkTextSecondary: '#757575',
  //   textDark: '#121926',
  //   menuSelected: '#5A6F7B',
  //   menuSelectedBack: '#BDC8D1',
  //   divider: '#E0E0E0',
  //   // mode: "light"
  //   customization,
  // };

  const lightTheme = {
    colors: {
      paper: '#F7F7FA',

      // primary
      primaryLight: '#D1D0E0',
      primaryMain: '#69668C',
      primaryDark: '#4C4A66',
      primary200: '#AFAFC0',
      primary800: '#333344',

      // secondary
      secondaryLight: '#D3D2DD',
      secondaryMain: '#73727F',
      secondaryDark: '#4F4E55',
      secondary200: '#B0AFB8',
      secondary800: '#2C2C30',

      // success
      successLight: '#E4F9E4',
      success200: '#A8D6A8',
      successMain: '#4CAF50',
      successDark: '#388E3C',

      // error
      errorLight: '#F9E4E4',
      errorMain: '#D65151',
      errorDark: '#933333',

      // orange
      orangeLight: '#FCEBD7',
      orangeMain: '#F4A261',
      orangeDark: '#E76F51',

      // warning
      warningLight: '#FFF7CC',
      warningMain: '#FFCA28',
      warningDark: '#FFB300',

      // grey
      grey50: '#FAFAFA',
      grey100: '#F5F5F5',
      grey200: '#EEEEEE',
      grey300: '#E0E0E0',
      grey500: '#9E9E9E',
      grey600: '#757575',
      grey700: '#616161',
      grey900: '#212121',
      heading: '#262536',
      backgroundDefault: '#fcfbff',
      background: '#fcfbff',
      // darkTextPrimary: '#333333',
      // darkTextSecondary: '#666666',
      // textDark: '#262536',
      menuSelected: '#69668C',
      menuSelectedBack: '#D1D0E0',
      divider: '#E0E0E0',
    },
    heading: '#262536',
    paper: '#F7F7FA',
    backgroundDefault: '#fcfbff',
    background: '#fcfbff',
    darkTextPrimary: '#333333',
    darkTextSecondary: '#666666',
    textDark: '#262536',
    menuSelected: '#69668C',
    menuSelectedBack: '#D1D0E0',
    divider: '#E0E0E0',
    // mode: "light"
    customization,
  };

  // const darkTheme = {
  //   colors: {
  //     paper: '#263238', // Dark grey background

  //     // primary
  //     primaryLight: '#4F5A62',
  //     primaryMain: '#4F5A62', // Dark blue-grey
  //     primaryDark: '#1C262B',
  //     primary200: '#455A64',
  //     primary800: '#102027',

  //     // secondary
  //     secondaryLight: '#546E7A',
  //     secondaryMain: '#455A64', // Dark grey-blue
  //     secondaryDark: '#37474F',
  //     secondary200: '#607D8B',
  //     secondary800: '#263238',

  //     // success
  //     successLight: '#2E7D32',
  //     success200: '#388E3C',
  //     successMain: '#1B5E20',
  //     successDark: '#124116',

  //     // error
  //     errorLight: '#B71C1C',
  //     errorMain: '#880E4F', // Dark red-purple
  //     errorDark: '#4A148C',

  //     // orange
  //     orangeLight: '#FF7043',
  //     orangeMain: '#EF6C00', // Dark orange
  //     orangeDark: '#E65100',

  //     // warning
  //     warningLight: '#FFD54F',
  //     warningMain: '#FFA000', // Dark yellow-orange
  //     warningDark: '#FF6F00',

  //     // grey
  //     grey50: '#212121',
  //     grey100: '#424242',
  //     grey200: '#616161',
  //     grey300: '#757575',
  //     grey500: '#9E9E9E',
  //     grey600: '#BDBDBD',
  //     grey700: '#E0E0E0',
  //     grey900: '#FFFFFF', // White text
  //   },
  //   heading: '#FFFFFF', // White heading text
  //   paper: '#263238', // Dark grey paper background
  //   backgroundDefault: '#263238', // Dark blue-grey background
  //   background: '#1C262B', // Dark blue background
  //   darkTextPrimary: '#FFFFFF', // White primary text
  //   darkTextSecondary: '#BDBDBD', // Light grey secondary text
  //   textDark: '#FFFFFF', // White text
  //   menuSelected: '#263238', // Dark blue-grey selected menu
  //   menuSelectedBack: '#455A64', // Dark grey-blue selected menu background
  //   divider: '#37474F', // Dark secondary divider
  //   // mode: "dark",
  //   customization,
  // };

  const darkTheme = {
    colors: {
      paper: '#212121',

      // primary
      primaryLight: '#4A4C66',
      primaryMain: '#69668C',
      primaryDark: '#333344',
      primary200: '#8C8FBF',
      primary800: '#1C1C28',

      // secondary
      secondaryLight: '#42415C',
      secondaryMain: '#73727F',
      secondaryDark: '#2C2C34',
      secondary200: '#595866',
      secondary800: '#1A1A20',

      // success
      successLight: '#33523C',
      success200: '#4CAF50',
      successMain: '#388E3C',
      successDark: '#1D3621',

      // error
      errorLight: '#933333',
      errorMain: '#D65151',
      errorDark: '#6A2C2C',

      // orange
      orangeLight: '#C4572C',
      orangeMain: '#F4A261',
      orangeDark: '#8F3F1D',

      // warning
      warningLight: '#665C2B',
      warningMain: '#FFCA28',
      warningDark: '#4F471A',

      // grey
      grey50: '#2E2E2E',
      grey100: '#424242',
      grey200: '#616161',
      grey300: '#757575',
      grey500: '#9E9E9E',
      grey600: '#BDBDBD',
      grey700: '#E0E0E0',
      grey900: '#FAFAFA',
    },
    heading: '#F7F7FA',
    paper: '#2e2b44',
    backgroundDefault: '#232136',
    background: '#232136',
    darkTextPrimary: '#FAFAFA',
    darkTextSecondary: '#BDBDBD',
    textDark: '#F7F7FA',
    menuSelected: '#69668C',
    menuSelectedBack: '#333344',
    divider: '#424242',
    // mode: "dark"
    customization,
  };

  const selectedTheme = darkMode ? darkTheme : lightTheme;

  const themeOptions = {
    direction: 'ltr',
    palette: themePalette(selectedTheme),
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '16px',
        '@media (min-width: 600px)': {
          minHeight: '48px',
        },
      },
    },
    components: componentStyleOverrides(selectedTheme),
    typography: themeTypography(selectedTheme),
    customization: customization,
  };

  const theme = createTheme(themeOptions);
  // theme.components = componentStyleOverrides(selectedTheme);

  return theme;
};

const useCustomTheme = (customization) => {
  const { darkMode } = useStore.getState();
  // const customization = useMemo(
  //   () => ({
  //     fontFamily: `'Inter', sans-serif`,
  //     borderRadius: 24,
  //     mode: darkMode ? 'dark' : 'light',
  //   }),
  //   [darkMode],
  // );
  // const customization = {
  //   fontFamily: `'Inter', sans-serif`,
  //   borderRadius: 24,
  //   mode: darkMode ? 'dark' : 'light',
  // };
  // console.log('Has Changed', darkMode);
  return useMemo(
    () => generateTheme(darkMode, customization),
    [darkMode, customization],
  );
};

export default useCustomTheme;
