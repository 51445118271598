import { CreditCard, Money } from '@mui/icons-material';
import { Divider, Grid, Tooltip, Typography } from '@mui/material';
import EditDeletePopup from 'components/EditDeletePopup';
import MainCard from 'components/MainCard';
import { FormattedNumber, useIntl } from 'react-intl';
import { gridSpacing } from 'store/constant';
import messages from './messages';

export default function ClaimsGridView({
  claimsData,
  handleEditClaim,
  handleDelete,
}) {
  const intl = useIntl();
  return (
    <Grid container spacing={gridSpacing} justifyContent="center">
      {claimsData.length > 0 ? (
        <>
          <Grid item xs={12} md={5.5} justifyContent="center">
            <Typography variant="h3" className="pb-3">
              {intl.formatMessage(messages.preferential)}
            </Typography>
            <Grid container spacing={gridSpacing}>
              {claimsData &&
                claimsData
                  .filter((e) => e.type_category === 'preferential')
                  .map((e) => (
                    <Grid item xs={12} md={6} key={e.id}>
                      <MainCard>
                        <div className="flex items-center justify-between">
                          <div className="flex items-end gap-2">
                            <Tooltip
                              title={e.get_type_category_display}
                              placement="left"
                              className="flex items-end"
                            >
                              {e.type_category === 'outlay' ? (
                                <Money />
                              ) : (
                                <CreditCard />
                              )}
                            </Tooltip>
                            <Typography variant="h4">{e.title}</Typography>
                          </div>
                          <EditDeletePopup
                            handleEdit={() => handleEditClaim(e.id)}
                            handleDelete={() => handleDelete(e.id)}
                          />
                        </div>

                        <div className="flex items-center justify-between pt-3">
                          <div>
                            <Typography variant="overline">
                              {e.stakeholder.name}
                            </Typography>
                          </div>
                          <Typography variant="h4">
                            <FormattedNumber
                              value={e.value}
                              style={'currency'}
                              currency="nok"
                            />
                          </Typography>
                        </div>
                      </MainCard>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Divider
              orientation={{ md: 'vertical' }}
              variant="middle"
              sx={{ width: '6px' }}
            />
          </Grid>
          <Grid item xs={12} md={5.5} justifyContent="center">
            <Typography variant="h3" className="pb-3">
              {intl.formatMessage(messages.outlay)}
            </Typography>
            <Grid container spacing={gridSpacing}>
              {claimsData &&
                claimsData
                  .filter((e) => e.type_category === 'outlay')
                  .map((e) => (
                    <Grid item xs={12} md={6} key={e.id}>
                      <MainCard>
                        <div className="flex items-center justify-between">
                          <div className="flex items-end gap-2">
                            <Tooltip
                              title={e.get_type_category_display}
                              placement="left"
                              className="flex items-end"
                            >
                              {e.type_category === 'outlay' ? (
                                <Money />
                              ) : (
                                <CreditCard />
                              )}
                            </Tooltip>
                            <Typography variant="h4">{e.title}</Typography>
                          </div>
                          <EditDeletePopup
                            handleEdit={() => handleEditClaim(e.id)}
                            handleDelete={() => handleDelete(e.id)}
                          />
                        </div>

                        <div className="flex items-center justify-between pt-3">
                          <div>
                            <Typography variant="overline">
                              {e.stakeholder.name}
                            </Typography>
                          </div>
                          <Typography variant="h4">
                            <FormattedNumber
                              value={e.value}
                              style="currency"
                              currency="nok"
                            />
                          </Typography>
                        </div>
                      </MainCard>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} className="text-center">
          <Typography variant="caption">
            {intl.formatMessage(messages.emptyMessage)}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
