import { Grid, Tooltip, Typography } from '@mui/material';
import EditDeletePopup from 'components/EditDeletePopup';
import MainCard from 'components/MainCard';
import { FormattedNumber, useIntl } from 'react-intl';
import { gridSpacing } from 'store/constant';
import assetTypeIcon from 'utils/assetTypeIcon';
import messages from './messages';

export default function AssetGridView({
  assetsData,
  handleEditAsset,
  handleDelete,
}) {
  const intl = useIntl();
  return (
    <Grid container spacing={gridSpacing}>
      {assetsData?.length > 0 ? (
        assetsData?.map((a) => (
          <Grid item xs={12} md={3} key={a.id}>
            <MainCard>
              <div className="flex items-center justify-between">
                <div className="flex items-end gap-2">
                  <Tooltip title={a.type}>{assetTypeIcon(a.type)}</Tooltip>
                  <Typography variant="h4">{a.title}</Typography>
                </div>
                <EditDeletePopup
                  handleEdit={() => handleEditAsset(a.id)}
                  handleDelete={() => handleDelete(a.id)}
                />
              </div>
              <div className="py-3">
                <Typography variant="body">{a.description}</Typography>
              </div>

              <div className="flex items-center justify-between pt-3">
                <div>
                  {a.transfered_to_heir && (
                    <Typography variant="overline">
                      {a.transfered_to_heir}
                    </Typography>
                  )}
                </div>
                <Typography variant="h4">
                  <FormattedNumber
                    value={a.value}
                    style="currency"
                    currency="nok"
                  />
                </Typography>
              </div>
              {/* <FormattedNumber
              value={a.value}
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency="nok"
            /> */}
            </MainCard>
          </Grid>
        ))
      ) : (
        <Grid item xs={12} className="text-center">
          <Typography variant="caption">
            {intl.formatMessage(messages.emptyText)}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
