import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import FileUploadButton from 'components/FileUploadButton';
import { gridSpacing } from 'store/constant';

export default function WillQuestion({ formData, handleChange, nextButton }) {
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Typography variant="h2">Testament / Ektepakt</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Har avdøde testament?</FormLabel>
        <RadioGroup
          onChange={handleChange('deceased_has_will')}
          value={formData?.deceased_has_will || false}
          row
        >
          <FormControlLabel label="Ja" control={<Radio />} value={true} />
          <FormControlLabel label="Nei" control={<Radio />} value={false} />
        </RadioGroup>
      </Grid>
      {formData?.deceased_has_will && (
        <Grid item xs={12} marginLeft={3} borderLeft={1}>
          {/* <Grid item xs={12}>
            <FormLabel>Skal arvinger få minstearv?</FormLabel>
            <RadioGroup
              onChange={handleChange('heirs_inherit_minimum')}
              value={formData?.heirs_inherit_minimum || false}
              row
            >
              <FormControlLabel label="Ja" control={<Radio />} value={true} />
              <FormControlLabel label="Nei" control={<Radio />} value={false} />
            </RadioGroup>
          </Grid> */}
          <Grid item xs={12}>
            <FileUploadButton
              label={'Last opp'}
              onFileChange={handleChange('new_death_will')}
            />
            <Typography variant="caption">
              Filen blir lagt i vårt sikre datarom. Du får enkel tilgang til
              alle dine filer herfra.
            </Typography>
          </Grid>
        </Grid>
      )}
      {/* <Grid item xs={12}>
        <FormLabel>Har avdøde ektepakt?</FormLabel>

        <RadioGroup
          onChange={handleChange('deceased_has_ektepakt')}
          value={formData?.deceased_has_ektepakt || false}
          row
        >
          <FormControlLabel label="Ja" control={<Radio />} value={true} />
          <FormControlLabel label="Nei" control={<Radio />} value={false} />
        </RadioGroup>
      </Grid>
      {formData?.deceased_has_ektepakt && (
        <Grid item xs={12} marginLeft={3} borderLeft={1}>
          <FileUploadButton label={'Last opp'} />
          <Typography variant="caption">
            Filen blir lagt i vårt sikre datarom. Du får enkel tilgang til alle
            dine filer herfra.
          </Typography>
        </Grid>
      )} */}

      <Grid item xs={12}>
        {/* {backButton()} */}
        {nextButton()}
      </Grid>
    </Grid>
  );
}
