import { GridView, TableRows } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import TitleToggleMenu from 'components/TitleToggleMenu';
import {
  useDeleteAsset,
  useEstate,
  useEstateAssets,
  useGetVehicleData,
  useUpdateEstate,
} from 'query/estates';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';
import useStore from 'store';
import AssetForm from './AssetForm';
import AssetGridView from './AssetGridView';
import AssetTableView from './AssetTableView';
import messages from './messages';

export default function Asset(params) {
  const intl = useIntl();
  const activeEstate = useStore((state) => state.activeEstate);
  const assetView = useStore((state) => state.assetView);
  const toggleAssetView = useStore((state) => state.toggleAssetView);
  const estate = useEstate({ uuid: activeEstate });
  const update = useUpdateEstate();
  const assets = useEstateAssets({ uuid: activeEstate });
  const vehicleData = useGetVehicleData({ uuid: activeEstate, enabled: false });
  const deleteAsset = useDeleteAsset();

  const [createFormOpen, setCreateFormOpen] = useState(false);
  const [editingAssetId, setEditingAssetId] = useState(null);
  const [assetsComplete, setAssetsComplete] = useState(false);
  const [assetsData, setAssetsData] = useState([]);

  let assetComp = estate.data?.real_assets_complete;

  useEffect(() => {
    setAssetsComplete(assetComp);
  }, [assetComp]);

  useEffect(() => {
    const newAssetsData = assets.data;
    setAssetsData(newAssetsData);
    console.log(assetsData);
  }, [assets.data, assets.isSuccess]);

  const handleEditAsset = (assetId) => {
    setEditingAssetId(assetId);
  };

  const handleCreateOpenForm = () => setCreateFormOpen(true);
  const handleCreateCloseForm = () => setCreateFormOpen(false);

  const assetComplete = async () => {
    update.mutate(
      { uuid: activeEstate, real_assets_complete: true },
      {
        onSuccess: (data) => {
          console.log('Mutation succeeded. Data:', data);
          toast.success(intl.formatMessage(messages.assetCompleted));
          estate.refetch();
        },
        onError: (error) => {
          console.log('Error:', error);
        },
      },
    );
  };

  const getVehicleData = () => {
    vehicleData.refetch();
    console.log(vehicleData.data);
  };

  const handleDelete = (assetId) => {
    deleteAsset.mutate(
      { id: assetId },
      {
        onSuccess: (data) => {
          console.log('Asset ', assetId, ' deleted');
          assets.refetch();
          toast.warning(intl.formatMessage(messages.assetDeleted));
        },
        onError: (error) => console.log('Could not delete asset! ', error),
      },
    );
  };

  return (
    <Box className="container mx-auto space-y-5">
      <Box>
        <TitleToggleMenu
          title={intl.formatMessage(messages.assetsTitle)}
          iconLeft={<GridView />}
          iconRight={<TableRows />}
          switchState={assetView}
          toggleSwitch={toggleAssetView}
        >
          {!assetsComplete && (
            <>
              <Button variant="contained" onClick={handleCreateOpenForm}>
                {intl.formatMessage(messages.addNewAsset)}
              </Button>
              <Button onClick={assetComplete}>
                {intl.formatMessage(messages.doneAddingAssets)}
              </Button>
              <Button onClick={getVehicleData}>
                {intl.formatMessage(messages.getVehicleData)}
              </Button>
            </>
          )}
        </TitleToggleMenu>
        <AssetForm
          mode={'create'}
          open={createFormOpen}
          onClose={() => handleCreateCloseForm()}
        />
        {editingAssetId && (
          <AssetForm
            mode={'update'}
            assetId={editingAssetId}
            open={Boolean(editingAssetId)}
            onClose={() => setEditingAssetId(null)}
          />
        )}
        {assetView ? (
          <AssetTableView
            assetsData={assetsData}
            handleEditAsset={handleEditAsset}
            handleDelete={handleDelete}
          />
        ) : (
          <AssetGridView
            assetsData={assetsData}
            handleEditAsset={handleEditAsset}
            handleDelete={handleDelete}
          />
        )}
      </Box>
    </Box>
  );
}
