import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import 'tailwindcss/tailwind.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from 'containers/App';
import { useMemo } from 'react';
import { Toaster } from 'sonner';
import useStore from 'store';
import useCustomTheme from 'themes';

const queryClient = new QueryClient();
// const customization = { fontFamily: `'Inter', sans-serif`, borderRadius: 24 };

const Root = () => {
  const darkMode = useStore((state) => state.darkMode);
  const customization = useMemo(
    () => ({
      fontFamily: `'Inter', sans-serif, 'Source Serif Pro', serif`,
      borderRadius: 18,
      mode: darkMode ? 'dark' : 'light',
    }),
    [darkMode],
  );
  const theme = useCustomTheme(customization);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster richColors />
        <App />
      </ThemeProvider>
    </QueryClientProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);

reportWebVitals();
