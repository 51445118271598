import {
  CardContent,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';

import MainCard from 'components/MainCard';
import useTaskManager from 'hooks/useTaskManager';
import { FormattedMessage } from 'react-intl';
import TaskStep1 from './TaskStep1';
import TaskStep2 from './TaskStep2';
import TaskStep3 from './TaskStep3';
import TaskStep4 from './TaskStep4';
import TaskStep5 from './TaskStep5';
import TaskStep6 from './TaskStep6';
import TaskStep7 from './TaskStep7';
import TaskStep8 from './TaskStep8';
import messages from './messages';

export default function TaskList({ isLoading }) {
  const theme = useTheme();
  const taskManager = useTaskManager();

  return (
    <MainCard>
      <CardContent sx={{ padding: { xs: 1, md: 1 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">
              <FormattedMessage {...messages.tasksTitle} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Container
              sx={{
                margin: { md: 2, xs: 1 },
                borderLeft: { md: 4, xs: 3 },
                color: theme.palette.primary.light,
              }}
            >
              <Typography variant="overline" color="GrayText">
                <FormattedMessage {...messages.introductoryTasks} />
              </Typography>
              <TaskStep1 taskManager={taskManager} />
              <TaskStep2 taskManager={taskManager} />
              <TaskStep3 taskManager={taskManager} />
            </Container>
            <Container
              sx={{
                margin: { md: 2, xs: 1 },
                borderLeft: { md: 4, xs: 3 },
                color: theme.palette.primary.main,
              }}
            >
              <Typography variant="overline" color="GrayText">
                <FormattedMessage {...messages.financialTasks} />
              </Typography>
              <TaskStep4 taskManager={taskManager} />
              <TaskStep5 taskManager={taskManager} />
            </Container>
            <Container
              sx={{
                margin: { md: 2, xs: 1 },
                borderLeft: { md: 4, xs: 3 },
                color: theme.palette.primary.dark,
              }}
            >
              <Typography variant="overline" color="GrayText">
                <FormattedMessage {...messages.concludingTasks} />
              </Typography>
              <TaskStep6 taskManager={taskManager} />
              <TaskStep7 taskManager={taskManager} />
              <TaskStep8 taskManager={taskManager} />
            </Container>
          </Grid>
        </Grid>
      </CardContent>
    </MainCard>
  );
}
