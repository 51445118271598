import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';
import { Button, ClickAwayListener, IconButton, Paper } from '@mui/material';
import { useState } from 'react';

export default function SimplePopup({
  children,
  icon,
  buttonText,
  disablePortal = false,
}) {
  const [anchor, setAnchor] = useState(null);

  const handleClick = (event) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;

  const Pop = () => (
    <ClickAwayListener onClickAway={handleClose}>
      <Popup
        id={id}
        open={open}
        anchor={anchor}
        placement={'right'}
        disablePortal={disablePortal}
        strategy={disablePortal ? 'fixed' : 'absolute'}
      >
        <Paper
          sx={{ borderRadius: 100, boxShadow: 3, padding: 1 }}
          onClick={handleClose}
        >
          {/* <div
          onClick={handleClose}
          className="z-10 h-auto w-max bg-white p-3 shadow-lg ring-1"
        > */}
          {children}
          {/* </div> */}
        </Paper>
      </Popup>
    </ClickAwayListener>
    // <Menu
    //   id="basic-menu"
    //   anchorEl={anchor}
    //   open={open}
    //   onClose={handleClose}
    //   MenuListProps={{
    //     'aria-labelledby': 'basic-button',
    //   }}
    // >
    //   <MenuItem>lol</MenuItem>
    //   <MenuItem>lol</MenuItem>
    // </Menu>
  );

  return (
    <>
      {icon !== undefined && (
        <IconButton aria-describedby={id} type="button" onClick={handleClick}>
          {icon}
        </IconButton>
      )}
      {buttonText !== undefined && (
        <Button onClick={handleClick} variant="contained">
          {buttonText}
        </Button>
      )}
      <Pop />
    </>
  );
}
