/* eslint-disable no-unused-vars */
import { useState } from 'react';

// material-ui
import { Box, Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// project imports
import MainCard from 'components/MainCard';

// assets
import { FormattedNumber } from 'react-intl';

const CardWrapper = styled(MainCard)(({ theme }) => ({
  // backgroundColor: theme.palette.secondary.dark,
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  // '&:after': {
  //   content: '""',
  //   position: 'absolute',
  //   width: 210,
  //   height: 210,
  //   background: theme.palette.secondary[800],
  //   borderRadius: '50%',
  //   top: -85,
  //   right: -95,
  //   [theme.breakpoints.down('sm')]: {
  //     top: -105,
  //     right: -140,
  //   },
  // },
  // '&:before': {
  //   content: '""',
  //   position: 'absolute',
  //   width: 210,
  //   height: 210,
  //   background: theme.palette.secondary[800],
  //   borderRadius: '50%',
  //   top: -125,
  //   right: -15,
  //   opacity: 0.5,
  //   [theme.breakpoints.down('sm')]: {
  //     top: -155,
  //     right: -70,
  //   },
  // },
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

export default function ValueCard({ isLoading, total, title, color }) {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <CardWrapper
      border={false}
      content={false}
      sx={{ backgroundColor: color ? color : theme.palette.secondary.main }}
    >
      <Box sx={{ p: { md: 2.25, xs: 1.4 } }}>
        <Grid container direction="column">
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h4" className="text-white">
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                {/* <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.secondary[200],
                    zIndex: 1,
                  }}
                  aria-controls="menu-earning-card"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <Edit fontSize="inherit" />
                </Avatar> */}
                {/* <Menu
                  id="menu-earning-card"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  variant="selectedMenu"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={handleClose}>
                    <GetAppTwoToneIcon sx={{ mr: 1.75 }} /> Import Card
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <FileCopyTwoToneIcon sx={{ mr: 1.75 }} /> Copy Data
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <PictureAsPdfTwoToneIcon sx={{ mr: 1.75 }} /> Export
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <ArchiveTwoToneIcon sx={{ mr: 1.75 }} /> Archive File
                  </MenuItem>
                </Menu> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Typography
                  sx={{
                    fontSize: '2.125rem',
                    fontWeight: 500,
                    mr: 1,
                    mt: 1.75,
                    mb: 0.75,
                  }}
                >
                  <FormattedNumber
                    value={total}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    currency="nok"
                  />
                  {/* <u>{total}</u>,- */}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item sx={{ mb: 1.25 }}>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 500,
                color: theme.palette.secondary[200],
              }}
            >
              Total Assets
            </Typography>
          </Grid> */}
        </Grid>
      </Box>
    </CardWrapper>
  );
}
