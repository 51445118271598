import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import CustomSubAccordion from 'components/CustomSubAccordion';
import StepAvatar from 'components/StepAvatar';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

export default function TaskStep1({ taskManager }) {
  const theme = useTheme();
  const { formatMessage, formatNumber } = useIntl();

  const { expandedMainPanel, handleMainAccordionChange, mainTasks } =
    taskManager;

  const completed = mainTasks.step1;
  const proclamaCost = formatNumber(2.2 * 1277, {
    style: 'currency',
    currency: 'NOK',
  });

  return (
    <Accordion
      expanded={expandedMainPanel === 'step1'}
      onChange={handleMainAccordionChange('step1')}
      sx={{
        backgroundColor: completed ? theme.palette.success.light : 'default',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className="flex items-center gap-2">
          <StepAvatar>1</StepAvatar>
          <Typography variant="h4">
            {/* Boets økonomiske status: Oppnå en helhetlig oversikt / Bekreftelse
            av solvens */}
            <FormattedMessage {...messages.step1title} />
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Box className="px-3 pb-3">
          <Typography>
            {/* Dersom det er usikkerhet om avdøde hadde mer gjeld enn formue, kan
            det være fornuftig å skaffe en grundig oversikt over avdødes
            økonomiske situasjon. */}
            <FormattedMessage {...messages.step1description} />
          </Typography>
        </Box>
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="fortune_poa_email_sent"
          title={formatMessage(messages.fortunePoaTitle)}
          detailText={formatMessage(messages.fortunePoaDescription)}
          isMailModal
          mailTag={'fortune_poa'}
          actionText={formatMessage(messages.fortunePoaButton)}
        />
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="last_year_tax_message"
          title={formatMessage(messages.lastYearTaxTitle)}
          detailText={formatMessage(messages.lastYearTaxDetail)}
          actionLink="https://www.skatteetaten.no/kontakt/skriv/?13887=374923_374913_374911_374874_374872_374970"
          actionText={formatMessage(messages.lastYearTaxButton)}
        />
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="contact_banks"
          title={formatMessage(messages.contactBanksTitle)}
          detailText={formatMessage(messages.contactBanksDetail)}
        />

        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="sent_proclama"
          title={formatMessage(messages.sentProclamaTitle)}
          detailText={formatMessage(messages.sentProclamaDetail, {
            proclamaCost,
          })}
          isMailModal
          mailTag={'proklama'}
          actionText={formatMessage(messages.sentProclamaButton)}
        />
      </AccordionDetails>
    </Accordion>
  );
}
