import { Box, Button, Grid, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import TitleToggleMenu from 'components/TitleToggleMenu';
import { toast } from 'sonner';
import { gridSpacing } from 'store/constant';

export default function Subcriptions(params) {
  const possibleSubscriptions = [
    {
      name: 'Facebook',
      type: 'some',
      description: 'Read more on our website on how to handle some',
    },
    {
      name: 'Instagram',
      type: 'some',
      description: 'Read more on our website on how to handle some',
    },
    {
      name: 'X (twitter)',
      type: 'some',
      description: 'Read more on our website on how to handle some',
    },
  ];

  return (
    <Box className="container mx-auto space-y-5">
      <Box>
        <TitleToggleMenu title={'Subscriptions & accounts'}>
          <Button onClick={() => toast('123')}>Add new</Button>
        </TitleToggleMenu>
        <Grid container spacing={gridSpacing}>
          {/* <Typography variant="h2">Add accounts and subscriptions to remember </Typography> */}
          <Grid item xs={12} md={3}>
            <MainCard>
              <Typography variant="h3">Facebook</Typography>
              <Typography>
                Les på vår nettside hvordan du kan håndtere avdødes facebook:
                link
              </Typography>
            </MainCard>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
