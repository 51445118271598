export default function componentStyleOverrides(theme) {
  const bgColor = theme.colors?.grey50;
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: '24px',
        },
        title: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.colors.darkTextPrimary,
          paddingTop: '10px',
          paddingBottom: '10px',
          borderRadius: `${theme?.customization?.borderRadius}px`,
          '&.Mui-selected': {
            color: theme.colors.menuSelected,
            backgroundColor: theme.colors.menuSelectedBack,
            '&:hover': {
              backgroundColor: theme.colors.menuSelectedBack,

              borderRadius: `${theme?.customization?.borderRadius}px`,
            },
            '& .MuiListItemIcon-root': {
              color: theme.colors.menuSelected,
              borderRadius: `${theme?.customization?.borderRadius}px`,
            },
          },
          '&:hover': {
            backgroundColor: theme.colors.menuSelectedBack,
            color: theme.colors.menuSelected,
            '& .MuiListItemIcon-root': {
              color: theme.colors.menuSelected,
            },
            borderRadius: `${theme?.customization?.borderRadius}px`,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.colors.darkTextPrimary,
          minWidth: '36px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.colors.textDark,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.colors.textDark,
          '&::placeholder': {
            color: theme.colors.darkTextSecondary,
            fontSize: '0.875rem',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: bgColor,
          borderRadius: `${theme?.customization?.borderRadius}px`,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.colors?.grey400,
          },
          '&:hover $notchedOutline': {
            borderColor: theme.colors?.primaryLight,
          },
          '&.MuiInputBase-multiline': {
            padding: 1,
          },
        },
        input: {
          fontWeight: 500,
          background: bgColor,
          padding: '15.5px 14px',
          borderRadius: `${theme?.customization?.borderRadius}px`,
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.colors?.grey300,
          },
        },
        mark: {
          backgroundColor: theme.colors.paper,
          width: '4px',
        },
        valueLabel: {
          color: theme?.colors?.primaryLight,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '.MuiSwitch-thumb': {
            backgroundColor: theme.colors.secondaryLight,
          },
          '.MuiSwitch-track': {
            backgroundColor: theme.colors.primaryLight,
          },
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              '+ .MuiSwitch-track': {
                backgroundColor: theme.colors.primaryDark,
              },
              '.MuiSwitch-thumb': {
                backgroundColor: theme.colors.primaryMain,
              },
            },
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.colors.divider,
          opacity: 1,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryDark,
          background: theme.colors?.primary200,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.colors.paper,
          background: theme.colors?.grey700,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
          border: 'solid 1px',
          borderColor: theme.colors.primaryLight,
          marginBottom: '4px',
          '&:before': {
            display: 'none',
          },
          '&:last-of-type': {
            borderRadius: `${theme?.customization?.borderRadius}px`,
          },
          '&:first-of-type': {
            borderRadius: `${theme?.customization?.borderRadius}px`,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '&:first-of-type': {
            paddingTop: '10px', // Adjust the value and units as needed
          },
        },
      },
    },
  };
}
