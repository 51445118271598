import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from '@mui/material';
import CustomSubAccordion from 'components/CustomSubAccordion';

import StepAvatar from 'components/StepAvatar';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import messages from './messages';

export default function TaskStep5({ taskManager }) {
  const theme = useTheme();

  const { expandedMainPanel, handleMainAccordionChange, mainTasks } =
    taskManager;

  const completed = mainTasks.step5;

  return (
    <Accordion
      expanded={expandedMainPanel === 'step5'}
      onChange={handleMainAccordionChange('step5')}
      sx={{
        backgroundColor: completed ? theme.palette.success.light : 'default',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className="flex items-center gap-2">
          <StepAvatar>5</StepAvatar>
          <Typography variant="h4">
            <FormattedMessage {...messages.step5Title} />
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="trade_union_benefit"
          title={<FormattedMessage {...messages.tradeUnionBenefitTitle} />}
          detailText={
            <FormattedMessage {...messages.tradeUnionBenefitDetail} />
          }
        />
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="nav_benefit"
          title={<FormattedMessage {...messages.navBenefitTitle} />}
          customDetail={
            <>
              <Typography>
                <FormattedMessage
                  {...messages.navBenefitDetail}
                  values={{
                    link: (
                      <Link
                        to={'https://www.nav.no/gjenlevendepensjon#kort-om'}
                        target="_blank"
                      >
                        <FormattedMessage {...messages.navBenefitDetail} />
                      </Link>
                    ),
                  }}
                />
              </Typography>
            </>
          }
        />
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="insurance_benefit"
          title={<FormattedMessage {...messages.insuranceBenefitTitle} />}
          detailText={<FormattedMessage {...messages.insuranceBenefitDetail} />}
        />
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="pension_benefit"
          title={<FormattedMessage {...messages.pensionBenefitTitle} />}
          detailText={<FormattedMessage {...messages.pensionBenefitDetail} />}
          actionLink="https://norskpensjon.no/rolp/foresporsel/etterlatte"
          actionText={<FormattedMessage {...messages.pensionBenefitDetail} />}
        />
      </AccordionDetails>
    </Accordion>
  );
}
