import { Grid } from '@mui/material';
import { useEstateAssets, useEstateClaims } from 'query/estates';
import useStore from 'store';
import TaskList from './TaskList';
import ValueCard from './ValueCard';

import { FormattedMessage } from 'react-intl';
import { gridSpacing } from 'store/constant';
import FilesCard from './FilesCard';
import messages from './messages';

export default function Dashboard(params) {
  const store = useStore();
  const claims = useEstateClaims({ uuid: store.activeEstate });
  const assets = useEstateAssets({ uuid: store.activeEstate });

  // console.log('Claims: ', claims.data);
  // console.log('Assets: ', assets.data);

  const assetValue =
    assets.data?.reduce((acc, curr) => acc + (curr.value || 0), 0) ?? 0;

  const claimsValue =
    claims.data?.reduce((acc, curr) => acc + (curr.value || 0), 0) ?? 0;

  return (
    <Grid container spacing={gridSpacing} id="dashboard">
      <Grid item xs={12} md={8}>
        <Grid container spacing={gridSpacing}>
          <Grid item md={6} xs={12}>
            <ValueCard
              total={assetValue}
              title={<FormattedMessage {...messages.assetsTitle} />}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <ValueCard
              total={claimsValue}
              title={<FormattedMessage {...messages.claimsTitle} />}
            />
          </Grid>
          <Grid item xs={12} id="tasks">
            <TaskList />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid item xs={12}>
          <FilesCard />
        </Grid>
      </Grid>
    </Grid>
  );
}
