import { defineMessages } from 'react-intl';

const messages = defineMessages({
  stakeholdersTitle: {
    id: 'Stakeholders.title',
    defaultMessage: 'Heirs',
  },
  addNewHeir: {
    id: 'Stakeholders.addNewHeir',
    defaultMessage: 'Add new heir',
  },
  doneAddingHeirs: {
    id: 'Stakeholders.doneAddingHeirs',
    defaultMessage: 'Done adding heirs',
  },
  heirsCompleted: {
    id: 'Stakeholders.heirsCompleted',
    defaultMessage: 'Heirs Completed',
  },
  errorOccurred: {
    id: 'Stakeholders.errorOccurred',
    defaultMessage: 'An error occurred',
  },
  stakeholderDeleted: {
    id: 'Stakeholders.stakeholderDeleted',
    defaultMessage: 'Stakeholder {id} deleted',
  },
  deleteStakeholderError: {
    id: 'Stakeholders.deleteStakeholderError',
    defaultMessage: 'Stakeholder {id} could not be deleted',
  },
  missingData: {
    id: 'Stakeholders.missingData',
    defaultMessage: '{key} missing: -{values}',
  },
  optionsTooltip: {
    id: 'StakeholderCard.optionsTooltip',
    defaultMessage: 'Options',
  },
  addChildTooltip: {
    id: 'StakeholderCard.addChildTooltip',
    defaultMessage: 'Add child',
  },
  deceasedLabel: {
    id: 'StakeholderCard.deceasedLabel',
    defaultMessage: 'Deceased',
  },
  grandchildLabel: {
    id: 'StakeholderCard.grandchildLabel',
    defaultMessage: 'Grandchild',
  },
  grandgrandchildLabel: {
    id: 'StakeholderCard.grandgrandchildLabel',
    defaultMessage: 'Great-grandchild',
  },
  dialogTitleCreateStakeholder: {
    id: 'StakeholderForm.dialogTitleCreateStakeholder',
    defaultMessage: 'Create Stakeholder',
  },
  dialogTitleUpdateStakeholder: {
    id: 'StakeholderForm.dialogTitleUpdateStakeholder',
    defaultMessage: 'Update Stakeholder',
  },
  dialogTitleCreateExternalStakeholder: {
    id: 'StakeholderForm.dialogTitleCreateExternalStakeholder',
    defaultMessage: 'Create External Stakeholder',
  },
  dialogTitleUpdateExternalStakeholder: {
    id: 'StakeholderForm.dialogTitleUpdateExternalStakeholder',
    defaultMessage: 'Update External Stakeholder',
  },
  showLess: {
    id: 'StakeholderForm.showLess',
    defaultMessage: 'Show less',
  },
  showMore: {
    id: 'StakeholderForm.showMore',
    defaultMessage: 'Show more',
  },
  name: {
    id: 'StakeholderForm.name',
    defaultMessage: 'Name',
  },
  ssn: {
    id: 'StakeholderForm.ssn',
    defaultMessage: 'Social Security Number',
  },
  type: {
    id: 'StakeholderForm.type',
    defaultMessage: 'Type',
  },
  email: {
    id: 'StakeholderForm.email',
    defaultMessage: 'Email',
  },
  relationshipToDeceased: {
    id: 'StakeholderForm.relationshipToDeceased',
    defaultMessage: 'Relationship to the deceased',
  },
  parent: {
    id: 'StakeholderForm.parent',
    defaultMessage: 'Parent',
  },
  grandparent: {
    id: 'StakeholderForm.grandparent',
    defaultMessage: 'Grandparent',
  },
  child: {
    id: 'StakeholderForm.child',
    defaultMessage: 'Child',
  },
  grandchild: {
    id: 'StakeholderForm.grandchild',
    defaultMessage: 'Grandchild',
  },
  uncleAunt: {
    id: 'StakeholderForm.uncleAunt',
    defaultMessage: 'Uncle/Aunt',
  },
  nephewNiece: {
    id: 'StakeholderForm.nephewNiece',
    defaultMessage: 'Nephew/Niece',
  },
  spouse: {
    id: 'StakeholderForm.spouse',
    defaultMessage: 'Spouse',
  },
  other: {
    id: 'StakeholderForm.other',
    defaultMessage: 'Other',
  },
  guardianName: {
    id: 'StakeholderForm.guardianName',
    defaultMessage: 'Guardian Name',
  },
  guardianEmail: {
    id: 'StakeholderForm.guardianEmail',
    defaultMessage: 'Guardian Email',
  },
  guardianSsn: {
    id: 'StakeholderForm.guardianSsn',
    defaultMessage: 'Guardian SSN',
  },
  estateManagerAuthorization: {
    id: 'StakeholderForm.estateManagerAuthorization',
    defaultMessage: 'Estate Manager has/should have authorization',
  },
  minimumInheritance: {
    id: 'StakeholderForm.minimumInheritance',
    defaultMessage: 'Should have minimum inheritance',
  },
  status: {
    id: 'StakeholderForm.status',
    defaultMessage: 'Status',
  },
  standard: {
    id: 'StakeholderForm.standard',
    defaultMessage: 'Standard',
  },
  deceased: {
    id: 'StakeholderForm.deceased',
    defaultMessage: 'Deceased',
  },
  renunciationOfInheritance: {
    id: 'StakeholderForm.renunciationOfInheritance',
    defaultMessage: 'Renunciation of inheritance',
  },
  minor: {
    id: 'StakeholderForm.minor',
    defaultMessage: 'Minor or legally incapacitated',
  },
  cancel: {
    id: 'StakeholderForm.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'StakeholderForm.save',
    defaultMessage: 'Save',
  },
  companyNumner: {
    id: 'StakeholderForm.companyNumber',
    defaultMessage: 'Company No',
  },
  debtCollector: {
    id: 'StakeholderForm.debtCollector',
    defaultMessage: 'Debt collector',
  },
  taxAuthority: {
    id: 'StakeholderForm.taxAuthority',
    defaultMessage: 'Tax authority',
  },
  government: {
    id: 'StakeholderForm.government',
    defaultMessage: 'Government',
  },
  none: {
    id: 'StakeholderForm.none',
    defaultMessage: 'None',
  },
  hideExternal: {
    id: 'StakeholderTableView.hideExternal',
    defaultMessage: 'Hide external stakeholders',
  },
  showExternal: {
    id: 'StakeholderTableView.showExternal',
    defaultMessage: 'Show external stakeholders',
  },
  emptyText: {
    id: 'StakeholderTableView.emptyText',
    defaultMessage: 'It was empty here, add a new heir',
  },
});

export default messages;
