import { Delete } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useState } from 'react';

const useDeleteDialog = () => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpen = () => setOpenDeleteDialog(true);
  const handleClose = () => setOpenDeleteDialog(false);

  const DeleteDialog = ({ onDelete }) => (
    <Dialog open={openDeleteDialog} onClose={handleClose}>
      {/* <DialogTitle>
        <BatteryAlert sx={{ float: 'center' }} />
      </DialogTitle> */}
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this item?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="error"
          endIcon={<Delete />}
          onClick={() => {
            onDelete();
            handleClose();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  return { handleOpen, handleClose, DeleteDialog };
};

export default useDeleteDialog;
