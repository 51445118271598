import {
  CarRental,
  HomeMini,
  House,
  Inventory,
  SearchOff,
  ShowChart,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

export default function TestPage() {
  return (
    <Grid
      container
      sx={{
        minHeight: '100vh',
        width: '100%',
        lg: { gridTemplateColumns: '280px 1fr' },
      }}
    >
      <Grid
        item
        lg={1}
        sx={{
          display: { xs: 'none', lg: 'block' },
          borderRight: '1px solid',
          borderColor: 'divider',
          bgcolor: 'background.paper',
          p: 2,
          maxHeight: '100vh',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid',
              borderColor: 'divider',
              p: 2,
            }}
          >
            <Link href="#" color="inherit" underline="none">
              <HomeMini sx={{ height: '1.5rem', width: '1.5rem' }} />
              <span>Asset Manager</span>
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
            <Grid container direction="column" gap={2}>
              <Link
                href="#"
                color="inherit"
                underline="none"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  borderRadius: '4px',
                  p: 2,
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'none' },
                }}
              >
                <CarRental sx={{ height: '1.5rem', width: '1.5rem' }} />
                Cars
              </Link>
              <Link
                href="#"
                color="inherit"
                underline="none"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  borderRadius: '4px',
                  p: 2,
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'none' },
                }}
              >
                <House sx={{ height: '1.5rem', width: '1.5rem' }} />
                Real Estate
              </Link>
              <Link
                href="#"
                color="inherit"
                underline="none"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  borderRadius: '4px',
                  p: 2,
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'none' },
                }}
              >
                <ShowChart sx={{ height: '1.5rem', width: '1.5rem' }} />
                Stocks
              </Link>
              <Link
                href="#"
                color="inherit"
                underline="none"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  borderRadius: '4px',
                  p: 2,
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'none' },
                }}
              >
                <Inventory sx={{ height: '1.5rem', width: '1.5rem' }} />
                More Assets
              </Link>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        lg={1}
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid',
            borderColor: 'divider',
            p: 2,
          }}
        >
          <Box
            sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', gap: 2 }}
          >
            <form>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  borderRadius: '4px',
                  bgcolor: 'background.paper',
                }}
              >
                <SearchOff
                  sx={{
                    position: 'absolute',
                    left: '0.625rem',
                    top: '0.625rem',
                    height: '1rem',
                    width: '1rem',
                    color: 'text.secondary',
                  }}
                />
                <TextField
                  sx={{
                    width: '100%',
                    bgcolor: 'white',
                    pl: 3,
                    md: { width: '66.66%' },
                    lg: { width: '33.33%' },
                    dark: { bgcolor: 'gray.950' },
                  }}
                  placeholder="Search assets..."
                  type="search"
                />
              </Box>
            </form>
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 2,
            md: { gap: 4, p: 4 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'semibold' }}>
              Asset Inventory
            </Typography>
            <Button variant="contained" color="primary" size="small">
              Add Asset
            </Button>
          </Box>
          <Box
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: '4px',
              p: 2,
            }}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Asset Type</TableCell>
                    <TableCell>Asset Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <img
                        alt="Asset"
                        className="aspect-square rounded-md object-cover"
                        height="64"
                        src="/placeholder.svg"
                        width="64"
                      />
                    </TableCell>
                    <TableCell>Car</TableCell>
                    <TableCell>BMW M3</TableCell>
                    <TableCell>Owned</TableCell>
                    <TableCell>$70,000</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
