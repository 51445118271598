/*
 * UserLogin Messages
 *
 * This contains all the text for the UserLogin component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.containers.UserLogin.title',
    defaultMessage: 'Log in',
  },
  or: {
    id: 'app.containers.UserLogin.or',
    defaultMessage: 'or',
  },
  noAccount: {
    id: 'app.containers.UserLogin.noAccount',
    defaultMessage: 'No account?',
  },
  register: {
    id: 'app.containers.UserLogin.register',
    defaultMessage: 'Register here',
  },
  reset: {
    id: 'app.containers.UserLogin.reset',
    defaultMessage: 'Reset it',
  },
  resetPasswordSent: {
    id: 'app.containers.UserLogin.resetPasswordSent',
    defaultMessage: 'Password reset instructions are sent to you email',
  },
  forgotPassword: {
    id: 'app.containers.UserLogin.forgotPassword',
    defaultMessage: 'Forgot your password?',
  },
  signIn: {
    id: 'app.containers.UserLogin.signIn',
    defaultMessage: 'Sign in with username/password',
  },
  cancel: {
    id: 'app.containers.UserLogin.cancel',
    defaultMessage: 'Cancel',
  },
  requestPasswordReset: {
    id: 'app.containers.UserLogin.requestPasswordReset',
    defaultMessage: 'Request password reset',
  },
  email: {
    id: 'app.containers.UserLogin.email',
    defaultMessage: 'Email',
  },
  resetPasswordHeading: {
    id: 'app.containers.UserLogin.resetPasswordHeading',
    defaultMessage: 'Enter your email address to request a password reset',
  },
  backToPTPro: {
    id: 'app.containers.UserLogin.backToPTPro',
    defaultMessage: 'Back to PTPro.no',
  },
  noAccountYet: {
    id: 'app.containers.UserLogin.noAccountYet',
    defaultMessage: 'No account yet?',
  },
});
