import { useAuthMutation, useAuthQuery } from '.';

export function useEstateList(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthQuery(
    ['estateList'],
    `estate/?deceased=true&status=b2c`,
    mergedOptions,
  );
}

export function useEstate(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthQuery(['estate'], `estate/${options.uuid}/`, mergedOptions);
}

export function useEstateCreation(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthQuery(
    ['estate'],
    `estate/${options.uuid}/retrieve_b2c_creation`,
    mergedOptions,
  );
}

export function useCreateEstate(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation('estate', mergedOptions);
}

export function useUpdateEstate(options = {}) {
  const mergedOptions = {
    ...options,
    idField: 'uuid',
    method: 'patch',
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation('estate', mergedOptions);
}

export function useUpdateEstateStatus(options = {}) {
  const mergedOptions = {
    ...options,
    // idField: 'uuid',
    method: 'post',
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation(
    `estate/${options.uuid}/set_status_open`,
    mergedOptions,
  );
}

export function useEstateStakeholders(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthQuery(
    ['stakeholders'],
    `estate/${options.uuid}/stakeholders`,
    mergedOptions,
  );
}

export function useEstateAssets(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthQuery(
    ['real_assets'],
    `estate/${options.uuid}/real_assets`,
    mergedOptions,
  );
}

export function useEstateClaims(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthQuery(
    ['claims'],
    `estate/${options.uuid}/claims`,
    mergedOptions,
  );
}

export function useEstatePrivateDivisionFrom(options = {}) {
  const mergedOptions = {
    ...options,
    enabled: false,
    fileDl: true,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },
  };

  return useAuthQuery(
    ['private_division_form_download'],
    `estate/${options.uuid}/fill_private_division_form`,
    mergedOptions,
  );
}

export function useFinalSettlementAgreement(options = {}) {
  const mergedOptions = {
    ...options,
    enabled: false,
    fileDl: true,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },
  };

  return useAuthQuery(
    ['final_settlement_agreement'],
    `estate/${options.uuid}/fill_final_settlement_agreement`,
    mergedOptions,
  );
}

export function useEstateAsset(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthQuery(
    ['real_asset'],
    `real_asset/${options.id}`,
    mergedOptions,
  );
}

export function useUpdateAsset(options = {}) {
  const mergedOptions = {
    ...options,
    idField: 'id',
    method: 'patch',
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation(`real_asset`, mergedOptions);
}

export function useDeleteAsset(options = {}) {
  const mergedOptions = {
    ...options,
    idField: 'id',
    method: 'delete',
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation(`real_asset`, mergedOptions);
}

export function useEstateClaim(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthQuery(['claim'], `claim/${options.id}`, mergedOptions);
}

export function useUpdateClaim(options = {}) {
  const mergedOptions = {
    idField: 'id',
    method: 'patch',
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation(`claim`, mergedOptions);
}

export function useDeleteClaim(options = {}) {
  const mergedOptions = {
    idField: 'id',
    method: 'delete',
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation(`claim`, mergedOptions);
}

export function useUpdateStakeholder(options = {}) {
  const mergedOptions = {
    ...options,
    idField: 'id',
    method: 'patch',
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation(`stakeholder`, mergedOptions);
}

export function useDeleteStakeholder(options = {}) {
  const mergedOptions = {
    ...options,
    idField: 'id',
    method: 'delete',
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation(`stakeholder`, mergedOptions);
}

export function useEstateStakeholder(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthQuery(
    ['stakeholder'],
    `stakeholder/${options.id}`,
    mergedOptions,
  );
}

export function useSendMailStakeholder(options = {}) {
  const mergedOptions = {
    ...options,
    // idField: 'id',
    method: 'post',
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation(`stakeholder/${options.id}/send_mail`, mergedOptions);
}

export function useUpdateHeirDistribution(options = {}) {
  const mergedOptions = {
    ...options,
    idField: 'uuid',
    method: 'post',
    endpointSuffix: 'update_heir_distribution',
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation(`estate`, mergedOptions);
}

export function useGetVehicleData(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthQuery(
    ['vehicleData'],
    `estate/${options.uuid}/get_vehicle_data`,
    mergedOptions,
  );
}

export function useCreatePrivateDivisionSigning(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthQuery(
    ['privateDivisionSigning'],
    `estate/${options.uuid}/create_private_division_signing`,
    mergedOptions,
  );
}

export function useCreateFinalSettlementAgreementSigning(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthQuery(
    ['privateDivisionSigning'],
    `estate/${options.uuid}/create_final_settlement_agreement_signing`,
    mergedOptions,
  );
}

export function useGetPrivateDivisionSigningStatus(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthQuery(
    ['documentSigningStatus'],
    `estate/${options.uuid}/get_document_signing_status`,
    mergedOptions,
  );
}

export function useGetEmailTemplate(options = {}) {
  const mergedOptions = {
    ...options,
    idField: 'uuid',
    method: 'post',
    endpointSuffix: 'get_template_b2c_email',
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },
  };
  return useAuthMutation(`estate`, mergedOptions);
}

export function useEstatePowerOfAttorneyPrivate(options = {}) {
  const mergedOptions = {
    ...options,
    enabled: false,
    fileDl: true,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },
  };

  return useAuthQuery(
    ['fill_power_of_attorney_private_division'],
    `estate/${options.uuid}/fill_power_of_attorney_private_division?stakeholderId=${options.stakeholderId}`,
    mergedOptions,
  );
}

export function useCreatePrivatePowerOfAttorneySigning(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthQuery(
    ['privateDivisionSigning'],
    `estate/${options.uuid}/create_private_poa_signing`,
    mergedOptions,
  );
}

export function useCompleteStakeholders(options = {}) {
  const mergedOptions = {
    ...options,
    idField: 'uuid',
    method: 'post',
    endpointSuffix: 'complete_stakeholders_check',
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },
  };
  return useAuthMutation(`estate`, mergedOptions);
}

export function useDataroomFile(options = {}) {
  const mergedOptions = {
    ...options,
    enabled: false,
    fileDl: true,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },
  };

  return useAuthQuery(
    ['dataroomFile'],
    `dataroom/${options.id}`,
    mergedOptions,
  );
}
