import { Avatar, styled } from '@mui/material';

const StepAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: '#ffffff',
  height: 30,
  width: 30,
  fontSize: 14,
}));

export default StepAvatar;
