import { useEstate } from 'query/estates';
import { useParams } from 'react-router';

export default function ViewEstate() {
  const { uuid } = useParams();
  const estateQuery = useEstate({ uuid: `${uuid}` });

  if (estateQuery.isLoading) {
    return <div>Loading...</div>;
  }
  if (estateQuery.isError) {
    return <div>Error: {estateQuery.error.message}</div>;
  }
  const estate = estateQuery.data;
  console.log(estate);

  return (
    <div>
      <h1>
        {estate.id} {estate.name}
      </h1>
    </div>
  );
}
