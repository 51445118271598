import { defineMessages } from 'react-intl';

export default defineMessages({
  step1title: {
    id: 'app.containers.Dashboard.title',
    defaultMessage:
      "The estate's financial status: Achieving a comprehensive overview / Confirmation of solvency",
  },
  step1description: {
    id: 'app.containers.Dashboard.description',
    defaultMessage:
      "If there is uncertainty about whether the deceased had more debt than assets, it may be wise to obtain a thorough overview of the deceased's financial situation.",
  },
  fortunePoaTitle: {
    id: 'app.containers.Dashboard.fortunePoaTitle',
    defaultMessage:
      'Asset Management Power of Attorney Granted by the District Court',
  },
  fortunePoaDescription: {
    id: 'app.containers.Dashboard.fortunePoaDescription',
    defaultMessage:
      "With an asset management power of attorney, you gain limited access to the deceased's financial situation. This authorization allows you to investigate whether the deceased left behind more debt than assets, which can assist you in deciding whether you want to take over the estate privately.",
  },
  fortunePoaButton: {
    id: 'app.containers.Dashboard.fortunePoaButton',
    defaultMessage: 'Email Template',
  },
  lastYearTaxTitle: {
    id: 'app.containers.Dashboard.lastYearTaxTitle',
    defaultMessage:
      "Request access to last year's tax return from the Norwegian Tax Administration",
  },
  lastYearTaxDetail: {
    id: 'app.containers.Dashboard.lastYearTaxDetail',
    defaultMessage:
      "Once you have received the power of attorney for asset management, you can request the deceased's tax return. You can do this by clicking the button below.",
  },
  lastYearTaxButton: {
    id: 'app.containers.Dashboard.lastYearTaxButton',
    defaultMessage: 'Get tax return',
  },
  contactBanksTitle: {
    id: 'app.containers.Dashboard.contactBanksTitle',
    defaultMessage:
      "Contact the bank(s) for access to the deceased's accounts and balances",
  },
  contactBanksDetail: {
    id: 'app.containers.Dashboard.contactBanksDetail',
    defaultMessage:
      'Determine where the deceased held their accounts. Different banks have various procedures for granting access, but it typically involves providing them with a power of attorney form and a copy of your ID.',
  },
  sentProclamaTitle: {
    id: 'app.containers.Dashboard.sentProclamaTitle',
    defaultMessage:
      'Request the district court to issue a proclamation (optional)',
  },
  sentProclamaDetail: {
    id: 'app.containers.Dashboard.sentProclamaDetail',
    defaultMessage: `Even with access to bank statements, understanding the outstanding debt can still be challenging. Thus, it might be prudent to ask the district court to issue a proclamation. This gives creditors a six-week deadline to report any claims against the deceased. Generally, claims will be forfeited if not reported within this timeframe. This process helps clarify the deceased's total debt responsibility before deciding whether to assume the estate for private settlement. Anyone who takes over an estate for private settlement without requesting a proclamation will, as mentioned, assume an unknown debt responsibility, exposing themselves to considerable risk. Issuing a proclamation costs {proclamaCost}, and it won't be issued until the bill is paid.`,
  },
  sentProclamaButton: {
    id: 'app.containers.Dashboard.sentProclamaButton',
    defaultMessage: 'Proclama Template',
  },
  step2title: {
    id: 'app.containers.Dashboard.step2title',
    defaultMessage: 'Deciding on the Estate Settlement Type',
  },
  step2description: {
    id: 'app.containers.Dashboard.step2description',
    defaultMessage:
      "At this point, you should have sufficient understanding of the deceased's financial situation to choose the type of estate settlement you prefer.",
  },
  privateSettlement: {
    id: 'app.containers.Dashboard.privateSettlement',
    defaultMessage: 'Private Settlement',
  },
  publicSettlement: {
    id: 'app.containers.Dashboard.publicSettlement',
    defaultMessage: 'Public Settlement',
  },
  compositeSettlement: {
    id: 'app.containers.Dashboard.compositeSettlement',
    defaultMessage: 'Composite Settlement',
  },
  step3Title: {
    id: 'app.containers.Dashboard.step3Title',
    defaultMessage: 'Estate Documents',
  },
  addStakeholders: {
    id: 'app.containers.Dashboard.addStakeholders',
    defaultMessage: 'Add All Heirs',
  },
  addAssets: {
    id: 'app.containers.Dashboard.addAssets',
    defaultMessage: 'Add All Assets',
  },
  poaSigning: {
    id: 'app.containers.Dashboard.poaSigning',
    defaultMessage: 'Power of Attorney Signing',
  },
  shiftFormSigning: {
    id: 'app.containers.Dashboard.shiftFormSigning',
    defaultMessage: 'Estate Division Form Signing',
  },
  sendFormsToCourt: {
    id: 'app.containers.Dashboard.sendFormsToCourt',
    defaultMessage: 'Send Forms to the District Court',
  },
  stakeholdersDetail: {
    id: 'app.containers.Dashboard.stakeholdersDetail',
    defaultMessage: 'Add all heirs with accurate information.',
  },
  assetsDetail: {
    id: 'app.containers.Dashboard.assetsDetail',
    defaultMessage: 'For public settlement, you also need to add all assets',
  },
  poaSignedDetail: {
    id: 'app.containers.Dashboard.poaSignedDetail',
    defaultMessage:
      'To manage the estate division, you need authorization from the other heirs. We have prepared power of attorney forms for the following heirs:',
  },
  shiftFormSignedDetail: {
    id: 'app.containers.Dashboard.shiftFormSignedDetail',
    defaultMessage:
      'The estate division form is now ready to be sent for signing.',
  },
  sendFormsToCourtDetail: {
    id: 'app.containers.Dashboard.sendFormsToCourtDetail',
    defaultMessage:
      'Please wait for all signatures to be obtained and then forward the signed documents to the district court.',
  },
  stakeholdersIncompleteDetail: {
    id: 'app.containers.Dashboard.stakeholdersIncompleteDetail',
    defaultMessage: 'Complete stakeholders',
  },
  step4Title: {
    id: 'app.containers.Dashboard.step4Title',
    defaultMessage: "Managing the Deceased's Finances",
  },
  bankAccessTitle: {
    id: 'app.containers.Dashboard.bankAccessTitle',
    defaultMessage: "Access to the Deceased's Bank Accounts",
  },
  bankAccessDetail: {
    id: 'app.containers.Dashboard.bankAccessDetail',
    defaultMessage:
      "Upon receiving the certificate of estate settlement, you can gain access to the deceased's bank accounts. Since each bank may have different procedures, it's recommended to contact the specific banks to understand their processes.",
  },
  customerRelationsTitle: {
    id: 'app.containers.Dashboard.customerRelationsTitle',
    defaultMessage:
      "Managing/Closing the Deceased's Ongoing Obligations and Customer Relations",
  },
  customerRelationsDetail: {
    id: 'app.containers.Dashboard.customerRelationsDetail',
    defaultMessage:
      "Get an overview the deceased's online accounts, which may include social media, cloud storage, emails, and more",
  },
  assetValuationTitle: {
    id: 'app.containers.Dashboard.assetValuationTitle',
    defaultMessage:
      'Obtaining Valuation for High-Value Assets (Properties, Vehicles)',
  },
  assetValuationDetail: {
    id: 'app.containers.Dashboard.assetValuationDetail',
    defaultMessage:
      'Reach out to an appraiser to determine the value and assign it to the corresponding field in assets.',
  },
  step5Title: {
    id: 'app.containers.Dashboard.step5Title',
    defaultMessage: 'Managing Pensions, Benefits, and Insurances',
  },
  tradeUnionBenefitTitle: {
    id: 'app.containers.Dashboard.tradeUnionBenefitTitle',
    defaultMessage: 'Deceased or Own Employer/Trade Union',
  },
  tradeUnionBenefitDetail: {
    id: 'app.containers.Dashboard.tradeUnionBenefitDetail',
    defaultMessage:
      "If the deceased was a member of a trade union, they may have been entitled to benefits such as burial funds, life insurance, or similar arrangements. For detailed information, please consult the respective union's official website. Some trade unions also offer support in the event of death of close relations, so it may be wise to investigate this for yourself and other close ones.",
  },
  navBenefitTitle: {
    id: 'app.containers.Dashboard.navBenefitTitle',
    defaultMessage: 'Applying for Benefits for Survivors from NAV',
  },
  navBenefitDetail: {
    id: 'app.containers.Dashboard.navBenefitDetail',
    defaultMessage:
      'For spouse, partner, or cohabitant with common children: {link}',
  },
  insuranceBenefitTitle: {
    id: 'app.containers.Dashboard.insuranceBenefitTitle',
    defaultMessage: 'Private Services from Bank and Insurance Company',
  },
  insuranceBenefitDetail: {
    id: 'app.containers.Dashboard.insuranceBenefitDetail',
    defaultMessage:
      'Check the available services in case of death from the bank and insurance company.',
  },
  pensionBenefitTitle: {
    id: 'app.containers.Dashboard.pensionBenefitTitle',
    defaultMessage: 'Pension',
  },
  pensionBenefitDetail: {
    id: 'app.containers.Dashboard.pensionBenefitDetail',
    defaultMessage: 'Complete the form for survivors on norskpensjon.no',
  },
  step6Title: {
    id: 'app.containers.Dashboard.step6Title',
    defaultMessage: 'Distributing and Settling the Estate',
  },
  assetsCompletedTitle: {
    id: 'app.containers.Dashboard.assetsCompletedTitle',
    defaultMessage: 'Add All Assets',
  },
  assetsCompletedDetail: {
    id: 'app.containers.Dashboard.assetsCompletedDetail',
    defaultMessage:
      'Complete when all assets to be distributed to heirs are entered.',
  },
  claimsCompletedTitle: {
    id: 'app.containers.Dashboard.claimsCompletedTitle',
    defaultMessage: 'Add All Expenses',
  },
  claimsCompletedDetail: {
    id: 'app.containers.Dashboard.claimsCompletedDetail',
    defaultMessage: 'Complete when all debts and expenses are entered.',
  },
  transferAssetsTitle: {
    id: 'app.containers.Dashboard.transferAssetsTitle',
    defaultMessage: 'Transfer Ownership of Assets or Properties',
  },
  transferAssetsDetail: {
    id: 'app.containers.Dashboard.transferAssetsDetail',
    defaultMessage:
      'Kartverket - Statens Vegvesen - Small Craft Register - etc.',
  },
  step7Title: {
    id: 'app.containers.Dashboard.step7Title',
    defaultMessage: "Handling the Deceased's Tax Settlement",
  },
  taxMessageCompletedTitle: {
    id: 'app.containers.Dashboard.taxMessageCompletedTitle',
    defaultMessage: "Fill out and submit the deceased's tax return",
  },
  taxMessageCompletedDetail: {
    id: 'app.containers.Dashboard.taxMessageCompletedDetail',
    defaultMessage: ' ',
  },
  taxMessageCompletedActionText: {
    id: 'app.containers.Dashboard.taxMessageCompletedActionText',
    defaultMessage: "Take over the deceased's estate tax",
  },
  tasksTitle: {
    id: 'app.containers.Dashboard.tasksTitle',
    defaultMessage: 'Tasks',
  },
  introductoryTasks: {
    id: 'app.containers.Dashboard.introductoryTasks',
    defaultMessage: 'Introductory',
  },
  financialTasks: {
    id: 'app.containers.Dashboard.financialTasks',
    defaultMessage: 'Financial',
  },
  concludingTasks: {
    id: 'app.containers.Dashboard.concludingTasks',
    defaultMessage: 'Concluding',
  },
  confirmationTitle: {
    id: 'app.containers.Dashboard.confirmationTitle',
    defaultMessage: 'Are you sure you want to send this document to signing?',
  },
  cancelButton: {
    id: 'app.containers.Dashboard.cancelButton',
    defaultMessage: 'Cancel',
  },
  sendButton: {
    id: 'app.containers.Dashboard.sendButton',
    defaultMessage: 'Send to signing',
  },
  downloadButton: {
    id: 'app.containers.Dashboard.downloadButton',
    defaultMessage: 'Download',
  },
  previewButton: {
    id: 'app.containers.Dashboard.previewButton',
    defaultMessage: 'Preview',
  },
  assetsTitle: {
    id: 'app.containers.Dashboard.assetsTitle',
    defaultMessage: 'Assets',
  },
  claimsTitle: {
    id: 'app.containers.Dashboard.claimsTitle',
    defaultMessage: 'Claims',
  },
  signedLabel: {
    id: 'app.containers.Dashboard.signedLabel',
    defaultMessage: 'signed',
  },
  pendingLabel: {
    id: 'app.containers.Dashboard.pendingLabel',
    defaultMessage: 'pending',
  },
  reminderTooltip: {
    id: 'app.containers.Dashboard.reminderTooltip',
    defaultMessage: 'Send Reminder',
  },
  reminderSubject: {
    id: 'app.containers.Dashboard.reminderSubject',
    defaultMessage: 'Reminder to sign',
  },
  reminderBody: {
    id: 'app.containers.Dashboard.reminderBody',
    defaultMessage:
      'Hi, you still have a signature pending. Sign here {sign_url}',
  },
  noFiles: {
    id: 'app.containers.Dashboard.noFiles',
    defaultMessage: 'no files yet',
  },
  fullmaktLabel: {
    id: 'app.containers.Dashboard.fullmaktLabel',
    defaultMessage: 'Power of Attorney - {name}',
  },
  privatSkifteLabel: {
    id: 'app.containers.Dashboard.privatSkifteLabel',
    defaultMessage: 'Private Division Form',
  },
  downloadTooltip: {
    id: 'app.containers.Dashboard.downloadTooltip',
    defaultMessage: 'Download',
  },
  pendingTooltip: {
    id: 'app.containers.Dashboard.pendingTooltip',
    defaultMessage: 'Pending',
  },
  step8Title: {
    id: 'app.containers.Dashboard.step8title',
    defaultMessage: 'Final Settlement Agreement',
  },
  step8Description: {
    id: 'app.containers.Dashboard.step8description',
    defaultMessage:
      'A Final Settlement Agreement is a legally binding document that resolves disputes between parties. It offers closure, clarity, and control over the outcome, while avoiding costly litigation. Signing one ensures certainty and confidentiality, making it a smart decision to conclude conflicts efficiently outside of court.',
  },
  signFinalSettlementTitle: {
    id: 'app.containers.Dashboard.signfinalsettlementtitle',
    defaultMessage: 'Sign Final Settlement Agreement',
  },
  signFinalSettlementDetail: {
    id: 'app.containers.Dashboard.signfinalsettlementdetail',
    defaultMessage: 'Send the final settlement agreement to signing',
  },
});
