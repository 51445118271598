import { Mail, TaskAlt } from '@mui/icons-material';
import { Chip, Divider, IconButton, Tooltip } from '@mui/material';
import { useSendMailStakeholder } from 'query/estates';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export default function FileSigner({ sign, divider }) {
  const sendMail = useSendMailStakeholder({ id: sign.id });

  const handleSendMail = () => {
    sendMail.mutate(
      {
        subject: <FormattedMessage {...messages.reminderSubject} />,
        body_plain: (
          <FormattedMessage
            {...messages.reminderBody}
            values={{ sign_url: sign.sign_url }}
          />
        ),
        reply_to_user: false,
      },
      {
        onSuccess: (data) => {
          console.log('Send Mail mutation succeeded. Data:', data);
        },
        onError: (error) => {
          console.log('Error:', error);
        },
      },
    );
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex w-full items-center gap-2">
          <span>{sign.name}</span>
          <Chip
            size="small"
            label={
              sign.signed ? (
                <FormattedMessage {...messages.signedLabel} />
              ) : (
                <FormattedMessage {...messages.pendingLabel} />
              )
            }
          />
        </div>
        <div>
          {sign.signed ? (
            <IconButton>
              <TaskAlt />
            </IconButton>
          ) : (
            <Tooltip
              title={<FormattedMessage {...messages.reminderTooltip} />}
              placement="left"
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleSendMail();
                }}
              >
                <Mail />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {divider && <Divider variant="middle" />}
    </>
  );
}
