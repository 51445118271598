import { Download } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { useDataroomFile } from 'query/estates';

export default function DataroomFileButton({
  id,
  text,
  variant = 'contained',
  iconButton,
}) {
  const fileDownload = useDataroomFile({ id: id });

  const handleFileDownload = async () => {
    try {
      const res = await fileDownload.refetch();
      const contentDisposition = res.data.headers.get('Content-Disposition');
      const filenameMatch =
        contentDisposition && contentDisposition.match(/filename="(.*?)"/);
      const filename = filenameMatch ? filenameMatch[1] : 'your-form.pdf';
      const blob = await res.data.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  if (iconButton)
    return (
      <IconButton onClick={handleFileDownload}>
        <Download />
      </IconButton>
    );

  return (
    <Button variant={variant} onClick={handleFileDownload}>
      {text}
    </Button>
  );
}
