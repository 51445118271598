/**
 *
 * EmailLogin
 *
 */

import { useEffect, useState } from 'react';
// mport PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useLogin, usePasswordReset, useSignicatLoginUrl } from 'query/user';

import messages from './messages';

import LoginForm from 'forms/LoginForm';

import { useNavigate } from 'react-router-dom';
import ArvskifteLogo from '../../assets/images/arvTestLogo.png';

export default function EmailLogin() {
  const intl = useIntl();

  const [loginCleared, setLoginCleared] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [resetPasswordSnackbarOpen, setResetPasswordSnackbarOpen] =
    useState(false);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [open, setOpen] = useState(false);

  const login = useLogin();
  const passwordReset = usePasswordReset();
  const signicatLoginUrl = useSignicatLoginUrl();
  const navigate = useNavigate();

  const theme = useTheme();

  if (!loginCleared) {
    login.reset();
    setLoginCleared(true);
  }

  useEffect(() => {
    setResetPasswordEmail('');
    passwordReset.reset();
  }, [resetPasswordOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogin = (username, password) => {
    login.mutate(
      { email: username, password, redirect: '/' },

      {
        // onSuccess: (data) => navigate('/'),
        onError: (error) => console.log(error),
      },
    );
  };

  const handleSignicatLogin = async (email = null) => {
    const requestData = email !== null ? { email: email } : {};

    signicatLoginUrl.mutate(requestData, {
      onSuccess: (response) => {
        window.location.href = response.redirect_uri;
      },
      onError: (error) => {
        console.log('loginError: ', error);
      },
    });
  };

  const handleChange = (e) => {
    setNewUserEmail(e.value);
    console.log(newUserEmail);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box
      sx={
        {
          // backgroundColor: theme.palette.darkGrey.main,
          // minHeight: '100vh',
        }
      }
    >
      <Helmet>
        <title>{intl.formatMessage(messages.title)} | Arvskifte</title>
        <meta name="description" content="User login" />
      </Helmet>
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        sx={{
          minHeight: '100vh',
        }}
      >
        <Grid item xs={12} md={6}>
          <Grid
            container
            justifyContent="center"
            spacing={2}
            alignItems="center"
            sx={{ height: '100%' }}
          >
            <Grid item xs={10} sm={8} md={10}>
              <Grid item xs={10}>
                {/* <Button
                  variant="contained"
                  onClick={() => handleSignicatLogin()}
                >
                  Signicat Login
                </Button>

                <Button variant="contained" onClick={handleClickOpen}>
                  New user
                </Button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                      event.preventDefault();
                      const formData = new FormData(event.currentTarget);
                      const formJson = Object.fromEntries(formData.entries());
                      const email = formJson.email;
                      console.log(email);
                      handleSignicatLogin(email);
                      handleClose();
                    },
                  }}
                >
                  <DialogTitle>New User</DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      required
                      margin="dense"
                      id="name"
                      name="email"
                      label="Email Address"
                      type="email"
                      fullWidth
                      variant="standard"
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Submit</Button>
                  </DialogActions>
                </Dialog> */}
              </Grid>
              <Grid container spacing={2}>
                <Grid container justifyContent="center" item xs={12}>
                  {/* <ArvskifteLogoText className={classes.logo} /> */}
                </Grid>

                <Hidden mdUp>
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <img
                      alt="arvskifte-logo"
                      src={ArvskifteLogo}
                      style={{
                        width: '300px',
                        height: 'auto',
                        maxWidth: '80%',
                        marginTop: theme.spacing(1),
                        marginBottom: theme.spacing(4),
                      }}
                    />
                  </Grid>

                  <Card elevation={24}>
                    <CardContent>
                      <Grid item xs={12}>
                        <LoginForm
                          onSubmit={handleLogin}
                          currentlySending={login.isLoading}
                          error={login.error}
                        />
                      </Grid>

                      {/* <Grid item xs={12} align="center">
                        <Typography>
                          <FormattedMessage {...messages.forgotPassword} />
                          <Button
                            color="primary"
                            onClick={() => setResetPasswordOpen(true)}
                          >
                            <FormattedMessage {...messages.reset} />
                          </Button>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} align="center">
                        <Typography>
                          <FormattedMessage {...messages.noAccountYet} />
                          <Button
                            color="primary"
                            component={Link}
                            to="/register"
                          >
                            <FormattedMessage {...messages.register} />
                          </Button>
                        </Typography>
                      </Grid> */}

                      <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Typography>
                          <FormattedMessage {...messages.or} />
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Hidden>
                <Hidden smDown>
                  <Grid item xs={12}>
                    <LoginForm
                      onSubmit={handleLogin}
                      currentlySending={login.isLoading}
                      error={login.error}
                    />
                  </Grid>

                  {/* <Grid item xs={12} align="center">
                    <Typography>
                      <FormattedMessage {...messages.forgotPassword} />
                      <Button
                        color="primary"
                        onClick={() => setResetPasswordOpen(true)}
                      >
                        <FormattedMessage {...messages.reset} />
                      </Button>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} align="center">
                    <Typography>
                      <FormattedMessage {...messages.noAccountYet} />
                      <Button color="primary" component={Link} to="/register">
                        <FormattedMessage {...messages.register} />
                      </Button>
                    </Typography>
                  </Grid> */}

                  {/* <Grid item xs={12} align="center">
                    <LocaleToggle />
                  </Grid> */}
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              justifyContent="center"
              spacing={2}
              alignItems="center"
              sx={{ height: '100%' }}
            >
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <img
                  alt="Arvskifte-logo"
                  src={ArvskifteLogo}
                  style={{
                    width: '300px',
                    height: 'auto',
                    maxWidth: '80%',
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
      <Dialog open={resetPasswordOpen} maxWidth="xs" fullWidth>
        <DialogContent style={{ padding: theme.spacing(2) }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography align="center" variant="h6">
                <FormattedMessage {...messages.resetPasswordHeading} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                type="email"
                label={<FormattedMessage {...messages.email} />}
                value={resetPasswordEmail}
                onChange={(e) => setResetPasswordEmail(e.target.value)}
                error={Boolean(passwordReset.error?.email)}
                helperText={
                  passwordReset.error &&
                  passwordReset.error.email &&
                  passwordReset.error.email
                }
              />
            </Grid>
            <Grid item xs={12} container spacing={1} justifyContent="center">
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => setResetPasswordOpen(false)}
                >
                  <FormattedMessage {...messages.cancel} />
                </Button>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  passwordReset.mutate(resetPasswordEmail, {
                    onSuccess: () => {
                      setResetPasswordSnackbarOpen(true);
                      setResetPasswordOpen(false);
                    },
                  })
                }
                disabled={resetPasswordEmail === '' || passwordReset.isLoading}
              >
                {passwordReset.isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <FormattedMessage {...messages.requestPasswordReset} />
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={resetPasswordSnackbarOpen}
        onClose={() => setResetPasswordSnackbarOpen(false)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <span id="message-id">
            <FormattedMessage {...messages.resetPasswordSent} />
          </span>
        }
        TransitionComponent={Fade}
        autoHideDuration={3000}
      />
    </Box>
  );
}
