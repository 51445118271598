import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from '@mui/material';
import CustomSubAccordion from 'components/CustomSubAccordion';
import StepAvatar from 'components/StepAvatar';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export default function TaskStep7({ taskManager }) {
  const theme = useTheme();

  const { expandedMainPanel, handleMainAccordionChange, mainTasks } =
    taskManager;

  const completed = mainTasks.step7;

  return (
    <Accordion
      expanded={expandedMainPanel === 'step7'}
      onChange={handleMainAccordionChange('step7')}
      sx={{
        backgroundColor: completed ? theme.palette.success.light : 'default',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel7a-content"
        id="panel7a-header"
      >
        <div className="flex items-center gap-2">
          <StepAvatar>7</StepAvatar>
          <Typography variant="h4">
            <FormattedMessage {...messages.step7Title} />
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="tax_message_completed"
          title={<FormattedMessage {...messages.taxMessageCompletedTitle} />}
          detailText={
            <FormattedMessage {...messages.taxMessageCompletedDetail} />
          }
          actionLink="https://www.skatteetaten.no/person/folkeregister/dodsfall/overta-avdodes-bo/"
          actionText={
            <FormattedMessage {...messages.taxMessageCompletedActionText} />
          }
        />
      </AccordionDetails>
    </Accordion>
  );
}
