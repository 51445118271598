import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material';
import ValueCard from 'containers/Dashboard/ValueCard';
import { useAuthGraphQuery } from 'query';
import {
  useEstate,
  useUpdateEstate,
  useUpdateHeirDistribution,
} from 'query/estates';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useStore from 'store';

import { gridSpacing } from 'store/constant';
import messages from './messages';

const GET_ESTATE_HEIR_DISTRIBUTION = `
  query GetEstateHeirDistribution($uuid: String!) {
    estate(uuid: $uuid) {
      get_heir_distribution
    }
  }
`;

export default function EstateSummary(params) {
  const activeEstate = useStore((state) => state.activeEstate);
  const estate = useEstate({ uuid: activeEstate });
  const updateEstate = useUpdateEstate({ uuid: activeEstate });
  const updateHeirDistribution = useUpdateHeirDistribution({
    uuid: activeEstate,
  });
  const theme = useTheme();
  const intl = useIntl();

  const getEstateHeirDistribution = useAuthGraphQuery(
    ['getEstateHeirDistribution', activeEstate],
    GET_ESTATE_HEIR_DISTRIBUTION,
    { uuid: activeEstate },
  );

  const [heirDistributionData, setHeirDistributionData] = useState(null);
  const [rows, setRows] = useState({});
  const [toggleSwitch, setToggleSwitch] = useState(false);

  useEffect(() => {
    if (estate.data) {
      setToggleSwitch(
        estate.data.deceased_has_will && estate.data.heirs_inherit_minimum,
      );
    }
  }, [estate.data]);

  const handleUpdateEstate = () => {
    updateEstate.mutate(
      {
        uuid: activeEstate,
        // deceased_has_children: true,
        deceased_has_will: !estate.data?.deceased_has_will,
        heirs_inherit_minimum: !estate.data?.heirs_inherit_minimum,
        // deceased_has_parents: false,
        // deceased_has_grandparents: false,
      },
      {
        onSuccess: (data) => {
          console.log('Success', data);
          getEstateHeirDistribution.refetch();
        },
        onError: (error) => {
          console.log('error', error);
        },
      },
    );
  };

  const handleUpdateFraction = () => {
    updateHeirDistribution.mutate(
      { uuid: activeEstate },
      {
        onSuccess: (data) => {
          getEstateHeirDistribution.refetch();
          // console.log('Success', data);
        },
        onError: (error) => {
          console.log('error', error);
        },
      },
    );
  };

  useEffect(() => {
    if (
      getEstateHeirDistribution.data &&
      getEstateHeirDistribution.data.estate &&
      getEstateHeirDistribution.data.estate.get_heir_distribution
    ) {
      try {
        const parsedData = JSON.parse(
          getEstateHeirDistribution.data.estate.get_heir_distribution,
        );
        console.log('Parsed Data: ', parsedData);
        setHeirDistributionData(parsedData);
        setRows(
          Object.keys(parsedData.heirs).map((key) => parsedData.heirs[key]),
        );
      } catch (e) {
        console.error('Error parsing heir distribution data:', e);
      }
    }
  }, [getEstateHeirDistribution.data]);

  console.log(rows);

  const handleToggle = () => {
    setToggleSwitch(!toggleSwitch);
    handleUpdateEstate();
    handleUpdateFraction();
    getEstateHeirDistribution.refetch();
  };

  return (
    <Box className="container mx-auto space-y-5">
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={4}>
          <ValueCard
            total={Number(heirDistributionData?.distributable_inventory)}
            title={intl.formatMessage(messages.totalInventory)}
            color={theme.palette.primary.main}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ValueCard
            total={Number(heirDistributionData?.total_distributed)}
            title={intl.formatMessage(messages.totalDistributed)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ValueCard
            total={Number(
              heirDistributionData?.initial_remaining_distributable_inventory,
            )}
            title={intl.formatMessage(messages.remainingDistributable)}
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{intl.formatMessage(messages.nameLabel)}</TableCell>
              <TableCell>{intl.formatMessage(messages.typeLabel)}</TableCell>
              <TableCell>
                {intl.formatMessage(messages.inheritanceLabel)}
              </TableCell>
              <TableCell>{intl.formatMessage(messages.assetLabel)}</TableCell>
              <TableCell>{intl.formatMessage(messages.outlaysLabel)}</TableCell>
              <TableCell>
                {intl.formatMessage(messages.totalCashValueLabel)}
              </TableCell>
            </TableRow>
          </TableHead>
          {rows.length > 0 ? (
            <TableBody>
              {rows
                .filter(
                  (r) =>
                    r.total_value > 0 ||
                    r.total_asset_value > 0 ||
                    r.total_outlay_value > 0,
                )
                .map((r) => (
                  <TableRow key={r.name}>
                    <TableCell component="th" scope="row">
                      {r.name}
                    </TableCell>
                    <TableCell>{r.type}</TableCell>
                    <TableCell>{r.total_value.toLocaleString()},-</TableCell>
                    <TableCell>
                      {r.assets?.map((a) => (
                        <Tooltip
                          arrow
                          placement="right"
                          title={
                            intl.formatMessage(messages.valueTooltip) +
                            a.value.toLocaleString() +
                            ',-'
                          }
                          key={a.id}
                        >
                          <Chip label={a.title} />
                        </Tooltip>
                      ))}
                    </TableCell>
                    <TableCell>
                      {r.outlays?.map((o) => (
                        <Tooltip
                          arrow
                          placement="right"
                          title={
                            intl.formatMessage(messages.valueTooltip) +
                            o.value.toLocaleString() +
                            ',-'
                          }
                          key={o.id}
                        >
                          <Chip label={o.title} />
                        </Tooltip>
                      ))}
                    </TableCell>
                    <TableCell>
                      {r.total_cash_value.toLocaleString()},-
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={3}>
                  {intl.formatMessage(messages.loadingMessage)}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {/* <FormControlLabel
        control={
          <Switch
            value="toggle"
            checked={toggleSwitch}
            onChange={handleToggle}
          />
        }
        label={intl.formatMessage(messages.inheritMinimumLabel)}
      /> */}
      <Button variant="contained" onClick={handleUpdateFraction}>
        {intl.formatMessage(messages.updateFractionButton)}
      </Button>
    </Box>
  );
}
