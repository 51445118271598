import { CheckCircleOutline, WarningAmber } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import MainCard from 'components/MainCard';
import { useSignicatLogin } from 'query/user';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { gridSpacing } from 'store/constant';

export default function Success(params) {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('sessionId');
  const { mutate } = useSignicatLogin({});
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [noUser, setNoUser] = useState(false);

  useEffect(() => {
    setErrorMessage();
    setSuccessMessage();
    mutate(
      { session_id: sessionId },
      {
        // onSuccess: (data) => {
        //   console.log('Success:', data);
        //   if (data.new_user) {
        //     navigate('/createprofile');
        //   } else {
        //     setSuccessMessage('Success!');
        //     navigate('/');
        //   }
        // },
        onError: (error) => {
          if (error.status === 'NOUSER') {
            setErrorMessage('No user found!');
            setNoUser(true);
          } else {
            console.log('Error:', error);
            setErrorMessage(error.detail);
          }
        },
      },
    );
  }, []);

  const iconSize = 70;
  return (
    <Grid
      container
      justifyContent={'center'}
      alignItems={'center'}
      spacing={gridSpacing}
      sx={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <MainCard sx={{ minWidth: '40%' }}>
        <Box className="flex flex-col items-center justify-start">
          {errorMessage ? (
            <WarningAmber
              color="warning"
              sx={{ width: iconSize, height: iconSize }}
            />
          ) : successMessage ? (
            <CheckCircleOutline
              color="success"
              sx={{ width: iconSize, height: iconSize }}
            />
          ) : (
            <CircularProgress size={iconSize} />
          )}
          {errorMessage && (
            <>
              <p>{errorMessage}</p>
              <Button variant="contained" onClick={() => navigate('/')}>
                Back
              </Button>
            </>
          )}
          {successMessage && <p>{successMessage}</p>}

          {/* <p>Bare en statusmelding</p> */}
          {/* </div> */}
          {/* <div>
            <Button
              onClick={() => {
                setErrorMessage('Bare en error Status');
                setSuccessMessage();
              }}
            >
              Error
            </Button>
            <Button
              onClick={() => {
                setErrorMessage();
                setSuccessMessage('Success Melding status');
              }}
            >
              Success
            </Button>
            <Button
              onClick={() => {
                setErrorMessage();
                setSuccessMessage();
              }}
            >
              Load
            </Button>
          </div> */}
        </Box>
      </MainCard>
    </Grid>
  );
}
