import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useCreatePrivatePowerOfAttorneySigning } from 'query/estates';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useStore from 'store';
import messages from './messages';

export default function SignPrivatePOA({ disabled = false }) {
  const store = useStore();

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);

  const signingData = useCreatePrivatePowerOfAttorneySigning({
    uuid: store.activeEstate,
    enabled: false,
  });

  const getSigning = async () => {
    signingData.refetch();
    console.log(await signingData.data);
  };

  return (
    <>
      <Button onClick={handleOpen} variant="contained" disabled={disabled}>
        <FormattedMessage {...messages.sendButton} />
      </Button>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h4">
            <FormattedMessage {...messages.confirmationTitle} />
          </Typography>
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              getSigning();
              handleClose();
            }}
          >
            <FormattedMessage {...messages.sendButton} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
