import { GridView, TableRows } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import TitleToggleMenu from 'components/TitleToggleMenu';
import {
  useDeleteClaim,
  useEstate,
  useEstateClaims,
  useUpdateEstate,
} from 'query/estates';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';
import useStore from 'store';
import ClaimForm from './ClaimForm';
import ClaimsGridView from './ClaimsGridView';
import ClaimsTableView from './ClaimsTableView';
import messages from './messages';

export default function Claims(params) {
  const intl = useIntl();
  const activeEstate = useStore((state) => state.activeEstate);
  const claimView = useStore((state) => state.claimView);
  const toggleClaimView = useStore((state) => state.toggleClaimView);
  const estate = useEstate({ uuid: activeEstate });
  const claims = useEstateClaims({ uuid: activeEstate });
  const update = useUpdateEstate();
  const deleteClaim = useDeleteClaim();

  const [createFormOpen, setCreateFormOpen] = useState(false);
  const [editingClaimId, setEditingClaimId] = useState(null);
  const [claimsComplete, setClaimsComplete] = useState(false);
  const [claimsData, setClaimsData] = useState([]);

  useEffect(() => {
    let claimsComp = estate.data?.claims_complete;
    setClaimsComplete(claimsComp);
    console.log(claimsComp);
  }, [estate.data?.claims_complete]);

  useEffect(() => {
    const newClaimsData = claims.data;
    setClaimsData(newClaimsData);
    console.log(claimsData);
  }, [claims.data]);

  const handleEditClaim = (claimId) => {
    setEditingClaimId(claimId);
  };

  const handleCreateOpenForm = () => setCreateFormOpen(true);
  const handleCreateCloseForm = () => setCreateFormOpen(false);

  const claimComplete = async () => {
    update.mutate(
      { uuid: activeEstate, claims_complete: true },
      {
        onSuccess: (data) => {
          console.log('Mutation succeeded. Data:', data);
          toast.success(intl.formatMessage(messages.claimsCompleted));
          estate.refetch();
        },
        onError: (error) => {
          console.log('Error:', error);
        },
      },
    );
  };

  const handleDelete = (claimId) => {
    deleteClaim.mutate(
      { id: claimId },
      {
        onSuccess: (data) => {
          console.log('Claim ', claimId, ' deleted');
          claims.refetch();
          toast.warning(intl.formatMessage(messages.claimDeleted));
        },
        onError: (error) => {
          console.log('Could not delete claim! ', error);
          toast.error(intl.formatMessage(messages.couldNotDeleteClaim));
        },
      },
    );
  };

  return (
    <Box className="container mx-auto space-y-5">
      <Box>
        <TitleToggleMenu
          title={intl.formatMessage(messages.claimsTitle)}
          iconLeft={<GridView />}
          iconRight={<TableRows />}
          switchState={claimView}
          toggleSwitch={toggleClaimView}
        >
          {!claimsComplete && (
            <>
              <Button variant="contained" onClick={handleCreateOpenForm}>
                {intl.formatMessage(messages.addNewClaim)}
              </Button>
              <Button onClick={claimComplete}>
                {intl.formatMessage(messages.doneAddingClaims)}
              </Button>
            </>
          )}
        </TitleToggleMenu>
        <ClaimForm
          mode={'create'}
          open={createFormOpen}
          onClose={() => handleCreateCloseForm()}
          refetch={() => claims.refetch()}
        />
        {editingClaimId && (
          <ClaimForm
            mode={'update'}
            claimId={editingClaimId}
            open={Boolean(editingClaimId)}
            onClose={() => setEditingClaimId(null)}
            refetch={() => claims.refetch()}
          />
        )}
        {!claims.isLoading &&
          (claimView ? (
            <ClaimsTableView
              claimsData={claims.data}
              handleEditClaim={handleEditClaim}
              handleDelete={handleDelete}
            />
          ) : (
            <ClaimsGridView
              claimsData={claims.data}
              handleEditClaim={handleEditClaim}
              handleDelete={handleDelete}
            />
          ))}
      </Box>
    </Box>
  );
}
