import { Check } from '@mui/icons-material';
import { ToggleButton, useTheme } from '@mui/material';
import {
  useEstate,
  useEstateStakeholders,
  useUpdateEstate,
} from 'query/estates';
import { useEffect, useState } from 'react';
import useStore from 'store';

const useTaskManager = () => {
  const theme = useTheme();
  const store = useStore();
  const update = useUpdateEstate();
  const estate = useEstate({ uuid: store.activeEstate });
  const stakeholders = useEstateStakeholders({ uuid: store.activeEstate });
  const [tasks, setTasks] = useState({});
  const [expandedMainPanel, setExpandedMainPanel] = useState(false);
  const [expandedSubPanel, setExpandedSubPanel] = useState(false);
  const [mainTasks, setMainTasks] = useState({});
  const [stakeholdersList, setStakeholdersList] = useState([]);
  const [stakeholdersWithPoa, setStakeholdersWithPoa] = useState([]);

  useEffect(() => {
    if (estate.data?.b2c_estate_questions) {
      setTasks(estate.data.b2c_estate_questions);
    }
  }, [estate.data?.b2c_estate_questions]);

  useEffect(() => {
    if (estate.data?.stakeholders_complete) {
      setTasks((tasks) => ({
        ...tasks,
        stakeholders_complete: estate.data.stakeholders_complete,
      }));
    }
  }, [estate.data?.stakeholders_complete]);

  useEffect(() => {
    if (stakeholders.isSuccess) {
      const newStakeholdersList = stakeholders.data;
      setStakeholdersList(newStakeholdersList);

      const poaList = newStakeholdersList
        .filter((stakeholder) => stakeholder.estate_manager_poa)
        .map((stakeholder) => ({ name: stakeholder.name, id: stakeholder.id }));

      setStakeholdersWithPoa(poaList);
    } else {
      setStakeholdersList([]);
      setStakeholdersWithPoa([]);
    }
  }, [stakeholders.data, stakeholders.isSuccess]);

  // useEffect(() => {
  //   const subTasks = [
  //     {
  //       key: 'fortune_poa_email_sent',
  //       condition: () => tasks.fortune_poa_email_sent,
  //     },
  //     {
  //       key: 'last_year_tax_message',
  //       condition: () => tasks.last_year_tax_message,
  //     },
  //     {
  //       key: 'contact_banks',
  //       condition: () => tasks.contact_banks,
  //     },
  //     {
  //       key: 'sent_proclama',
  //       condition: () => tasks.sent_proclama,
  //     },
  //     {
  //       key: 'shift_type',
  //       condition: () => tasks.shift_type && tasks.shift_type !== 'none',
  //     },
  //     {
  //       key: 'stakeholders_complete',
  //       condition: () => estate.data.stakeholders_complete,
  //     },
  //     {
  //       key: 'poa_signed',
  //       condition: () => tasks.poa_signed,
  //     },
  //     {
  //       key: 'shift_form_signed',
  //       condition: () => tasks.shift_form_signed,
  //     },
  //     {
  //       key: 'send_forms_to_court',
  //       condition: () => tasks.send_forms_to_court,
  //     },
  //     {
  //       key: 'get_bank_access',
  //       condition: () => tasks.get_bank_access,
  //     },
  //     {
  //       key: 'handle_customer_relations',
  //       condition: () => tasks.handle_customer_relations,
  //     },
  //     {
  //       key: 'asset_valuation',
  //       condition: () => tasks.asset_valuation,
  //     },
  //     {
  //       key: 'trade_union_benefit',
  //       condition: () => tasks.trade_union_benefit,
  //     },
  //     {
  //       key: 'nav_benefit',
  //       condition: () => tasks.nav_benefit,
  //     },
  //     {
  //       key: 'insurance_benefit',
  //       condition: () => tasks.insurance_benefit,
  //     },
  //     {
  //       key: 'pension_benefit',
  //       condition: () => tasks.pension_benefit,
  //     },
  //     {
  //       key: 'assets_complete',
  //       condition: () => estate.data.real_assets_complete,
  //     },
  //     {
  //       key: 'claims_complete',
  //       condition: () => estate.data.claims_complete,
  //     },
  //     {
  //       key: 'transfer_ownership',
  //       condition: () => tasks.transfer_ownership,
  //     },
  //     {
  //       key: 'handle_tax_message',
  //       condition: () => tasks.handle_tax_message,
  //     },
  //     {
  //       key: 'final_settlement_agreement',
  //       condition: () => tasks.final_settlement_agreement,
  //     },
  //   ];

  //   let nextSubAccordionToExpand = '';
  //   for (let i = 0; i < subTasks.length; i++) {
  //     if (subTasks[i].condition()) {
  //       continue;
  //     } else {
  //       nextSubAccordionToExpand = subTasks[i].key;
  //       break;
  //     }
  //   }

  //   setExpandedSubPanel(nextSubAccordionToExpand);
  // }, [tasks]);

  useEffect(() => {
    const orderedTasks = [
      {
        key: 'step1',
        condition: () =>
          tasks.fortune_poa_email_sent &&
          tasks.last_year_tax_message &&
          tasks.contact_banks &&
          tasks.sent_proclama,
      },
      {
        key: 'step2',
        condition: () => tasks.shift_type !== 'none' || !tasks.shift_type,
      },
      {
        key: 'step3',
        condition: () =>
          estate.data.stakeholders_complete &&
          tasks.poa_signed &&
          tasks.shift_form_signed &&
          tasks.sendt_forms_to_court,
      },
      {
        key: 'step4',
        condition: () =>
          tasks.handle_customer_relations &&
          tasks.asset_valuation &&
          tasks.get_bank_access,
      },
      {
        key: 'step5',
        condition: () =>
          tasks.trade_union_benefit &&
          tasks.nav_benefit &&
          tasks.insurance_benefit &&
          tasks.pension_benefit,
      },
      {
        key: 'step6',
        condition: () =>
          tasks.assets_completed &&
          tasks.claims_completed &&
          tasks.transfer_assets,
      },
      {
        key: 'step7',
        condition: () => tasks.tax_message_completed,
      },
      {
        key: 'step8',
        condition: () => tasks.final_settlement_agreement,
      },
    ];

    const mainTasks = {};

    let nextAccordionToExpand = 0;
    for (let i = 0; i < orderedTasks.length; i++) {
      const isTaskDone = orderedTasks[i].condition();
      mainTasks[orderedTasks[i].key] = isTaskDone;

      if (isTaskDone) {
        nextAccordionToExpand = i + 1;
      } else {
        break;
      }
    }

    if (nextAccordionToExpand >= orderedTasks.length) {
      nextAccordionToExpand = orderedTasks.length - 1;
    }

    // setExpandedMainPanel(`step${nextAccordionToExpand + 1}`);

    setMainTasks(mainTasks);
  }, [tasks]);

  const handleMainAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedMainPanel(isExpanded ? panel : false);
  };

  const handleSubAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedSubPanel(isExpanded ? panel : false);
  };

  const handleTaskStatus = async (
    taskKey,
    value = undefined,
    event = undefined,
  ) => {
    if (event !== undefined) {
      event.stopPropagation();
    }
    const newValue = value !== undefined ? value : !tasks[taskKey];

    setTasks((prevTasks) => ({
      ...prevTasks,
      [taskKey]: newValue,
    }));

    try {
      const updatedTasks = await update.mutateAsync({
        uuid: store.activeEstate,
        b2c_estate_questions: {
          ...tasks,
          [taskKey]: newValue,
        },
      });

      setTasks(updatedTasks.b2c_estate_questions);
    } catch (error) {
      console.error('Error updating task status:', error);
      setTasks((prevTasks) => ({
        ...prevTasks,
        [taskKey]: !newValue,
      }));
    }
  };

  const getStatusToggleButton = (taskKey) => {
    const CustomToggleButton = ({ children }) => (
      <ToggleButton
        value="check"
        sx={(theme) => ({
          borderRadius: 23,
          height: 20,
          width: 20,
          marginRight: 1,
          color: tasks[taskKey] ? theme.palette.success.light : 'default',
        })}
        selected={tasks[taskKey]}
        onChange={(event) => handleTaskStatus(taskKey, undefined, event)}
      >
        {children}
      </ToggleButton>
    );
    return ({ value }) => (
      <CustomToggleButton>{tasks[taskKey] && <Check />}</CustomToggleButton>
    );
  };

  return {
    tasks,
    expandedMainPanel,
    expandedSubPanel,
    handleMainAccordionChange,
    handleSubAccordionChange,
    handleTaskStatus,
    getStatusToggleButton,
    mainTasks,
    stakeholdersWithPoa,
  };
};

export default useTaskManager;
