import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from '@mui/material';
import CustomSubAccordion from 'components/CustomSubAccordion';
import StepAvatar from 'components/StepAvatar';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export default function TaskStep6({ taskManager }) {
  const theme = useTheme();
  const { expandedMainPanel, handleMainAccordionChange, mainTasks } =
    taskManager;

  const completed = mainTasks.step6;

  return (
    <Accordion
      expanded={expandedMainPanel === 'step6'}
      onChange={handleMainAccordionChange('step6')}
      sx={{
        backgroundColor: completed ? theme.palette.success.light : 'default',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className="flex items-center gap-2">
          <StepAvatar>6</StepAvatar>
          <Typography variant="h4">
            <FormattedMessage {...messages.step6Title} />
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="assets_completed"
          title={<FormattedMessage {...messages.assetsCompletedTitle} />}
          detailText={<FormattedMessage {...messages.assetsCompletedDetail} />}
        />
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="claims_completed"
          title={<FormattedMessage {...messages.claimsCompletedTitle} />}
          detailText={<FormattedMessage {...messages.claimsCompletedDetail} />}
        />
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="transfer_assets"
          title={<FormattedMessage {...messages.transferAssetsTitle} />}
          detailText={<FormattedMessage {...messages.transferAssetsDetail} />}
        />
      </AccordionDetails>
    </Accordion>
  );
}
