import { useEffect, useState } from 'react';

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface EstateGlobalState {}
interface AppState {
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
  token: string | null;
  setToken: (token: string | null) => void;
  locale: 'no' | 'en';
  setLocale: (locale: 'no' | 'en') => void;
  estateGlobalState: EstateGlobalState;
  setEstateGlobalState: (estateGlobalState: EstateGlobalState) => void;
  activeEstate: string | null;
  setActiveEstate: (activeEstate: string | null) => void;
  assetView: boolean;
  toggleAssetView: () => void;
  claimView: boolean;
  toggleClaimView: () => void;
  stakeholderView: boolean;
  toggleStakeholderView: () => void;
  tutorialDone: boolean;
  darkMode: boolean;
  toggleDarkMode: () => void;
}

const useStore = create<AppState>()(
  persist(
    (set) => ({
      loggedIn: false,
      setLoggedIn: (loggedIn: boolean) => set({ loggedIn }),
      token: null,
      setToken: (token: string | null) => set({ token }),
      locale: 'no',
      setLocale: (locale: 'no' | 'en') => set({ locale }),
      estateGlobalState: {},
      setEstateGlobalState: (estateGlobalState: EstateGlobalState) =>
        set({ estateGlobalState }),
      assetView: false,
      toggleAssetView: () => set((state) => ({ assetView: !state.assetView })),
      claimView: false,
      toggleClaimView: () => set((state) => ({ claimView: !state.claimView })),
      stakeholderView: false,
      toggleStakeholderView: () =>
        set((state) => ({ stakeholderView: !state.stakeholderView })),
      activeEstate: null,
      setActiveEstate: (activeEstate: string | null) => set({ activeEstate }),
      tutorialDone: false,
      setTutorialDone: (tutorialDone: boolean) => set({ tutorialDone }),
      darkMode: false,
      toggleDarkMode: () => set((state) => ({ darkMode: !state.darkMode })),
    }),
    {
      name: 'state-storage', // unique name
    },
  ),
);

const useStoreIsHydrated = () => {
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    const unsubFinishHydration = useStore.persist.onFinishHydration(() =>
      setHydrated(true),
    );

    setHydrated(useStore.persist.hasHydrated());

    return () => {
      unsubFinishHydration();
    };
  }, []);

  return hydrated;
};

export default useStore;
export { useStoreIsHydrated };
export type { AppState };
