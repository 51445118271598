import { FormControl, Grid, TextField, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';

export default function InitEstate({
  formData,
  handleChange,
  errorMessage,
  nextButton,
}) {
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Typography variant="h2">Opplysninger om avdøde</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            type="text"
            id="name"
            label="Name"
            onChange={handleChange('name')}
            value={formData.name || ''}
            autoCapitalize="on"
            helperText={errorMessage.name && errorMessage.name[0]}
            error={!!errorMessage.name}
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            id="fødselsnummer"
            type="text"
            label="Fødselsnummer"
            onChange={handleChange('deceased_ssn')}
            value={formData.deceased_ssn || ''}
            helperText={
              errorMessage.deceased_ssn && errorMessage.deceased_ssn[0]
            }
            error={!!errorMessage.deceased_ssn}
            required
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        {nextButton()}
      </Grid>
    </Grid>
  );
}
