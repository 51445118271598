import { AddCircle } from '@mui/icons-material';
import {
  Badge,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import EditDeletePopup from 'components/EditDeletePopup';
import MainCard from 'components/MainCard';
import { useIntl } from 'react-intl';
import messages from './messages';

export default function StakeholderCard({
  stakeholder,
  stakeholdersComplete,
  onEdit,
  setParentData,
  newChildForm,
  handleDelete,
  deceased = false,
  name,
}) {
  const intl = useIntl();

  // Example usage:
  const optionsTooltip = intl.formatMessage(messages.optionsTooltip);
  const addChildTooltip = intl.formatMessage(messages.addChildTooltip);
  const deceasedLabel = intl.formatMessage(messages.deceasedLabel);
  const grandchildLabel = intl.formatMessage(messages.grandchildLabel);
  const grandgrandchildLabel = intl.formatMessage(
    messages.grandgrandchildLabel,
  );

  const handleNewChild = (id, type) => {
    let heirRelation;
    if (type === 'life_heirs') {
      heirRelation = grandchildLabel;
    }
    if (type === 'heir') {
      heirRelation = grandgrandchildLabel;
    }
    setParentData({
      heir_parent: id,
      type: 'heir',
      entity_type: 'personal',
      heir_relation: heirRelation,
    });
    newChildForm();
  };

  return (
    <div className="relative overflow-visible">
      <MainCard className="m-auto h-40 w-60 pb-3">
        {!deceased && (
          <div className="relative z-40 overflow-visible">
            {/* <Tooltip title={'Options'} placement="top"> */}
            <div className="absolute -right-2 -top-2">
              <EditDeletePopup
                handleEdit={() => onEdit(stakeholder.id)}
                handleDelete={() => handleDelete(stakeholder.id)}
                disablePortal={true}
              />
            </div>
            {/* </Tooltip> */}
          </div>
        )}

        <CardContent className="text-center">
          <div className="flex items-center justify-center space-x-2">
            <div className="space-y-1">
              <Typography className="text-lg font-semibold">
                {deceased ? name : stakeholder.name}
              </Typography>
              <Badge variant="outlined">
                {deceased
                  ? deceasedLabel
                  : stakeholder.heir_relation
                    ? stakeholder.heir_relation
                    : stakeholder.get_type_display}
              </Badge>
            </div>
          </div>
        </CardContent>

        {!deceased &&
          (stakeholder.type === 'life_heirs' || stakeholder.type === 'heir') &&
          !stakeholdersComplete && (
            <div className="flex items-center justify-center">
              <Tooltip title={addChildTooltip} placement="top">
                <IconButton
                  // size="large"
                  onClick={() =>
                    handleNewChild(stakeholder.id, stakeholder.type)
                  }
                  className="absolute -bottom-4"
                  color="secondary"
                >
                  <AddCircle
                    fontSize="medium"
                    className="rounded-full bg-background"
                  />
                </IconButton>
              </Tooltip>
            </div>
          )}
      </MainCard>
    </div>
  );
}
