import { Box, Button } from '@mui/material';
import MainCard from 'components/MainCard';
import { toast } from 'sonner';

const CopyToClipboardButton = ({ text }) => {
  const handleClick = () => {
    toast('Copied to clipboard');
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <Button onClick={handleClick} variant="outlined">
        Copy to clipboard
      </Button>
    </>
  );
};

export default function TextCard({ text, title }) {
  return (
    <MainCard title={title}>
      <Box className="h-44 overflow-y-scroll scroll-smooth whitespace-pre-line ring-2">
        {text}
      </Box>
      <div className="flex items-center justify-end pt-4">
        <CopyToClipboardButton text={text} />
      </div>
    </MainCard>
  );
}
