import { FormControl, Grid, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format, isValid, parse, parseISO } from 'date-fns';
import { gridSpacing } from 'store/constant';

export default function EstatePersonalia({
  formData,
  handleChange,
  errorMessage,
  setFormData,
  nextButton,
}) {
  const handleDateChange = (date) => {
    if (date && isValid(date)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        deceased_date: format(date, 'yyyy-MM-dd'),
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        deceased_date: '',
      }));
    }
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Typography variant="h2">Opplysninger om avdøde</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <DatePicker
            label="Dødsdato"
            format="dd/MM/yyyy"
            onChange={handleDateChange}
            value={
              formData.deceased_date ? parseISO(formData.deceased_date) : null
            }
            slotProps={{
              textField: {
                helperText:
                  errorMessage.deceased_date && errorMessage.deceased_date[0],
              },
            }}
            error={!!errorMessage.deceased_date}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => {
                  const date = parse(e.target.value, 'dd/MM/yyyy', new Date());
                  handleDateChange(date);
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            id="address"
            type="text"
            label="Boligadresse"
            onChange={handleChange('postal_address_street')}
            value={formData.postal_address_street || ''}
            helperText={
              errorMessage.postal_address_street &&
              errorMessage.postal_address_street[0]
            }
            error={!!errorMessage.postal_address_street}
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            id="postnr"
            type="text"
            label="Postnr"
            onChange={handleChange('postal_address_zip')}
            value={formData.postal_address_zip || ''}
            helperText={
              errorMessage.postal_address_zip &&
              errorMessage.postal_address_zip[0]
            }
            error={!!errorMessage.postal_address_zip}
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            id="poststed"
            type="text"
            label="Poststed"
            onChange={handleChange('postal_address_city')}
            value={formData.postal_address_city || ''}
            helperText={
              errorMessage.postal_address_city &&
              errorMessage.postal_address_city[0]
            }
            error={!!errorMessage.postal_address_city}
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {nextButton()}
      </Grid>
    </Grid>
  );
}
