import {
  Button,
  FormControl,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import MainCard from 'components/MainCard';
import useFormHandler from 'hooks/useFormHandler';
import { useSignicatLoginUrl } from 'query/user';
import { useState } from 'react';

import { ArrowBack } from '@mui/icons-material';
import ArvskifteLogo from '../../assets/images/arvTestLogo.png';

export default function SignicatLogin(params) {
  const [newUser, setNewUser] = useState(false);

  const signicatLoginUrl = useSignicatLoginUrl();
  const { formData, handleChange, setErrorMessage, errorMessage } =
    useFormHandler();

  const handleSignicatLogin = async (email = null) => {
    const requestData = email !== null ? { email: email } : {};

    signicatLoginUrl.mutate(requestData, {
      onSuccess: (response) => {
        window.location.href = response.redirect_uri;
      },
      onError: (error) => {
        console.log('loginError: ', error);
      },
    });
  };

  function isValidEmail(email) {
    const regex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }

  const handleSubmit = () => {
    setErrorMessage('');
    const email = formData.email;
    if (isValidEmail(email)) {
      handleSignicatLogin(email);
    } else {
      setErrorMessage({ email: 'Not valid' });
    }
  };

  return (
    <div className="flex h-full items-center justify-center">
      <div className="w-20%">
        <MainCard>
          {newUser && (
            <div className="absolute left-2 top-2">
              <IconButton
                onClick={() => {
                  setNewUser(false);
                }}
              >
                <ArrowBack />
              </IconButton>
            </div>
          )}
          <Stack
            direction={'column'}
            spacing={1}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <img
              alt="arvskifte-logo"
              src={ArvskifteLogo}
              style={{
                width: '250px',
                height: 'auto',
                maxWidth: '80%',
              }}
            />
            {!newUser ? (
              <>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => handleSignicatLogin()}
                >
                  Logg inn med BankID
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setNewUser(true)}
                >
                  Registrer ny bruker
                </Button>
              </>
            ) : (
              <>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    size="small"
                    id="newUserEmail"
                    type="text"
                    label="Epost"
                    onChange={handleChange('email')}
                    value={formData?.email || ''}
                    helperText={errorMessage.email && errorMessage.email}
                    error={!!errorMessage.email}
                    onFocus={() => setErrorMessage('')}
                  />
                </FormControl>
                <Button fullWidth variant="contained" onClick={handleSubmit}>
                  Register
                </Button>
              </>
            )}
          </Stack>
        </MainCard>
      </div>
    </div>
  );
}
