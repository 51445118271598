import {
  Button,
  Collapse,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import EditDeletePopup from 'components/EditDeletePopup';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { gridSpacing } from 'store/constant';
import messages from './messages';

export default function StakeholderTableView({
  stakeholder,
  handleEditStakeholder,
  handleDelete,
}) {
  const [expanded, setExpanded] = useState(false);
  const intl = useIntl();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {stakeholder.data?.length > 0 ? (
        <>
          <Grid container spacing={gridSpacing} justifyContent="center">
            <Grid item xs={12} md={5}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {stakeholder.data
                      ?.filter((s) => s.type !== 'other')
                      .sort((a, b) => (a.type > b.type ? 1 : -1))
                      .map((s) => (
                        <TableRow key={s.id}>
                          <TableCell component="th" scope="row">
                            {s.name}
                          </TableCell>
                          <TableCell>
                            {s.heir_relation
                              ? s.heir_relation
                              : s.get_type_display}
                          </TableCell>
                          <TableCell>
                            <EditDeletePopup
                              handleEdit={() => handleEditStakeholder(s.id)}
                              handleDelete={() => handleDelete(s.id)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {stakeholder.data?.filter((s) => s.type === 'other').length > 0 && (
              <>
                <Grid item xs={12} md={8}>
                  <Divider>
                    <Button onClick={handleExpandClick}>
                      {expanded
                        ? intl.formatMessage(messages.hideExternal)
                        : intl.formatMessage(messages.showExternal)}
                    </Button>
                  </Divider>
                </Grid>

                <Grid item xs={12} md={5}>
                  <Collapse in={expanded} timeout="auto">
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          {stakeholder.data
                            ?.filter((s) => s.type === 'other')
                            .map((s) => (
                              <TableRow key={s.id}>
                                <TableCell component="th" scope="row">
                                  {s.name}
                                </TableCell>
                                <TableCell>
                                  {s.heir_relation
                                    ? s.heir_relation
                                    : s.get_type_display}
                                </TableCell>
                                <TableCell>
                                  <EditDeletePopup
                                    handleEdit={() =>
                                      handleEditStakeholder(s.id)
                                    }
                                    handleDelete={() => handleDelete(s.id)}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Collapse>
                </Grid>
              </>
            )}
          </Grid>
        </>
      ) : (
        <Typography variant="caption">
          {intl.formatMessage(messages.emptyText)}
        </Typography>
      )}
    </div>
  );
}
