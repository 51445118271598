import { useAuthMutation, useAuthQuery } from '.';

import { useUser } from './user';

import type { AuthUseQueryOptions } from '.';

// type Profile = {
//   get_profile_types: string[];
//   get_avatar_avatar: string;
// };

function useProfile(options: any = {}) {
  const mergedOptions: AuthUseQueryOptions = {
    ...options,
    onSuccess: (data: any) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  const user = useUser();
  return useAuthQuery(
    ['profile', user.data && user.data.id],
    `user/profile_b2c/`,
    mergedOptions,
  );
}

function useUpdateProfile() {
  return useAuthMutation('profile', {
    idField: 'id',
    method: 'put',
  });
}

export { useProfile, useUpdateProfile };
