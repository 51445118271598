import { useMutation, useQueryClient } from '@tanstack/react-query';

import useStore from '../store';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { getHeaders, useAuthQuery } from '.';

type UserLoginRequest = {};
type UserLoginResponse = {};

type PasswordResetRequest = {};
type PasswordResetError = {
  email: string;
};
type PasswordResetResponse = {};

type User = {};

function useLogin() {
  const headers = getHeaders();
  const store = useStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<UserLoginRequest, Error, UserLoginResponse>(
    async (obj) => {
      const url = `${process.env.REACT_APP_API_URL}login/`;

      const loginRes = await fetch(url, {
        headers,
        method: 'post',
        body: JSON.stringify(obj),
      });
      const loginResJson = await loginRes.json();

      if (loginRes.ok) {
        const tokenRes = await fetch(
          `${process.env.REACT_APP_API_URL}knox_login/`,
          {
            headers: {
              ...headers,
              Authorization: `SimpleToken ${loginResJson.key}`,
            },
            method: 'post',
            body: JSON.stringify({}),
          },
        );
        const tokenResJson = await tokenRes.json();
        if (tokenRes.ok) {
          store.setToken(tokenResJson.token);
          return tokenResJson.user;
        }
        throw tokenResJson;
      } else {
        throw loginResJson;
      }
    },
    {
      onSuccess: async (data) => {
        store.setLoggedIn(true);
        queryClient.setQueryData(['user'], data);
        navigate('/');
      },
    },
  );
}

function useLogout() {
  const store = useStore();

  const queryClient = useQueryClient();

  return useMutation(async () => {
    const headers = getHeaders();

    await fetch(`${process.env.REACT_APP_API_URL}knox_logout/`, {
      headers,
      method: 'post',
      body: JSON.stringify({}),
    });

    store.setToken(null);
    store.setLoggedIn(false);

    queryClient.clear();

    return null;
  });
}

function useUser(options: object) {
  const logout = useLogout();
  return useAuthQuery<User, Error>(['user'], 'user/', {
    ...options,
    networkMode: 'always',
    onError: (data: any) => {
      toast.error('Failed to reach server (Check internet connection)', {
        duration: 34000,
      });

      if (data.http_status === 401) {
        logout.mutate();
      }
    },
  });
}

function usePasswordReset() {
  return useMutation<
    PasswordResetRequest,
    PasswordResetError,
    PasswordResetResponse
  >(async () => {
    const headers = getHeaders();
    return await fetch(`${process.env.REACT_APP_API_URL}password/reset/`, {
      headers,
      method: 'post',
      body: JSON.stringify({}),
    });
  });
}

function usePasswordResetConfirm() {
  return useMutation(async () => {
    const headers = getHeaders();
    await fetch(`${process.env.REACT_APP_API_URL}password/reset/confirm/`, {
      headers,
      method: 'post',
      body: JSON.stringify({}),
    });
    return null;
  });
}

function useSignicatLoginUrl(options = {}) {
  const headers = getHeaders();

  return useMutation(async (obj) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}bobestyreruser/get_login_url/`,
      { headers, method: 'post', body: JSON.stringify(obj) },
    );
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }
    return res.json();
  });
}

// return useQuery(
//   ['signicatLoginUrl'],
//   () =>
//     fetch(`${process.env.REACT_APP_API_URL}bobestyreruser/get_login_url`, {
//       headers,
//       method: 'GET',
//     }).then((res) => res.json()),
//   { ...options },
// );
// }

function useSignicatLogin(options = {}) {
  const store = useStore();
  const headers = getHeaders();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [newUser, setNewUser] = useState(false);

  return useMutation(
    async (obj) => {
      const loginRes = await fetch(
        `${process.env.REACT_APP_API_URL}bobestyreruser/login/`,
        {
          headers,
          method: 'post',
          body: JSON.stringify(obj),
        },
      );
      const loginResJson = await loginRes.json();
      console.log('LOGINRESJSON: ', loginResJson);
      if (loginResJson.new_user) setNewUser(true);
      if (loginRes.ok) {
        const tokenRes = await fetch(
          `${process.env.REACT_APP_API_URL}knox_login/`,
          {
            headers: {
              ...headers,
              Authorization: `SimpleToken ${loginResJson.key}`,
            },
            method: 'post',
            body: JSON.stringify({}),
          },
        );
        const tokenResJson = await tokenRes.json();
        if (tokenRes.ok) {
          store.setToken(tokenResJson.token);
          return tokenResJson.user;
        }
        throw tokenResJson;
      } else {
        throw loginResJson;
      }
    },
    {
      onSuccess: async (data) => {
        console.log(data);
        if (newUser) {
          navigate('/createprofile');
        } else {
          navigate('/');
        }
        store.setLoggedIn(true);
        queryClient.setQueryData(['user'], data);
      },
    },
  );
}

export {
  useLogin,
  useLogout,
  usePasswordReset,
  usePasswordResetConfirm,
  useSignicatLogin,
  useSignicatLoginUrl,
  useUser,
};
