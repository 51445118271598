import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useEstate } from 'query/estates';
import { useProfile } from 'query/profile';
import { useCreateStakeholder } from 'query/stakeholder';
import { useState } from 'react';
import useStore from 'store';
import { gridSpacing } from 'store/constant';

export default function EstateTaskListQuestions({
  formData,
  activeStep,
  handleChange,
  nextButton,
  backButton,
  setErrorMessage,
  errorMessage,
  inputDisabled,
  setInputDisabled,
  nextVisible,
  setNextVisible,
}) {
  const store = useStore();
  const activeEstate = store.activeEstate;
  const estate = useEstate({ uuid: activeEstate });
  const create = useCreateStakeholder();
  const profile = useProfile();

  const [userIsHeir, setUserIsHeir] = useState(false);
  const [userHeirRelation, setUserHeirRelation] = useState('');
  // const [inputDisabled, setInputDisabled] = useState(false);
  // const [nextVisible, setNextVisible] = useState(false);
  const handleStakeholderCreate = async () => {
    setErrorMessage('');

    const stakeholderType = () => {
      if (userHeirRelation === 'child') return 'life_heirs';
      if (userHeirRelation === 'parent') return 'heir';
      if (userHeirRelation === 'grandchild') return 'heir';
      if (userHeirRelation === 'spouse') return 'spouse';
      if (userHeirRelation === 'other') return 'other';
    };

    create.mutate(
      {
        name: profile.data.fname + ' ' + profile.data.lname,
        ssn: profile.data.ssn,
        address_street: profile.data.address_street,
        address_city: profile.data.address_city,
        address_zip: profile.data.address_zip,
        heir_relation: userHeirRelation,
        entity_type: 'personal',
        type: stakeholderType(),
        estate: estate.data.id,
      },
      {
        onSuccess: (data) => {
          console.log('Mutation succeeded. Data:', data);
          setNextVisible(true);
          setInputDisabled(true);
        },
        onError: (error) => {
          console.log('Error:', error);
          setErrorMessage(error);
        },
      },
    );
  };

  const question = formData.b2c_estate_questions;
  // console.log(activeStep);
  // console.log(profile.data);

  return (
    <Grid container spacing={gridSpacing}>
      {/* {activeStep === 0 && (
        <>
          <Grid item xs={12}>
            <Typography variant="h2">Opplysninger om boet</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body">
              Vi vil å spørre deg en rekke spørsmål for å kunne opprette en
              gjøreliste for deg. Du kan alltid svare vet ikke, for å ta
              stilling til det senere.
            </Typography>
          </Grid>
        </>
      )} */}
      {activeStep === 0 && (
        <>
          <Grid item xs={12}>
            <Typography variant="h2">Administrasjon av boet</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Boet skal administreres av</FormLabel>
            <RadioGroup
              onChange={handleChange(
                'b2c_estate_questions.single_estate_manager',
              )}
              value={question?.single_estate_manager}
              row
            >
              <FormControlLabel control={<Radio />} value={true} label="Meg" />
              <FormControlLabel
                control={<Radio />}
                value={false}
                label="Flere"
              />
              {/* <FormControlLabel
                control={<Radio />}
                value={'unsure'}
                label="Usikker"
              /> */}
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              <p>
                Det er vanlig at en person tar på seg ansvaret for å håndtere
                boets skifteprosedyrer, da dette forenkler prosessen med å
                innhente nødvendige samtykker og fullmakter fra ulike instanser.
              </p>
              <p>
                Dersom du planlegger å utføre skiftet alene, må du ha fullmakter
                fra andre arvinger, vi hjelper deg med dette.
              </p>
            </Typography>
          </Grid>
        </>
      )}
      {activeStep === 1 && (
        <>
          <Grid item xs={12}>
            <Typography variant="h2">Skal du arve fra avdøde?</Typography>
          </Grid>
          <Grid item xs={12}>
            {/* <FormLabel>Jeg skal arve</FormLabel> */}
            <RadioGroup
              onChange={(e) => {
                const value = e.target.value === 'true';
                setUserIsHeir(value);
                setNextVisible(!value);
              }}
              value={userIsHeir || false}
              row
            >
              <FormControlLabel
                control={<Radio disabled={inputDisabled} />}
                value={true}
                label="Ja"
              />
              <FormControlLabel
                control={<Radio disabled={inputDisabled} />}
                value={false}
                label="Nei"
              />
            </RadioGroup>
          </Grid>
          {userIsHeir && (
            <>
              <Grid item xs={12}>
                <FormLabel>Din relasjon til avdøde</FormLabel>
                <RadioGroup
                  value={userHeirRelation}
                  onChange={(e) => {
                    setUserHeirRelation(e.target.value);
                    handleChange('heir_relation');
                    setNextVisible(false);
                  }}
                  row
                >
                  {formData.deceased_marital_status === 'married' && (
                    <FormControlLabel
                      control={<Radio disabled={inputDisabled} />}
                      value="spouse"
                      label="Ektefelle"
                      // onInput={() => setNextVisible(true)}
                    />
                  )}
                  <FormControlLabel
                    control={<Radio disabled={inputDisabled} />}
                    value="parent"
                    label="Forelder"
                  />
                  <FormControlLabel
                    control={<Radio disabled={inputDisabled} />}
                    value="child"
                    label="Barn"
                  />
                  <FormControlLabel
                    control={<Radio disabled={inputDisabled} />}
                    value="grandchild"
                    label="Barnebarn"
                  />
                  <FormControlLabel
                    control={<Radio disabled={inputDisabled} />}
                    value="other"
                    label="Annen"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={handleStakeholderCreate}
                  disabled={inputDisabled}
                >
                  Lagre
                </Button>
              </Grid>
            </>
          )}
        </>
      )}
      {activeStep === 2 && (
        <>
          <Grid item xs={12}>
            <Typography variant="h2">Fast eiendom</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Eide avdøde fast eiendom?</FormLabel>
            <RadioGroup
              onChange={handleChange('b2c_estate_questions.has_realestate')}
              value={question?.has_realestate || false}
              row
            >
              <FormControlLabel control={<Radio />} value={true} label="Ja" />
              <FormControlLabel control={<Radio />} value={false} label="Nei" />
            </RadioGroup>
          </Grid>
        </>
      )}
      {activeStep === 3 && (
        <>
          <Grid item xs={12}>
            <Typography variant="h2">Ferie eiendom</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Eide avdøde ferie eiendom?</FormLabel>
            <RadioGroup
              onChange={handleChange('b2c_estate_questions.has_vacationhome')}
              value={question?.has_vacationhome || false}
              row
            >
              <FormControlLabel control={<Radio />} value={true} label="Ja" />
              <FormControlLabel control={<Radio />} value={false} label="Nei" />
            </RadioGroup>
          </Grid>
        </>
      )}
      {activeStep === 4 && (
        <>
          <Grid item xs={12}>
            <Typography variant="h2">Landbrukseiendom</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Eide avdøde Landbrukseiendom?</FormLabel>
            <RadioGroup
              onChange={handleChange(
                'b2c_estate_questions.has_agricultural_property',
              )}
              value={question?.has_agricultural_property || false}
              row
            >
              <FormControlLabel control={<Radio />} value={true} label="Ja" />
              <FormControlLabel control={<Radio />} value={false} label="Nei" />
            </RadioGroup>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        {activeStep > 0 && backButton()}
        {(activeStep === 0 || nextVisible || inputDisabled) && nextButton()}
      </Grid>
    </Grid>
  );
}
