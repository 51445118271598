import { useAuthMutation } from 'query';

export function useCreateClaim(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation('claim', mergedOptions);
}
