import { useAuthMutation } from 'query';

// export function useStakeholder(options = {}) {
//   const mergedOptions = {
//     ...options,
//     onSuccess: (data) => {
//       if (options.onSuccess) {
//         options.onSuccess();
//       }
//     },
//   };
//   return useAuthQuery(
//     ['stakeholders'],
//     `stakeholder?uuid?=${options.uuid}`,
//     mergedOptions,
//   );
// }

export function useCreateStakeholder(options = {}) {
  const mergedOptions = {
    ...options,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation('stakeholder', mergedOptions);
}

export function useStakeholderSendMail(options = {}) {
  const mergedOptions = {
    ...options,
    method: 'post',
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  };
  return useAuthMutation(`stakeholder/${options.id}/send_email`, mergedOptions);
}
