import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useCreateEstate,
  useEstateCreation,
  useUpdateEstate,
} from 'query/estates';
import { useUser } from 'query/user';

//import forms to use
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import MainCard from 'components/MainCard';
import useFormHandler from 'hooks/useFormHandler';
import useStore from 'store';
import EstatePersonalia from './EstatePersonalia';
import EstateTaskListQuestions from './EstateTaskListQuestions';
import FinalStep from './FinalStep';
import InitEstate from './InitEstate';
import MaritalStatus from './MaritalStatus';
import WillQuestion from './WillQuestion';

export default function CreateEstate(props) {
  //   const intl = useIntl();
  // const store = useStore();
  const activeEstate = useStore((state) => state.activeEstate);
  const setActiveEstate = useStore((state) => state.setActiveEstate);
  const user = useUser();
  const Create = useCreateEstate();
  const Update = useUpdateEstate();
  const navigate = useNavigate();
  const estate = useEstateCreation({ uuid: activeEstate, enabled: false });
  const {
    formData,
    handleChange,
    setFormData,
    errorMessage,
    setErrorMessage,
    formDataChanged,
    setFormDataChanged,
  } = useFormHandler();
  const [activeComponent, setActiveComponent] = useState('InitEstate');
  const [activeStep, setActiveStep] = useState(0);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [nextVisible, setNextVisible] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const status = query.get('status');

  const steps = [
    { component: 'InitEstate', steps: 0 },
    { component: 'EstatePersonalia', steps: 0 },
    { component: 'MaritalStatus', steps: 0 },
    { component: 'WillQuestion', steps: 0 },
    { component: 'EstateTaskListQuestions', steps: 4 },
    { component: 'FinalStep', steps: 0 },
    // { component: 'EstateQuestion', steps: 0 },
    // { component: 'EstateQuestionType', steps: 0 },
    // { component: 'PrivateDivisionForm', steps: 4 },
  ];

  useEffect(() => {
    if (status === 'created') {
      (async () => {
        try {
          const response = await estate.refetch();
          if (response.data) {
            const as = response.data.new_estate_active_step;
            const ac = response.data.new_estate_active_component;
            setActiveStep(as);
            setActiveComponent(ac);
            setFormData({ ...response.data });
          }
        } catch (error) {
          console.error('Failed to fetch estate data:', error);
        }
      })();
    }
  }, []);

  const handleNext = () => {
    const currentIndex = steps.findIndex(
      (step) => step.component === activeComponent,
    );

    const currentStep = steps[currentIndex];
    const nextComponent = steps[currentIndex + 1];

    if (currentStep.steps > 1 && currentStep.steps !== activeStep) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (!nextComponent) {
      setActiveStep(0);
      navigate('/dashboard');
    } else {
      setActiveStep(0);
      setActiveComponent(nextComponent.component);
    }
    handleChange('new_estate_active_step', activeStep);
    handleChange('new_estate_active_component', activeComponent);
    console.log('CurrentStep', currentStep, '| activeStep', activeStep);
  };

  const handleBack = () => {
    setErrorMessage('');

    const currentIndex = steps.findIndex(
      (step) => step.component === activeComponent,
    );
    const currentStep = steps[currentIndex];
    let previousComponent = steps[currentIndex - 1];

    if (activeStep > 0 && currentStep.steps > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      setActiveComponent(previousComponent.component);
    }
  };

  // const handleChange = (input) => (e) => {
  //   let value;

  //   if (e.target && e.target.type === 'file') {
  //     value = e.target.files[0];
  //   } else {
  //     value =
  //       e.target && e.target.type === 'checkbox'
  //         ? e.target.checked
  //         : e.target.value;
  //     if (value === 'true' || value === 'false') {
  //       value = value === 'true';
  //     }
  //   }

  //   setFormData((prevFormData) => {
  //     const inputPath = input.split('.');

  //     const updateNestedData = (data, path, newValue) => {
  //       if (path.length === 1) {
  //         return { ...data, [path[0]]: newValue };
  //       }

  //       if (!data[path[0]]) {
  //         data[path[0]] = {};
  //       }

  //       data[path[0]] = updateNestedData(
  //         data[path[0]],
  //         path.slice(1),
  //         newValue,
  //       );
  //       return data;
  //     };

  //     const updatedFormData = updateNestedData(
  //       { ...prevFormData },
  //       inputPath,
  //       value,
  //     );

  //     return updatedFormData;
  //   });
  //   setFormDataChanged(true);
  // };

  const handleCreate = async () => {
    setErrorMessage('');
    Create.mutate(
      {
        ...formData,
        type: 'deceased',
        deceased_is_private: true,
        estate_manager: user.data.id,
        original_company_orgnr: formData.deceased_ssn?.slice(0, 6),
        deceased_composite_distribution: false,
        new_estate_active_component: activeComponent,
        new_estate_active_step: activeStep,
        // status: 'created',
      },
      {
        onSuccess: (data) => {
          setActiveEstate(data.uuid);
          console.log('Mutation succeeded. Data:', data);
          setFormData({
            ...data,
            status: 'created',
            caseworkers: user.data.id,
            new_estate_active_component: activeComponent,
            new_estate_active_step: activeStep,
          });
          handleNext();
          setFormDataChanged(false);
        },
        onError: (error) => {
          console.log('Error:', error);
          setErrorMessage(error);
        },
      },
    );
  };

  const handleUpdate = async () => {
    // console.log('update', formData);
    setErrorMessage('');
    Update.mutate(
      {
        ...formData,
        new_estate_active_component: activeComponent,
        new_estate_active_step: activeStep,
        caseworkers: user.data.id,
      },
      {
        onSuccess: (data) => {
          console.log('Update mutation succeeded. Data:', data);
          handleNext();
          setFormDataChanged(false);
        },
        onError: (error) => {
          console.log('Error:', error);
          setErrorMessage(error);
        },
      },
    );
  };

  const handleNextButton = () => {
    setInputDisabled(false);
    setNextVisible(true);
    if (!formData.uuid) {
      handleCreate();
    } else if (formDataChanged) {
      handleUpdate();
    } else {
      handleNext();
    }
    console.log(formData);
  };

  const NextButton = () => (
    <Button
      size="large"
      variant="contained"
      onClick={handleNextButton}
      endIcon={<ArrowForward />}
      sx={{ float: 'right' }}
    >
      Neste
    </Button>
  );

  const BackButton = () => (
    <Button
      size="large"
      variant="outlined"
      onClick={handleBack}
      startIcon={<ArrowBack />}
      sx={{ float: 'left' }}
    >
      Tilbake
    </Button>
  );

  // const FadeSlide = ({ comp, children, activeComponent }) => (
  //   <Fade in={activeComponent === comp} mountOnEnter unmountOnExit>
  //     <Slide
  //       direction="left"
  //       in={activeComponent === comp}
  //       mountOnEnter
  //       unmountOnExit
  //     >
  //       <div>{children}</div>
  //     </Slide>
  //   </Fade>
  // );

  return (
    <div className="flex h-full items-center justify-center">
      <MainCard sx={{ width: '60%' }}>
        {activeComponent === 'InitEstate' && (
          <InitEstate
            formData={formData}
            handleChange={handleChange}
            errorMessage={errorMessage}
            nextButton={NextButton}
          />
        )}

        {activeComponent === 'EstatePersonalia' && (
          <EstatePersonalia
            formData={formData}
            handleChange={handleChange}
            errorMessage={errorMessage}
            setFormData={setFormData}
            nextButton={NextButton}
            backButton={BackButton}
          />
        )}

        {activeComponent === 'MaritalStatus' && (
          <MaritalStatus
            formData={formData}
            handleChange={handleChange}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            nextButton={NextButton}
            backButton={BackButton}
            inputDisabled={inputDisabled}
            setInputDisabled={setInputDisabled}
            nextVisible={nextVisible}
            setNextVisible={setNextVisible}
          />
        )}

        {activeComponent === 'WillQuestion' && (
          <WillQuestion
            formData={formData}
            handleChange={handleChange}
            nextButton={NextButton}
            backButton={BackButton}
          />
        )}

        {activeComponent === 'EstateTaskListQuestions' && (
          <EstateTaskListQuestions
            formData={formData}
            activeStep={activeStep}
            handleChange={handleChange}
            nextButton={NextButton}
            backButton={BackButton}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            inputDisabled={inputDisabled}
            setInputDisabled={setInputDisabled}
            nextVisible={nextVisible}
            setNextVisible={setNextVisible}
          />
        )}

        {activeComponent === 'FinalStep' && (
          <FinalStep
            formData={formData}
            handleChange={handleChange}
            handleUpdate={handleUpdate}
          />
        )}
      </MainCard>
    </div>
  );
}
