/**
 *
 * UserLogin
 *
 */

// mport PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import ArvskifteLogo from '../../assets/images/arvTestLogo.png';

import { Button, Stack } from '@mui/material';
import MainCard from 'components/MainCard';
import { useNavigate } from 'react-router-dom';

export function UserLogin() {
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <div className="flex h-full items-center justify-center">
      <div className="w-20%">
        <MainCard>
          <Stack
            direction={'column'}
            spacing={1}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <img
              alt="Arvskifte-logo"
              src={ArvskifteLogo}
              style={{
                width: '250px',
                height: 'auto',
                maxWidth: '80%',
              }}
            />
            <Button
              fullWidth
              variant="contained"
              onClick={() => navigate('/email')}
            >
              Login with email
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={() => navigate('/bankid')}
            >
              Login with BankId
            </Button>
          </Stack>
        </MainCard>
      </div>
    </div>
  );
}

export default UserLogin;
