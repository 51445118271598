import { FileUpload } from '@mui/icons-material';
import { Button, FormLabel, TextField } from '@mui/material';
import { useState } from 'react';

function FileUploadButton({ label, onFileChange }) {
  const [filename, setFilename] = useState('No file chosen');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFilename(file.name);
      onFileChange(event); // Call the passed handleChange function with the event
    } else {
      setFilename('No file chosen');
    }
  };

  return (
    <div className="flex items-center gap-1">
      <FormLabel>{label}</FormLabel>
      <TextField
        color="primary"
        accept="image/*"
        type="file"
        onChange={handleFileChange}
        id="icon-button-file"
        style={{ display: 'none' }}
      />
      <label htmlFor="icon-button-file">
        <Button
          variant="outlined"
          component="span"
          size="small"
          color="primary"
          startIcon={<FileUpload />}
        >
          {filename}
        </Button>
      </label>
      {/* <Typography variant="overline">{filename}</Typography> */}
    </div>
  );
}

export default FileUploadButton;
