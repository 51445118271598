import { Grid, Switch, Typography } from '@mui/material';

export default function TitleToggleMenu({
  title,
  iconLeft,
  iconRight,
  switchState,
  toggleSwitch,
  children,
  id,
  toggleId,
}) {
  return (
    <Grid container className="w-full items-center pb-5" id={id}>
      <Grid item xs={9} md={4} className="flex items-center justify-start">
        <Typography variant="h2">{title}</Typography>
      </Grid>
      <Grid item xs={3} md={4}>
        {toggleSwitch && (
          <div
            className="xs:justify-end flex items-center md:justify-center"
            id={toggleId}
          >
            {iconLeft}
            <Switch
              checked={switchState}
              onChange={toggleSwitch}
              color="default"
            />
            {iconRight}
          </div>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        // justifyContent={{ md: 'end', xs: 'center' }}
        // alignItems={{ md: 'center', xs: 'center' }}
        className="flex items-center sm:justify-center md:justify-end"
      >
        {children}
      </Grid>
    </Grid>
  );
}
