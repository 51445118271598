import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from '@mui/material';

import CustomSubAccordion from 'components/CustomSubAccordion';
import StepAvatar from 'components/StepAvatar';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export default function TaskStep4({ taskManager }) {
  const theme = useTheme();

  const { expandedMainPanel, handleMainAccordionChange, mainTasks } =
    taskManager;
  const completed = mainTasks.step4;

  return (
    <Accordion
      expanded={expandedMainPanel === 'step4'}
      onChange={handleMainAccordionChange('step4')}
      sx={{
        backgroundColor: completed ? theme.palette.success.light : 'default',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className="flex items-center gap-2">
          <StepAvatar>4</StepAvatar>
          <Typography variant="h4">
            <FormattedMessage {...messages.step4Title} />
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="get_bank_access"
          title={<FormattedMessage {...messages.bankAccessTitle} />}
          detailText={<FormattedMessage {...messages.bankAccessDetail} />}
        />
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="handle_customer_relations"
          title={<FormattedMessage {...messages.customerRelationsTitle} />}
          detailText={
            <FormattedMessage {...messages.customerRelationsDetail} />
          }
        />
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="asset_valuation"
          title={<FormattedMessage {...messages.assetValuationTitle} />}
          detailText={<FormattedMessage {...messages.assetValuationDetail} />}
        />
      </AccordionDetails>
    </Accordion>
  );
}
