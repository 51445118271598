import { useState } from 'react';

const updateNestedData = (data, path, value) => {
  let updatedData = { ...data };
  let current = updatedData;

  for (let i = 0; i < path.length - 1; i++) {
    const part = path[i];
    if (!current[part]) current[part] = {};
    current = current[part];
  }

  current[path[path.length - 1]] = value;
  return updatedData;
};

const useFormHandler = (initialState = {}) => {
  const [formData, setFormData] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState({});
  const [formDataChanged, setFormDataChanged] = useState(false);

  const handleChange =
    (input, value = null) =>
    (e) => {
      let newValue =
        value ??
        (e?.target?.type === 'checkbox'
          ? e.target.checked
          : e?.target?.type === 'file'
            ? e.target.files[0]
            : e.target.value);

      if (newValue === 'true' || newValue === 'false') {
        newValue = newValue === 'true';
      }
      if (newValue === 'unsure') {
        newValue = false;
      }

      setFormData((prevFormData) => {
        const inputPath = input.split('.');
        return updateNestedData(prevFormData, inputPath, newValue);
      });
      setFormDataChanged(true);
      // console.log(formData);
    };

  return {
    formData,
    handleChange,
    setFormData,
    errorMessage,
    setErrorMessage,
    formDataChanged,
    setFormDataChanged,
  };
};

export default useFormHandler;
