import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormLabel,
  Grid,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';

import StepAvatar from 'components/StepAvatar';
import { gridSpacing } from 'store/constant';
import messages from './messages';

export default function TaskStep2({ taskManager }) {
  const theme = useTheme();
  // const StepAvatar = styled(Avatar)(({ theme }) => ({
  //   backgroundColor: theme.palette.secondary.main,
  //   color: '#ffffff',
  // }));

  const {
    expandedMainPanel,
    handleMainAccordionChange,
    mainTasks,
    tasks,
    taskKey,
    handleTaskStatus,
    getStatusToggle,
  } = taskManager;

  const completed = mainTasks.step2;

  return (
    <Accordion
      expanded={expandedMainPanel === 'step2'}
      onChange={handleMainAccordionChange('step2')}
      sx={{
        backgroundColor: completed ? theme.palette.success.light : 'default',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className="flex items-center gap-2">
          <StepAvatar>2</StepAvatar>
          <Typography variant="h4">
            <FormattedMessage {...messages.step2title} />
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {/* {console.log(tasks.shift_type)} */}
        {/* {(tasks.shift_type === 'none' || !tasks.shift_type) && ( */}
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Typography>
              <FormattedMessage {...messages.step2description} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              value={tasks['shift_type']}
              exclusive
              onChange={(event, value) => handleTaskStatus('shift_type', value)}
              color="primary"
            >
              <ToggleButton value="private">
                <FormattedMessage {...messages.privateSettlement} />
              </ToggleButton>
              <ToggleButton value="public">
                <FormattedMessage {...messages.publicSettlement} />
              </ToggleButton>
              <ToggleButton value="composite">
                <FormattedMessage {...messages.compositeSettlement} />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {tasks.shift_type === 'private' && (
            <>
              <Grid item xs={12}>
                <Switch />
                <FormLabel>
                  The deceased's fortune, including funeral expenses, amounts to{' '}
                  <Tooltip
                    title="Grunnbeløpet (G) per 2023-05-01 er 118620 kroner"
                    placement="top"
                    arrow
                  >
                    <span className="cursor-help font-semibold underline">
                      1G
                    </span>
                  </Tooltip>
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <Switch />
                <FormLabel>
                  All heirs are minors or deprived of legal capacity to act
                </FormLabel>
              </Grid>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
