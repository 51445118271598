import { defineMessages } from 'react-intl';

const messages = defineMessages({
  assetsTitle: {
    id: 'Asset.title',
    defaultMessage: 'Assets',
  },
  addNewAsset: {
    id: 'Asset.addNewAsset',
    defaultMessage: 'Add new asset',
  },
  doneAddingAssets: {
    id: 'Asset.doneAddingAssets',
    defaultMessage: 'Done adding assets',
  },
  getVehicleData: {
    id: 'Asset.getVehicleData',
    defaultMessage: 'Get vehicle data',
  },
  assetCompleted: {
    id: 'Asset.assetCompleted',
    defaultMessage: 'Assets completed',
  },
  assetDeleted: {
    id: 'Asset.assetDeleted',
    defaultMessage: 'Asset deleted',
  },
  couldNotDeleteAsset: {
    id: 'Asset.couldNotDeleteAsset',
    defaultMessage: 'Could not delete asset!',
  },
  emptyText: {
    id: 'Asset.emptyText',
    defaultMessage: 'It was empty here, add a new asset.',
  },
  dialogTitleCreateAsset: {
    id: 'AssetForm.dialogTitleCreateAsset',
    defaultMessage: 'Create Asset',
  },
  dialogTitleUpdateAsset: {
    id: 'AssetForm.dialogTitleUpdateAsset',
    defaultMessage: 'Update Asset',
  },
  title: {
    id: 'AssetForm.title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'AssetForm.description',
    defaultMessage: 'Description',
  },
  gnr: {
    id: 'AssetForm.gnr',
    defaultMessage: 'Gnr',
  },
  bnr: {
    id: 'AssetForm.bnr',
    defaultMessage: 'Bnr',
  },
  municipality: {
    id: 'AssetForm.municipality',
    defaultMessage: 'Municipality',
  },
  value: {
    id: 'AssetForm.value',
    defaultMessage: 'Value',
  },
  inheritedBy: {
    id: 'AssetForm.inheritedBy',
    defaultMessage: 'Inherited by',
  },
  none: {
    id: 'AssetForm.none',
    defaultMessage: 'None',
  },
  cancel: {
    id: 'AssetForm.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'AssetForm.save',
    defaultMessage: 'Save',
  },
  assetAdded: {
    id: 'AssetForm.assetAdded',
    defaultMessage: 'Asset added',
  },
  assetUpdated: {
    id: 'AssetForm.assetUpdated',
    defaultMessage: 'Asset updated',
  },
  other: {
    id: 'AssetForm.other',
    defaultMessage: 'other',
  },
});

export default messages;
