/*
 * Login form
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';

import messages from './messages';

function LoginForm(props) {
  const intl = useIntl();

  const { error, auth } = props;

  const [lockedUser, setLockedUser] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return document.removeEventListener('keydown', handleKeyDown);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const prevUser = {};
  useEffect(() => {
    if (prevUser.username && prevUser.username !== username) {
      setLockedUser(true);
      setUsername(prevUser.username);
    }
  }, [prevUser.username, username]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit();
    }
  };

  const onSubmit = () => {
    props.onSubmit(username, password);
    setPassword('');
  };

  const handleUnlockUsername = () => {
    setLockedUser(false);
    setUsername('');
  };

  const changeUsername = (event) => {
    setUsername(event.target.value);
  };

  const changePassword = (event) => {
    setPassword(event.target.value);
  };

  return (
    <Grid container spacing={2}>
      {error && error.non_field_errors && (
        <Grid item xs={12}>
          <Typography color="error">{error.non_field_errors}</Typography>
        </Grid>
      )}
      {lockedUser ? (
        <Grid item xs={12}>
          <FormControl>
            {auth.loggedOutMessage && (
              <Typography>{auth.loggedOutMessage}</Typography>
            )}
            {auth.loggedOutProfile && auth.loggedOutProfile.fname && (
              <List>
                <ListItem>
                  <Avatar>
                    {auth.loggedOutProfile.get_avatar_avatar ? (
                      <img
                        alt="profile-avatar"
                        src={auth.loggedOutProfile.get_avatar_avatar}
                        height="40"
                        width="40"
                      />
                    ) : (
                      `${auth.loggedOutProfile.fname[0]}
                        `
                    )}
                  </Avatar>
                  <ListItemText
                    primary={`${auth.loggedOutProfile.fname}`}
                    secondary={auth.username}
                  />
                </ListItem>
              </List>
            )}
            <Button onClick={handleUnlockUsername}>
              <FormattedMessage {...messages.signinAsSomeoneElse} />
            </Button>
            Test
          </FormControl>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              variant="outlined"
              error={error && error.email !== undefined}
              type="email"
              id="username"
              label={intl.formatMessage(messages.usernameLabel)}
              placeholder={intl.formatMessage(messages.usernameLabel)}
              onChange={changeUsername}
              value={username}
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              helperText={error && error.email ? error && error.email : null}
              autoFocus
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            fullWidth
            variant="outlined"
            error={error && error.password !== undefined}
            id="password"
            type="password"
            label={intl.formatMessage(messages.passwordLabel)}
            value={password}
            placeholder="••••••••••"
            onChange={changePassword}
            helperText={
              error && error.password ? error && error.password : null
            }
          />
        </FormControl>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={props.currentlySending}
          >
            {props.currentlySending ? (
              <CircularProgress size={20} />
            ) : (
              <FormattedMessage {...messages.signinButton} />
            )}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  error: PropTypes.object,
  currentlySending: PropTypes.bool,
  username: PropTypes.string,
  auth: PropTypes.object,
};

export default LoginForm;
