import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';

import CustomSubAccordion from 'components/CustomSubAccordion';
import StepAvatar from 'components/StepAvatar';
import { FormattedMessage } from 'react-intl';
import PreviewPrivateForm from './PreviewPrivateFrom';
import PreviewPrivatePoa from './PreviewPrivatePOA';
import SignPrivateDivisionForm from './SignPrivateDivisionForm';
import SignPrivatePOA from './SignPrivatePOA';
import messages from './messages';

export default function TaskStep3({ taskManager }) {
  const theme = useTheme();

  const {
    expandedMainPanel,
    handleMainAccordionChange,
    mainTasks,
    tasks,
    stakeholdersWithPoa,
  } = taskManager;

  const completed = mainTasks.step3;

  return (
    <Accordion
      expanded={expandedMainPanel === 'step3'}
      onChange={handleMainAccordionChange('step3')}
      sx={{
        backgroundColor: completed ? theme.palette.success.light : 'default',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className="flex items-center gap-2">
          <StepAvatar>3</StepAvatar>
          <Typography variant="h4">
            <FormattedMessage {...messages.step3Title} />
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="stakeholders_complete"
          title={<FormattedMessage {...messages.addStakeholders} />}
          detailText={<FormattedMessage {...messages.stakeholdersDetail} />}
          actionLinkInternal="/stakeholders"
          actionText="Stakeholders"
          toggleable={false}
        />
        {tasks.shift_type === 'public' && (
          <CustomSubAccordion
            taskManager={taskManager}
            taskKey="assets_complete"
            title={<FormattedMessage {...messages.addAssets} />}
            detailText={<FormattedMessage {...messages.assetsDetail} />}
            actionLinkInternal="/assets"
            actionText="Assets"
            // toggleable={false}
          />
        )}
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="poa_signed"
          title={<FormattedMessage {...messages.poaSigning} />}
          customDetail={
            tasks.stakeholders_complete ? (
              <>
                <Typography>
                  <FormattedMessage {...messages.poaSignedDetail} />
                </Typography>
                <List sx={{ maxWidth: '40%' }}>
                  {stakeholdersWithPoa.map((s) => (
                    <ListItem divider key={s.id}>
                      <Grid container>
                        <Grid item xs={6} alignItems={'center'}>
                          <ListItemText>{s.name}</ListItemText>
                        </Grid>
                        <Grid item xs={6} alignItems={'center'}>
                          <PreviewPrivatePoa stakeholderId={s.id} />
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              </>
            ) : (
              <Typography>
                <FormattedMessage {...messages.stakeholdersIncompleteDetail} />
              </Typography>
            )
          }
          // toggleable={false}
          actionComponent={
            <SignPrivatePOA disabled={!tasks.stakeholders_complete} />
          }
        />
        <CustomSubAccordion
          taskManager={taskManager}
          // toggleable={false}
          taskKey="shift_form_signed"
          title={<FormattedMessage {...messages.shiftFormSigning} />}
          detailText={
            tasks.stakeholders_complete ? (
              <FormattedMessage {...messages.shiftFormSignedDetail} />
            ) : (
              <FormattedMessage {...messages.stakeholdersIncompleteDetail} />
            )
          }
          actionComponent={
            <>
              <PreviewPrivateForm disabled={!tasks.stakeholders_complete} />
              <SignPrivateDivisionForm
                disabled={!tasks.stakeholders_complete}
              />
            </>
          }
        />
        <CustomSubAccordion
          taskManager={taskManager}
          taskKey="sendt_forms_to_court"
          title={<FormattedMessage {...messages.sendFormsToCourt} />}
          detailText={<FormattedMessage {...messages.sendFormsToCourtDetail} />}
          isMailModal
          mailTag={'send_forms_to_court'}
          actionText={'Email Template'}
        />
      </AccordionDetails>
    </Accordion>
  );
}
