import useFormHandler from 'hooks/useFormHandler';
import { useCreateClaim } from 'query/claims';
import {
  useEstateClaim,
  useEstateClaims,
  useEstateStakeholders,
  useUpdateClaim,
} from 'query/estates';
import { useEffect, useMemo, useState } from 'react';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  createFilterOptions,
} from '@mui/material';
import StakeholderForm from 'containers/Stakeholders/StakeholderForm';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';
import useStore from 'store';
import { gridSpacing } from 'store/constant';
import messages from './messages';

export default function ClaimForm({ mode, claimId, open, onClose, refetch }) {
  const intl = useIntl();
  const filter = createFilterOptions();
  const activeEstate = useStore((state) => state.activeEstate);
  const stakeholders = useEstateStakeholders({ uuid: activeEstate });
  const createClaim = useCreateClaim();
  const updateClaim = useUpdateClaim();
  const { data: claimData, isLoading } = useEstateClaim({
    id: claimId,
    enabled: mode === 'update',
  });
  const claims = useEstateClaims({ uuid: activeEstate });

  // const [groupedStakeholders, setGroupedStakeholders] = useState([]);
  const [typeCategory, setTypeCategory] = useState('');
  const [stakeholderOpen, toggleStakeholderOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    name: '',
    type: 'other',
    orgnr: '999999',
  });

  const { formData, setFormData, handleChange, errorMessage, setErrorMessage } =
    useFormHandler({ stakeholder: null });

  console.log(formData);

  useEffect(() => {
    if (mode === 'update' && !isLoading) {
      setTypeCategory(claimData.type_category);
      setFormData({
        ...formData,
        title: claimData.title,
        value: claimData.value,
        stakeholder: claimData.stakeholder,
        type_group: 'preferential',
        type_category: claimData.type_category,
      });
    }
  }, [claimData]);

  const groupedStakeholders = useMemo(() => {
    if (!stakeholders.data) return [];

    const heirTypes = ['life_heirs', 'spouse', 'heir'];
    const filteredStakeholders = stakeholders.data.filter(
      (s) => !heirTypes.includes(s.type),
    );
    const heirStakeholders = stakeholders.data.filter((s) =>
      heirTypes.includes(s.type),
    );

    return [
      ...heirStakeholders.map((s) => ({ ...s, group: 'Heirs' })),
      ...filteredStakeholders.map((s) => ({ ...s, group: 'Other' })),
    ];
  }, [stakeholders.data]);

  // useEffect(() => {
  //   if (!stakeholders.data) {
  //     return;
  //   }

  //   const heirTypes = ['life_heirs', 'spouse', 'heir'];
  //   const filteredStakeholders = stakeholders.data.filter(
  //     (s) => !heirTypes.includes(s.type),
  //   );
  //   const heirStakeholders = stakeholders.data.filter((s) =>
  //     heirTypes.includes(s.type),
  //   );

  //   setGroupedStakeholders([
  //     ...heirStakeholders.map((s) => ({ ...s, group: 'Heirs' })),
  //     ...filteredStakeholders.map((s) => ({ ...s, group: 'Other' })),
  //   ]);
  //   console.log(stakeholders.data);
  // }, [stakeholders.data]);

  const handleSubmit = async () => {
    setErrorMessage('');

    if (mode === 'create') {
      createClaim.mutate(
        {
          ...formData,
          date: new Date().toISOString().split('T')[0],
          status: 'approved',
          type_group: 'preferential',
          type_category: typeCategory,
        },
        {
          onSuccess: (data) => {
            console.log('Mutation succeeded. Data:', data);
            setFormData({});
            claims.refetch();
            onClose();
            toast.success(intl.formatMessage(messages.claimCreated));
            setTypeCategory('');
          },
          onError: (error) => {
            console.log('Error:', error);
            setErrorMessage(error);
          },
        },
      );
    } else if (mode === 'update') {
      updateClaim.mutate(
        {
          ...formData,
          id: claimId,
          status: 'not_considered',
        },
        {
          onSuccess: (data) => {
            console.log('Mutation succeeded. Data:', data);
            setFormData({});
            claims.refetch();
            onClose();
            toast.success(intl.formatMessage(messages.claimUpdated));
            setTypeCategory('');
          },
          onError: (error) => {
            console.log('Error:', error);
            setErrorMessage(error);
          },
        },
      );
    }
  };

  const handleClose = () => {
    setDialogValue({
      ...dialogValue,
      name: '',
    });
    stakeholders.refetch();
    toggleStakeholderOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setTypeCategory('');
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle variant="h2">
        {mode === 'create'
          ? intl.formatMessage(messages.dialogTitleCreateClaim)
          : intl.formatMessage(messages.dialogTitleUpdateClaim)}
      </DialogTitle>
      <DialogContent>
        <div className="flex h-full items-center justify-center">
          {typeCategory ? (
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    type="text"
                    id="title"
                    label={intl.formatMessage(messages.title)}
                    onChange={handleChange('title')}
                    value={formData.title || ''}
                    helperText={errorMessage.title && errorMessage.title[0]}
                    error={!!errorMessage.title}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    variant="outlined"
                    type="number"
                    id="value"
                    label={intl.formatMessage(messages.value)}
                    onChange={handleChange('value')}
                    value={formData.value || ''}
                    helperText={errorMessage.value && errorMessage.value[0]}
                    error={!!errorMessage.value}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    value={groupedStakeholders.find(
                      (a) => a.name === formData.stakeholder,
                    )}
                    id="stakeholder"
                    options={groupedStakeholders || []}
                    groupBy={(option) => option.group}
                    onChange={(event, newValue) => {
                      if (newValue && newValue.inputValue) {
                        setDialogValue({
                          ...dialogValue,
                          name: newValue.inputValue,
                        });
                        toggleStakeholderOpen(true);
                      } else if (newValue) {
                        const idValue = newValue ? newValue.id : null;

                        handleChange('stakeholder', idValue)();
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          name: intl.formatMessage(messages.addNew, {
                            name: params.inputValue,
                          }),
                          isAddOption: true,
                        });
                      }

                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      return !option.isAddOption
                        ? option.name || ''
                        : option.inputValue;
                    }}
                    renderOption={(props, option) => (
                      <li {...props} value={option.id}>
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={intl.formatMessage(messages.stakeholder)}
                      />
                    )}
                    selectOnFocus
                    handleHomeEndKeys
                    freeSolo
                  />
                </FormControl>
              </Grid>
              <StakeholderForm
                mode={'create'}
                notHeir={true}
                notHeirData={dialogValue}
                open={stakeholderOpen}
                onClose={handleClose}
                // setReturnedData={setNewStakeholder}
                handleReturnedData={(id) => handleChange('stakeholder', id)()}
              />
            </Grid>
          ) : (
            <Grid container spacing={gridSpacing}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  onClick={() => setTypeCategory('outlay')}
                  fullWidth
                  sx={{ padding: 2 }}
                >
                  {intl.formatMessage(messages.outlay)}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setTypeCategory('preferential')}
                  sx={{ padding: 2 }}
                >
                  {intl.formatMessage(messages.preferential)}
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </DialogContent>
      {typeCategory && (
        <DialogActions>
          <Button onClick={onClose}>
            {intl.formatMessage(messages.cancel)}
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            {intl.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
