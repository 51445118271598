import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import useDeleteDialog from 'hooks/useDeleteDialog';
import SimplePopup from './SimplePopup';

export default function EditDeletePopup({
  handleEdit,
  handleDelete,
  disablePortal = false,
}) {
  const { handleOpen, DeleteDialog } = useDeleteDialog();

  return (
    <>
      <SimplePopup icon={<MoreVert />} disablePortal={disablePortal}>
        {/* <div className="flex flex-col items-center justify-center gap-2">
          <IconButton color="primary">
            <Edit />
          </IconButton>
          <IconButton color="primary">
            <Delete />
          </IconButton>
        </div> */}
        <Stack gap={2} alignItems={'center'} justifyContent={'center'}>
          <IconButton onClick={handleEdit}>
            {/* <ListItemIcon> */}
            <Tooltip placement="top" title="Edit">
              <Edit color="primary" />
            </Tooltip>
            {/* </ListItemIcon> */}
            {/* <ListItemText>Edit</ListItemText> */}
          </IconButton>
          {/* <ListItem> */}
          <IconButton color="primary" onClick={handleOpen}>
            {/* <ListItemIcon> */}
            <Tooltip placement="top" title="Delete">
              <Delete color="primary" />
            </Tooltip>
            {/* </ListItemIcon> */}
            {/* <ListItemText>Delete</ListItemText> */}
          </IconButton>
        </Stack>
        {/* </ListItem> */}
      </SimplePopup>
      <DeleteDialog onDelete={handleDelete} />
    </>
  );
}
