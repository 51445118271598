import { HighlightOff } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Modal,
  Typography,
} from '@mui/material';
import MainCard from 'components/MainCard';
import {
  useCreateFinalSettlementAgreementSigning,
  useFinalSettlementAgreement,
} from 'query/estates';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useStore from 'store';
import { gridSpacing } from 'store/constant';
import messages from './messages';

export default function FinalSettlementAgreement(params) {
  const activeEstate = useStore((state) => state.activeEstate);
  const divForm = useFinalSettlementAgreement({
    uuid: activeEstate,
  });

  const [pdfUrl, setPdfUrl] = useState(null);

  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const signingData = useCreateFinalSettlementAgreementSigning({
    uuid: activeEstate,
    enabled: false,
  });

  const handleSignOpen = () => setOpenDialog(true);
  const handleSignClose = () => setOpenDialog(false);

  const handleOpen = () => {
    setOpen(true);
    fetchPdf();
  };
  const handleClose = () => setOpen(false);

  const fetchPdf = async () => {
    try {
      const response = await divForm.refetch();
      // console.log(response.data);
      if (!response.isError) {
        const blob = await response.data.blob();
        const url = URL.createObjectURL(blob);
        const modUrl = url + '#toolbar=0&navpanes=0';
        // console.log('MODURL', modUrl);
        setPdfUrl(modUrl);
      } else {
        const errors = response.error.error;
        if (errors && errors.length > 0) {
          setErrorMessage(errors);
        } else {
          setErrorMessage(['An unknown error occurred']);
        }
      }
    } catch (error) {
      setErrorMessage([error.message] || ['An unknown error occurred']);
      console.error('Error fetching PDF:', error);
    }
  };

  const getSigning = async () => {
    signingData.refetch();
    console.log(await signingData.data);
  };

  if (errorMessage.length > 0) {
    return (
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          {errorMessage?.map((error, index) => (
            <Typography key={index} color="error">
              {error}
            </Typography>
          ))}
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Button onClick={handleOpen} variant="outlined">
        <FormattedMessage {...messages.previewButton} />
      </Button>

      <Button onClick={handleSignOpen} variant="contained">
        <FormattedMessage {...messages.sendButton} />
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
          }}
        >
          <MainCard sx={{ height: '100%', position: 'relative' }}>
            {/* <Box> */}
            <HighlightOff
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8, // Adjust as needed for spacing from right
                top: 8, // Adjust as needed for spacing from top
                cursor: 'pointer',
                color: 'inherit', // or set a default color
                '&:hover': {
                  color: 'red', // Change to your preferred hover color
                },
                height: 30,
                width: 30,
              }}
            />

            {pdfUrl ? (
              <iframe
                title="pdf"
                src={pdfUrl}
                style={{
                  width: '100%',
                  height: '85vh',
                  marginTop: '1%',
                  borderRadius: 14,
                }} // Set your desired dimensions
                frameBorder="0"
                scrolling="auto"
              ></iframe>
            ) : (
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <CircularProgress />
                  {/* <Typography>Loading PDF...</Typography> */}
                </Grid>
              </Grid>
            )}
          </MainCard>
        </Box>
      </Modal>

      <Dialog open={openDialog} onClose={handleSignClose}>
        <DialogTitle>
          <Typography variant="h4">
            <FormattedMessage {...messages.confirmationTitle} />
          </Typography>
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleSignClose}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              getSigning();
              handleSignClose();
            }}
          >
            <FormattedMessage {...messages.sendButton} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
