import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import EditDeletePopup from 'components/EditDeletePopup';
import { FormattedNumber, useIntl } from 'react-intl';
import assetTypeIcon from 'utils/assetTypeIcon';
import messages from './messages';

export default function AssetTableView({
  assetsData,
  handleEditAsset,
  handleDelete,
}) {
  const intl = useIntl();
  return (
    <div className="flex items-center justify-center">
      {assetsData?.length > 0 ? (
        <TableContainer component={Paper} sx={{ maxWidth: { md: '40%' } }}>
          <Table>
            <TableBody>
              {assetsData?.map((a) => (
                <TableRow key={a.id}>
                  <TableCell width={10} component="th" scope="row">
                    <Tooltip title={a.type}>{assetTypeIcon(a.type)}</Tooltip>
                  </TableCell>
                  <TableCell width={200}>{a.title}</TableCell>
                  <TableCell width={100}>
                    <FormattedNumber
                      value={a.value}
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currency="nok"
                    />
                  </TableCell>
                  <TableCell width={10}>
                    <EditDeletePopup
                      handleEdit={() => handleEditAsset(a.id)}
                      handleDelete={() => handleDelete(a.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="caption">
          {intl.formatMessage(messages.emptyText)}
        </Typography>
      )}
    </div>
  );
}
