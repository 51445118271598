import { ArrowDropDown } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { useRef, useState } from 'react';
import { toast } from 'sonner';

const options = [
  'Send mail with Gmail in browser',
  'Send mail with Outlook in browser',
  'Send mail with default email',
  'Copy to clipboard',
];

export default function Mailto({
  email,
  cc,
  bcc,
  subject = '',
  body = '',
  children,
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(3);
  let subjectURI = '';
  let bodyURI = '';
  let ccURI = '';
  let bccURI = '';

  if (subject) subjectURI = encodeURIComponent(subject);
  if (body) bodyURI = encodeURIComponent(body);
  cc ? (ccURI = encodeURIComponent(cc)) : (cc = '');
  bcc ? (bccURI = encodeURIComponent(bcc)) : (bcc = '');

  const handleClick = () => {
    if (selectedIndex === 0)
      window.open(
        `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subjectURI}&cc=${ccURI}&bcc=${bccURI}&body=${bodyURI}`,
        '_blank',
        'noreferrer',
      );
    else if (selectedIndex === 1)
      window.open(
        `https://outlook.live.com/owa/?path=/mail/action/compose&to=${email}&subject=${subjectURI}&body=${bodyURI}&cc=${ccURI}&bcc=${bccURI}`,
        '_blank',
        'noreferrer',
      );
    else if (selectedIndex === 2)
      window.open(
        `mailto:${email}?subject=${subjectURI}&body=${bodyURI}&bcc=${bccURI}&cc=${cc}`,
        '_blank',
        'noreferrer',
      );
    else if (selectedIndex === 3) {
      const fixBody = body.replaceAll('%0D%0A', '');
      const text = `email: ${email}\ncc: ${cc}\nbcc: ${bcc}\nsubject: ${subject}\ntext: ${fixBody}`;
      navigator.clipboard.writeText(text);
      toast('Copied to clipboard');
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup color="secondary" variant="contained" ref={anchorRef}>
        <Button onClick={handleClick}>{options[selectedIndex]}</Button>

        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select mail composer"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper variant="outlined" sx={{ borderRadius: '4px' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
