import { defineMessages } from 'react-intl';

const messages = defineMessages({
  totalInventory: {
    id: 'EstateSummary.totalInventory',
    defaultMessage: 'Total Inventory',
  },
  totalDistributed: {
    id: 'EstateSummary.totalDistributed',
    defaultMessage: 'Total Distributed',
  },
  remainingDistributable: {
    id: 'EstateSummary.remainingDistributable',
    defaultMessage: 'Remaining Distributable',
  },
  nameLabel: {
    id: 'EstateSummary.nameLabel',
    defaultMessage: 'Name',
  },
  typeLabel: {
    id: 'EstateSummary.typeLabel',
    defaultMessage: 'Type',
  },
  inheritanceLabel: {
    id: 'EstateSummary.inheritanceLabel',
    defaultMessage: 'Inheritance',
  },
  assetLabel: {
    id: 'EstateSummary.assetLabel',
    defaultMessage: 'Asset',
  },
  outlaysLabel: {
    id: 'EstateSummary.outlaysLabel',
    defaultMessage: 'Outlays',
  },
  totalCashValueLabel: {
    id: 'EstateSummary.totalCashValueLabel',
    defaultMessage: 'Total Cash Value',
  },
  inheritMinimumLabel: {
    id: 'EstateSummary.inheritMinimumLabel',
    defaultMessage: 'Inherit minimum',
  },
  updateFractionButton: {
    id: 'EstateSummary.updateFractionButton',
    defaultMessage: 'Update Fraction',
  },
  loadingMessage: {
    id: 'EstateSummary.loadingMessage',
    defaultMessage: 'Loading...',
  },
  valueTooltip: {
    id: 'EstateSummary.valueTooltip',
    defaultMessage: 'Value',
  },
});

export default messages;
