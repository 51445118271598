import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';
import { useEstate } from 'query/estates';
import { useCreateStakeholder } from 'query/stakeholder';
import { useEffect, useState } from 'react';
import useStore from 'store';
import { gridSpacing } from 'store/constant';

export default function MaritalStatus({
  formData,
  handleChange,
  errorMessage,
  setErrorMessage,
  nextButton,
  backButton,
  inputDisabled,
  setInputDisabled,
  nextVisible,
  setNextVisible,
}) {
  const store = useStore();
  const activeEstate = store.activeEstate;
  const estate = useEstate({ uuid: activeEstate });
  const create = useCreateStakeholder();

  const [spouseData, setSpouseData] = useState({
    name: '',
    ssn: '',
    address_street: '',
    address_zip: '',
    address_city: '',
  });

  // const [nextVisible, setNextVisible] = useState(false);
  // const [inputDisabled, setInputDisabled] = useState(false);

  useEffect(() => {
    if (formData.deceased_marital_status === 'married') {
      setSpouseData((prevData) => ({
        ...prevData,
        address_street: estate.data.address_street || '',
        address_zip: estate.data.address_zip || '',
        address_city: estate.data.address_city || '',
      }));
    }
  }, [formData?.deceased_marital_status]);

  const handleSpouseCreate = async () => {
    setErrorMessage('');

    const estateId = await estate.data.id;

    create.mutate(
      {
        ...spouseData,
        entity_type: 'personal',
        heir_relation: 'spouse',
        type:
          formData.deceased_marital_status === 'predeceased_spouse'
            ? 'predeceased_spouse'
            : 'spouse',
        estate: estateId,
        is_deceased: formData.deceased_marital_status === 'predeceased_spouse',
      },
      {
        onSuccess: (data) => {
          console.log('Mutation succeeded. Data:', data);
          setNextVisible(true);
          setInputDisabled(true);
        },
        onError: (error) => {
          console.log('Error:', error);
          setErrorMessage(error);
        },
      },
    );
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Typography variant="h2">Avdødes Sivilstatus</Typography>
      </Grid>

      <Grid item xs={12}>
        <FormLabel>Avdøde var ved sin død</FormLabel>
        <RadioGroup
          onChange={handleChange('deceased_marital_status')}
          value={formData?.deceased_marital_status || ''}
          row
        >
          <FormControlLabel
            value={'married'}
            control={<Radio disabled={inputDisabled} />}
            label="Gift"
            // labelPlacement="bottom"
            onInput={() => setNextVisible(false)}
          />
          <FormControlLabel
            value={'not_married'}
            control={<Radio disabled={inputDisabled} />}
            label="Ugift"
            onInput={() => setNextVisible(true)}
            // labelPlacement="bottom"
          />
          <FormControlLabel
            value={'separated'}
            control={<Radio disabled={inputDisabled} />}
            label="Separert/skilt"
            onInput={() => setNextVisible(true)}
            // labelPlacement="bottom"
          />
          <FormControlLabel
            value={'predeceased_spouse'}
            control={<Radio disabled={inputDisabled} />}
            label="Gjennlevende ektefelle"
            // labelPlacement="bottom"
            onInput={() => setNextVisible(false)}
          />
        </RadioGroup>
      </Grid>
      {formData.deceased_marital_status === 'predeceased_spouse' && (
        <>
          <Grid item xs={12}>
            <RadioGroup
              onChange={handleChange('deceased_composite_distribution')}
              value={formData?.deceased_composite_distribution || false}
              row
            >
              <FormControlLabel
                value={true}
                control={<Radio disabled={inputDisabled} />}
                label="Avdøde satt i uskiftet bo"
              />
              <FormControlLabel
                value={false}
                control={<Radio disabled={inputDisabled} />}
                label="Boet etter førsteavdøde er skiftet"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                id="Spouse Name"
                type="text"
                label="Avdøde ektefelles navn"
                onChange={(e) => {
                  setSpouseData((prevData) => ({
                    ...prevData,
                    name: e.target.value,
                  }));
                }}
                value={spouseData.name || ''}
                helperText={errorMessage.name && errorMessage.name[0]}
                error={!!errorMessage.name}
                required
                disabled={inputDisabled}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <DatePicker
                label={'Avdøde ektefelles dødsdato'}
                format="dd/MM/yyyy"
                disabled={inputDisabled}
                onChange={(date) => {
                  setSpouseData((prevFormData) => ({
                    ...prevFormData,
                    deceased_date: date.toISOString().split('T')[0],
                  }));
                }}
                value={
                  formData.deceased_date
                    ? parseISO(spouseData.deceased_date)
                    : null
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleSpouseCreate}
              disabled={inputDisabled}
            >
              Lagre
            </Button>
          </Grid>
        </>
      )}
      {formData.deceased_marital_status === 'married' && (
        <>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                id="Spouse Name"
                type="text"
                label="Ektefelles navn"
                onChange={(e) => {
                  setSpouseData((prevData) => ({
                    ...prevData,
                    name: e.target.value,
                  }));
                }}
                value={spouseData.name || ''}
                helperText={errorMessage.name && errorMessage.name[0]}
                error={!!errorMessage.name}
                required
                disabled={inputDisabled}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                id="Spouse ssn"
                type="text"
                label="Ektefelles Fødselsnummer"
                onChange={(e) => {
                  setSpouseData((prevData) => ({
                    ...prevData,
                    ssn: e.target.value,
                  }));
                }}
                value={spouseData.ssn || ''}
                helperText={errorMessage.ssn && errorMessage.ssn[0]}
                error={!!errorMessage.ssn}
                required
                disabled={inputDisabled}
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                type="text"
                label="Ektefelles Boligadresse"
                onChange={(e) => {
                  setSpouseData((prevData) => ({
                    ...prevData,
                    address_street: e.target.value,
                  }));
                }}
                value={spouseData.address_street || ''}
                disabled={inputDisabled}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                type="text"
                label="Ektefelles Postnr"
                onChange={(e) => {
                  setSpouseData((prevData) => ({
                    ...prevData,
                    address_zip: e.target.value,
                  }));
                }}
                value={spouseData.address_zip || ''}
                disabled={inputDisabled}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                type="text"
                label="Ektefelles Poststed"
                onChange={(e) => {
                  setSpouseData((prevData) => ({
                    ...prevData,
                    address_city: e.target.value,
                  }));
                }}
                value={spouseData.address_city || ''}
                disabled={inputDisabled}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleSpouseCreate}
              disabled={inputDisabled}
            >
              Lagre
            </Button>
          </Grid>
        </>
      )}
      {(nextVisible || inputDisabled) && (
        <Grid item xs={12}>
          {/* {backButton()} */}
          {nextButton()}
        </Grid>
      )}
    </Grid>
  );
}
